import React, { } from 'react';
import JJ_From, { FormItemData } from "../../components/JJ_From";
import api from '../../api';
import { Select, Tag } from 'antd';
import { connect, ConnectedProps } from 'react-redux';
import { getAreaCodes } from '../../public';


interface PageState {

}

type Type = { type: 'add' } | { type: 'edit', id: string }

interface Props {
  onFinish?(props: PageProps, id: string): void
}



interface Page {
  /**
   * 保存数据
   */
  _onFinish(values: any): void

  /**
   * 获取数据
   */
  _onLoadData(): Promise<{}>

  id?: string

}


type PageProps = Props & Type & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {

}

const mapState = (state: RootState) => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class AreaEditAndAdd extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);

    this.state = {

    }

  }
  componentDidMount() {
  }

  _onFinish = async (values: any): Promise<void> => {

    switch (this.props.type) {
      case 'edit': {
        const res = await api.PutArea({
          ...values,
          id: this.props.id,
        })
        if (res.kind !== 'ok') throw Error(res.kind)
        this.props.onFinish && this.props.onFinish(this.props, res.data.id)
      }
        break
      case 'add': {

        const res = await api.PostArea({
          ...values,
        })
        if (res.kind !== 'ok') throw Error(res.kind)
        this.props.onFinish && this.props.onFinish(this.props, res.data.id)
      }
        break
    }
  }
  _onLoadData = async (): Promise<any> => {
    switch (this.props.type) {
      case 'edit': {
        const res = await api.GetArea({ id: this.props.id })

        if (res.kind === 'ok') {
          return res.data
        }
        throw Error(res.kind)
      }
      case 'add': {
        return
      }

    }
  };

  _formListData = (): FormItemData[] => {
    switch (this.props.type) {
      case 'edit': {
        return [
          {
            id: 'i18nAndDefaultText',
            name: 'name',
            label: '名稱',
            rules: [
              {
                required: true,
              }
            ],
          },
          {
            id: 'status',
            label: '區域類型',
            rules: [
              {
                required: false,
                message: `請輸入 區域類型`
              }
            ],
            componet: (
              <Select>
                {[
                  { value: 'NORMAL', name: <Tag color="blue">正常</Tag> },
                  { value: 'TEST', name: <Tag color="red">測試</Tag> },
                ].map(item => {
                  return (
                    <Select.Option key={item.value} value={item.value}>{item.name}</Select.Option>
                  )
                })}
              </Select>
            ),
          },
          {
            id: 'code',
            label: '區域代碼',
            rules: [
              {
                required: false,
                message: `請輸入 區域代碼`
              }
            ],
            componet: (
              <Select>
                {getAreaCodes.map(item => {
                  return (
                    <Select.Option key={item} value={item}>{item}</Select.Option>
                  )
                })}
              </Select>
            ),
          },
          {
            id: 'submit',
          },
        ]
      }
      case 'add': {
        return [
          {
            id: 'i18nAndDefaultText',
            name: 'name',
            label: '名稱',
            rules: [
              {
                required: true,
              }
            ],
          },
          {
            id: 'status',
            label: '區域類型',
            rules: [
              {
                required: true,
                message: `請輸入 區域類型`
              }
            ],
            componet: (
              <Select>
                {[
                  { value: 'NORMAL', name: <Tag color="blue">正常</Tag> },
                  { value: 'TEST', name: <Tag color="red">測試</Tag> },
                ].map(item => {
                  return (
                    <Select.Option key={item.value} value={item.value}>{item.name}</Select.Option>
                  )
                })}
              </Select>
            ),
          },
          {
            id: 'code',
            label: '區域代碼',
            rules: [
              {
                required: true,
                message: `請輸入 區域代碼`
              }
            ],
            componet: (
              <Select>
                {getAreaCodes.map(item => {
                  return (
                    <Select.Option key={item} value={item}>{item}</Select.Option>
                  )
                })}
              </Select>
            ),
          },
          {
            id: 'submit',
          },
        ]
      }

    }
  }

  render() {
    return (
      <div id="AreaEditAndAdd">
        <JJ_From
          formListData={this._formListData()}
          onLoadData={this._onLoadData}
          onFinish={this._onFinish}
        />
      </div>
    )
  }

}
export default connector(AreaEditAndAdd)
