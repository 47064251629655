import React, { ReactElement } from 'react';
import { Tabs, Card, Input } from 'antd';


interface PageProps {
  value?: any
  onChange?: (value?: any) => void;
}

interface PageState {

}

interface Page {

}

export default class JJ_FromI18nInputText extends React.Component<PageProps, PageState> implements Page {

  componentDidMount() {


  }


  constructor(props: any) {
    super(props);
    this.state = {}
  }

  render() {
    const tabDatas = [
      { title: 'English', value: 'en' },
      { title: '繁體中文', value: 'zh-tw' },
      { title: '简体中文', value: 'zh-cn' },
    ]
    return (
      <Card>
        <Tabs
          defaultActiveKey="1"
          type='card'
        >
          {tabDatas.map(item => {
            const value: any = this.props.value || {}

            return (
              <Tabs.TabPane
                tab={item.title}
                key={item.value}
              >
                <Input
                  defaultValue={value[item.value]}
                  // value={value[item.value]}
                  onChange={v => {
                    const newValue: any = this.props.value || {}
                    newValue[item.value] = v.target.value
                    this.props.onChange && this.props.onChange({
                      'zh-tw': '',
                      'zh-cn': '',
                      'en': '',
                      ...(newValue || {}),
                    })

                  }}
                />
              </Tabs.TabPane>
            )
          })}
        </Tabs>
      </Card>
    )
  }

}


