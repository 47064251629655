import React, { } from 'react';
import api, { PostSignupFormImageRotationRes, PostSignupFormImageRotationParameters } from '../../api';
import { Modal, Carousel, Image, Space, Button, Tooltip, Spin, message } from 'antd';
import { EditOutlined, CaretLeftOutlined, CaretRightOutlined, FullscreenOutlined, RotateLeftOutlined, RotateRightOutlined } from '@ant-design/icons';

interface PageState {
  activeSlide: number
  hasRead: number[]
  imageRotationLoading: any
}

interface Image {
  id: string
  url: string
  type: string
  label: string
}

interface Props {
  id: string
  visible: boolean
  index: number
  images: Image[]
  onChange: (index: number) => void
  onUpdate: (index: number) => void
  onCancel: () => void
}

interface Page {
}

type PageProps = Props

class SignupFormDetailsModalImageBrowserModal extends React.Component<PageProps, PageState> implements Page {

  sliderRef: any | null
  constructor(props: Readonly<PageProps>) {
    super(props);
    this.state = {
      activeSlide: props.index,
      hasRead: [props.index],
      imageRotationLoading: {},
    }
  }
  componentDidMount() {
  }

  _postSignupFormImageRotation = async (params: PostSignupFormImageRotationParameters) => {
    const res = await api.PostSignupFormImageRotation(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _rotateImage = async (index: number, direction: number): Promise<void> => {
    this.setState({
      imageRotationLoading: {
        ...this.state.imageRotationLoading,
        [index]: true,
      }
    })

    const res = await this._postSignupFormImageRotation({
      id: this.props.id,
      imageId: this.props.images[index].id,
      direction,
    })

    this.setState({
      imageRotationLoading: {
        ...this.state.imageRotationLoading,
        [index]: false,
      }
    })

    this.props.onUpdate(index)

    message.success('圖片旋轉成')
  }

  render() {
    return (
      <Modal
        width="80%"
        visible={this.props.visible}
        title="預覽文件"
        onCancel={this.props.onCancel}
        footer={null}
      >
        <div style={{textAlign: 'center', marginBottom: 16}}>
          <Space>
            <Button
              type="primary"
              icon={<CaretLeftOutlined />}
              onClick={() => {
                this.sliderRef.prev();
              }}
            />
            <span>{this.state.activeSlide + 1} / {this.props.images.length}</span>
            <Button
              type="primary"
              icon={<CaretRightOutlined />}
              onClick={() => {
                this.sliderRef.next();
              }}
            />
          </Space>
        </div>
        <Carousel
          ref={ref => this.sliderRef = ref}
          dots={false}
          initialSlide={this.props.index}
          afterChange={(current) => {
            this.setState({
              activeSlide: current,
              hasRead: this.state.hasRead.indexOf(current) > -1 ? this.state.hasRead : [
                ...this.state.hasRead,
                current,
              ],
            })
          }}
        >
          {this.props.images.map((image, index) => (
            <div>
              <div style={{textAlign: 'center'}}>
                <Space direction="vertical" style={{ width: '100%' }}>
                  <div>{image.label}</div>

                  {image.type === 'application/pdf' && this.state.hasRead.indexOf(index) > -1 && (
                    <iframe
                      style={{ width: '100%', height: 454 }}
                      src={image.url}
                    />
                  )}
                  {image.type === 'application/pdf' && this.state.hasRead.indexOf(index) === -1 && (
                    <Tooltip title="按此加載 PDF 文件">
                      <Image
                        key={index}
                        src={`${image.url}?thumbnail=true`}
                        style={{ border: '1px solid #d9d9d9', padding: 4, cursor: 'pointer' }}
                        preview={false}
                      />
                    </Tooltip>
                  )}
                  {image.type.split('/')[0] === 'image' && (
                    <Spin spinning={!!this.state.imageRotationLoading[index]}>
                      <Image
                        key={index}
                        src={image.url}
                        preview={false}
                        height={540}
                        style={{
                          objectFit: 'contain',
                        }}
                      />
                    </Spin>
                  )}

                  {image.type.split('/')[0] === 'image' && (
                    <Space>
                      <Button
                        type="dashed"
                        icon={<RotateLeftOutlined />}
                        onClick={() => {
                          this._rotateImage(index, -1)
                        }}
                        disabled={!!this.state.imageRotationLoading[index]}
                      >
                        逆時針旋轉
                      </Button>

                      <Button
                        type="dashed"
                        icon={<RotateRightOutlined />}
                        onClick={() => {
                          this._rotateImage(index, 1)
                        }}
                        disabled={!!this.state.imageRotationLoading[index]}
                      >
                        順時針旋轉
                      </Button>
                    </Space>
                  )}

                  <Button
                    type="primary"
                    href={image.url}
                    target="_blank"
                    icon={<FullscreenOutlined />}
                  >
                    在新視窗打開文件
                  </Button>
                </Space>
              </div>
            </div>
          ))}
        </Carousel>
      </Modal>
    )
  }

}
export default SignupFormDetailsModalImageBrowserModal

