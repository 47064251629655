import React, { } from 'react';
import JJ_From, { FormItemData } from "../../components/JJ_From";
import api, { GetProductLabelParameters, PostProductLabelParameters, GetProductLabelListParameters, GetImageRes, GetLabelRes, GetProductLabelRes, GetLabelParameters, GetLabelListParameters, DeleteProductLabelParameters } from '../../api';
import { connect, ConnectedProps } from 'react-redux';
import JJ_FromProductLabelTaransfer from '../../components/JJ_FromProductLabelTaransfer';
import { ActionUserRes } from '../../actions/ActionUser';

interface PageState {
  lables?: GetLabelRes[]
}


export type ProductLabelEditAndAddPropsType = { type: 'add', productId: string }

interface Props {
  onFinish?(props: PageProps, id: string): void
}



interface Page {
  /**
   * 保存数据
   */
  _onFinish(values: any): void

  /**
   * 获取数据
   */
  _onLoadData(): Promise<{}>

  id?: string

}


type PageProps = Props & ProductLabelEditAndAddPropsType & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {
  user: ActionUserRes
}

const mapState = (state: RootState) => ({
  user: state.user
})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class ProductLabelEditAndAdd extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);

    this.state = {

    }

  }
  componentDidMount() {
  }

  oldProductLabels: GetProductLabelRes[] = []

  _onFinish = async (values: any): Promise<void> => {

    console.log('_onFinish', this.props.type, values)

    switch (this.props.type) {
      case 'add': {
        const labels: GetLabelRes[] = values.labels
        const deleteProductLabels = this.oldProductLabels.filter(oldProductLabel => !labels.some(newLabel => newLabel.id === (oldProductLabel.label as GetLabelRes).id))
        const postLabels = labels.filter(newLabel => !this.oldProductLabels.some(oldProductLabel => newLabel.id === (oldProductLabel.label as GetLabelRes).id))

        for (const deleteProductLabel of deleteProductLabels) {
          await this._deleteProductLabel({ id: deleteProductLabel.id })
        }

        for (const label of postLabels) {
          await this._postProductLabel({ labelId: label.id, productId: this.props.productId })

        }

        this.props.onFinish && this.props.onFinish(this.props, '')
      }
        break
    }
  }

  _onLoadData = async (): Promise<any> => {
    switch (this.props.type) {

      case 'add': {
        const res = await this._getProductLabelList({ productId: this.props.productId, scope: ['label'] })
        const labelList = await this._getLabelList({ count: 10000 })
        this.setState({
          lables: labelList.data,
        })
        this.oldProductLabels = res.data
        return {
          labels: res.data.map(item => (item.label as GetLabelRes))
        }
      }


    }
  };

  _getProductLabel = async (param: GetProductLabelParameters) => {
    const res = await api.GetProductLabel(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }
  _deleteProductLabel = async (param: DeleteProductLabelParameters) => {
    const res = await api.DeleteProductLabel(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }
  _getLabelList = async (param: GetLabelListParameters) => {
    const res = await api.GetLabelList(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _getProductLabelList = async (param: GetProductLabelListParameters) => {
    const res = await api.GetProductLabelList(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }


  _postProductLabel = async (param: PostProductLabelParameters) => {
    const res = await api.PostProductLabel(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }


  _formListData = (): FormItemData[] => {
    switch (this.props.type) {
      case 'add': {

        return [
          ...(this.state.lables ? [
            {
              id: 'labels',
              label: '產品標籤',
              rules: [
                {
                  required: false,
                  message: `請選擇產品標籤`,
                }
              ],
              componet: (<JJ_FromProductLabelTaransfer
                labels={this.state.lables}
                user={this.props.user}
              />)

            },
          ] : []),

          {
            id: 'submit',
          },
        ]
      }


    }
  }

  render() {
    return (
      <div id="ProductLabelEditAndAdd">
        <JJ_From
          formListData={this._formListData()}
          onLoadData={this._onLoadData}
          onFinish={this._onFinish}
        />
      </div>
    )
  }

}
export default connector(ProductLabelEditAndAdd)
