import React, { } from 'react';
import JJ_From, { FormItemData } from "../../components/JJ_From";
import api, { GetQuotaParameters, PutQuotaParameters, PostQuotaParameters, GetQuotaListParameters } from '../../api';
import { connect, ConnectedProps } from 'react-redux';
import JJ_FromI18nInputText from '../../components/JJ_FromI18nInputText';
import { Input } from 'antd';
import JJ_FromI18nInputTextByDefault from '../../components/JJ_FromI18nInputTextByDefault';
import TextArea from 'antd/lib/input/TextArea';

interface PageState {

}


export type QuotaEditAndAddPropsType = { type: 'add' } | { type: 'edit', id: string }

interface Props {
  onFinish?(props: PageProps, id: string): void
}



interface Page {
  /**
   * 保存数据
   */
  _onFinish(values: any): void

  /**
   * 获取数据
   */
  _onLoadData(): Promise<{}>

  id?: string

}


type PageProps = Props & QuotaEditAndAddPropsType & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {

}

const mapState = (state: RootState) => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class QuotaEditAndAdd extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);

    this.state = {

    }

  }
  componentDidMount() {
  }

  _onFinish = async (values: any): Promise<void> => {

    console.log('_onFinish', this.props.type, values)

    switch (this.props.type) {
      case 'edit': {
        const res = await this._putQuota({
          id: this.props.id,
          monthCount: values.monthCount > 0 ? Math.trunc(values.monthCount) : 0,
          weekCount: values.weekCount > 0 ? Math.trunc(values.weekCount) : 0,
          dayCount: values.dayCount > 0 ? Math.trunc(values.dayCount) : 0,
          name: values.name,
          remark: values.remark,
        })
        this.props.onFinish && this.props.onFinish(this.props, res.id)
      }
        break
      case 'add': {
        const res = await this._postQuota({
          monthCount: values.monthCount > 0 ? Math.trunc(values.monthCount) : 0,
          weekCount: values.weekCount > 0 ? Math.trunc(values.weekCount) : 0,
          dayCount: values.dayCount > 0 ? Math.trunc(values.dayCount) : 0,
          name: values.name,
          remark: values.remark,
        })
        this.props.onFinish && this.props.onFinish(this.props, res.id)
      }
        break
    }
  }

  _onLoadData = async (): Promise<any> => {
    switch (this.props.type) {
      case 'edit': {
        const res = await this._getQuota({ id: this.props.id })
        return {
          ...res,
        }
      }
      case 'add': {

        return {

        }
      }


    }
  };

  _getQuota = async (param: GetQuotaParameters) => {
    const res = await api.GetQuota(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _getQuotaList = async (param: GetQuotaListParameters) => {
    const res = await api.GetQuotaList(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _putQuota = async (param: PutQuotaParameters) => {
    const res = await api.PutQuota(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }
  _postQuota = async (param: PostQuotaParameters) => {
    const res = await api.PostQuota(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }


  _formListData = (): FormItemData[] => {
    switch (this.props.type) {
      case 'edit': {
        return [
          {
            id: 'monthCount',
            label: '每月最大配額',
            rules: [
              {
                required: false,
                message: `請輸入每月最大配額`,
              }
            ],
            componet: (<Input type='number' />)

          },
          {
            id: 'weekCount',
            label: '每周最大配額',
            rules: [
              {
                required: false,
                message: `請輸入每周最大配額`,
              }
            ],
            componet: (<Input type='number' />)

          },
          {
            id: 'dayCount',
            label: '每日最大配額',
            rules: [
              {
                required: false,
                message: `請輸入每日最大配額`,
              }
            ],
            componet: (<Input type='number' />)

          },

          {
            id: 'name',
            label: '名稱',
            rules: [
              {
                required: true,
                message: `請輸入名稱`,
              }
            ],
            componet: (<JJ_FromI18nInputTextByDefault />)
          },

          {
            id: 'remark',
            label: '備註',
            rules: [
              {
                required: false,
                message: `請輸入 備註`
              }
            ],
            componet: (
              < TextArea
                style={{
                  height: 150,
                }}
              />
            )
          },
          {
            id: 'submit',
          },
        ]
      }
      case 'add': {
        return [

          {
            id: 'monthCount',
            label: '每月最大配額',
            rules: [
              {
                required: true,
                message: `請輸入每月最大配額`,
              }
            ],
            componet: (<Input type='number' />)

          },
          {
            id: 'weekCount',
            label: '每周最大配額',
            rules: [
              {
                required: true,
                message: `請輸入每周最大配額`,
              }
            ],
            componet: (<Input type='number' />)

          },
          {
            id: 'dayCount',
            label: '每日最大配額',
            rules: [
              {
                required: true,
                message: `請輸入每日最大配額`,
              }
            ],
            componet: (<Input type='number' />)

          },

          {
            id: 'name',
            label: '名稱',
            rules: [
              {
                required: true,
                message: `請輸入名稱`,
              }
            ],
            componet: (<JJ_FromI18nInputTextByDefault />)
          },

          {
            id: 'remark',
            label: '備註',
            rules: [
              {
                required: false,
                message: `請輸入 備註`
              }
            ],
            componet: (
              < TextArea
                style={{
                  height: 150,
                }}
              />
            )
          },
          {
            id: 'submit',
          },
        ]
      }


    }
  }

  render() {
    return (
      <div id="QuotaEditAndAdd">
        <JJ_From
          formListData={this._formListData()}
          onLoadData={this._onLoadData}
          onFinish={this._onFinish}
        />
      </div>
    )
  }

}
export default connector(QuotaEditAndAdd)
