import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, message, Spin, Button, Tag } from 'antd';
import api, { GetKioskVendingListParameters, GetKioskVendingRes, GetStatisticKioskVendingSellWeekParameters, GetStatisticKioskVendingSellWeekDataRes } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { connect, ConnectedProps } from 'react-redux';
import JJ_TableItemCopyableId from '../../components/JJ_TableItemCopyableId';
import moment from 'moment';
import KioskVendingSellDayListModal from './KioskVendingSellDayListModal';
const { confirm } = Modal;

interface PageState {
  isSpinLoading: boolean
  kioskVendingSellDayListModalProps: {
    key?: any
    title: string
    visible: boolean
    kioskVendingIds: string[]
    beginAtMoment: moment.Moment
    endAtMoment: moment.Moment
  }
  selectedRowKeys: string[]
}
interface Props {
}


interface Page {


}


type PageProps = Props & RouteChildrenProps & ConnectedProps<typeof connector>

interface RootState {

}

const mapState = () => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class KioskVendingSellWeekList extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: true,
      kioskVendingSellDayListModalProps: {
        title: '查看售賣機日統計報告',
        visible: false,
        kioskVendingIds: [],
        beginAtMoment: moment(),
        endAtMoment: moment(),
      },
      selectedRowKeys: []
    }
  }
  tableRef?: JJ_Table<GetStatisticKioskVendingSellWeekDataRes & { id: string }> | null


  componentDidMount() {
    this._initData()
  }
  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true
      })
      await Promise.resolve()
      this.setState({
        isSpinLoading: false
      })
    } catch (error) {
      this.setState({
        isSpinLoading: false
      })
    }
  }

  _getRangePickerBeginAtAndEndAtData = () => {

    let beginAtM = moment().subtract(moment().days() === 0 ? 2 : 1, 'week').day(1)
    let endAt = moment(beginAtM).add(6, 'day')

    return [beginAtM, endAt]

  }

  render() {
    return (
      <div id="KioskVendingSellWeekList"
      >
        <Button
          type='primary'
          style={{ marginBottom: 5 }}
          disabled={this.state.selectedRowKeys.length === 0}
          onClick={() => this.setState(state => ({
            kioskVendingSellDayListModalProps: { ...this.state.kioskVendingSellDayListModalProps, visible: true, kioskVendingIds: this.state.selectedRowKeys, key: Date.now() }
          }))}
          icon={<SearchOutlined />}
        >
          {this.state.kioskVendingSellDayListModalProps.title}
        </Button>

        {this.state.kioskVendingSellDayListModalProps.key && (
          <KioskVendingSellDayListModal
            {...this.state.kioskVendingSellDayListModalProps}
            onCancel={() => {
              this.setState({ kioskVendingSellDayListModalProps: { ...this.state.kioskVendingSellDayListModalProps, visible: false } })
            }}
          />
        )}

        <Spin spinning={this.state.isSpinLoading}>
          {!this.state.isSpinLoading && (
            <JJ_Table<GetStatisticKioskVendingSellWeekDataRes & { id: string }, {
              desc: 'ascend' | 'descend'
              rangePicker?: [moment.Moment, moment.Moment] | null
              isShowError?: 'false' | 'true'
            }>
              tableProps={{
                rowSelection: {
                  onChange: (selectedRowKeys: any) => {
                    this.setState({ selectedRowKeys })
                  },
                  selectedRowKeys: this.state.selectedRowKeys,
                },
              }}
              ref={ref => this.tableRef = ref}
              isSearchText={true}
              sourceItems={[
                {
                  type: 'select',
                  defaultValue: 'ascend',
                  key: 'desc',
                  options: [
                    {
                      value: 'ascend',
                      name: '升序',
                      disabled: false,
                    },
                    {
                      value: 'descend',
                      name: '降序',
                      disabled: false,
                    },
                  ]
                },

                {
                  type: 'select',
                  defaultValue: 'false',
                  key: 'isShowError',
                  placeholder: '任意記錄',
                  allowClear: true,
                  options: [
                    {
                      value: 'true',
                      name: <Tag color='error'>只顯示失敗記錄</Tag>,
                      disabled: false,
                    },
                    {
                      value: 'false',
                      name: <Tag color='success'>只顯示成功記錄</Tag>,
                      disabled: false,
                    },
                  ]
                },

                {
                  type: 'rangePicker',
                  key: 'rangePicker',
                  defaultValue: this._getRangePickerBeginAtAndEndAtData(),
                  props: {
                    disabledDate: (current) => current && current > moment().startOf('day'),
                    allowClear: false,
                  }
                },


              ]}
              count = {500}
              columns={[
                {
                  title: 'ID',
                  dataIndex: 'id',
                  key: 'id',
                  width: 50,
                  render: (value) => (
                    <JJ_TableItemCopyableId
                      text={value}
                    />
                  )
                },
                {
                  title: '名稱',
                  dataIndex: ['kioskVending', 'name'],
                  key: 'name',
                },
                {
                  title: '代號',
                  dataIndex: ['kioskVending', 'code'],
                  key: 'code',
                },
                {
                  title: '平均每日取餐',
                  dataIndex: ['dayTotalCount'],
                  key: 'dayTotalCount',
                },
                {
                  title: '該週總共取餐',
                  dataIndex: ['weekTotalCount'],
                  key: 'weekTotalCount',
                },
                {
                  title: '該週總共未取餐',
                  dataIndex: ['notWeekTotalCount'],
                  key: 'notWeekTotalCount',
                },
              ]}
              title={'售賣機清單'}
              onDataSource={async (body) => {
                const { sourceItemBody } = body
                let params: {
                  isAscend?: GetStatisticKioskVendingSellWeekParameters['isAscend']
                  scope?: GetStatisticKioskVendingSellWeekParameters['scope']
                  beginAt?: GetStatisticKioskVendingSellWeekParameters['beginAt']
                  endAt?: GetStatisticKioskVendingSellWeekParameters['endAt']
                  isShowError?: GetStatisticKioskVendingSellWeekParameters['isShowError']
                } = {}
                if (sourceItemBody) {

                  if (sourceItemBody.isShowError === 'false') {
                    params.isShowError = false
                  }

                  if (sourceItemBody.isShowError === 'true') {
                    params.isShowError = true
                  }

                  if (sourceItemBody.desc) {
                    params.isAscend = sourceItemBody.desc === 'ascend' ? true : false
                  }

                  if (sourceItemBody.rangePicker) {

                    const [beginAt, endAt] = sourceItemBody.rangePicker

                    params.beginAt = moment(beginAt).startOf('day').toISOString()
                    params.endAt = moment(endAt).endOf('day').toISOString()
                    this.setState({
                      kioskVendingSellDayListModalProps: {
                        ...this.state.kioskVendingSellDayListModalProps,
                        beginAtMoment: beginAt,
                        endAtMoment: endAt,
                      }
                    })
                  }
                }
                params.scope = ['community']
                const res = await api.GetStatisticKioskVendingSellWeek({
                  ...body,
                  ...params,
                })
                if (res.kind !== 'ok') throw new Error(res.kind)
                return {
                  ...res.data,
                  data: res.data.data.map(item => ({ ...item, id: item.kioskVending.id }))
                }
              }}
            />
          )}
        </Spin>

      </div>
    )
  }

}
export default connector(KioskVendingSellWeekList)

