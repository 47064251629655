import React, { } from 'react';
import JJ_From, { FormItemData } from "../../components/JJ_From";
import api, { GetKioskVendingRes, GetKioskVendingListParameters, GetImageParameters, PutImageParameters, PostImageParameters, GetImageListParameters, GetImageRes } from '../../api';
import { connect, ConnectedProps } from 'react-redux';
import JJ_FromUploadImage from '../../components/JJ_FromUploadImage';


interface PageState {

}


export type ImageLibraryEditAndAddPropsType = { type: 'add' } | { type: 'edit', id: string }

interface Props {
  onFinish?(props: PageProps, id: string): void
}



interface Page {
  /**
   * 保存数据
   */
  _onFinish(values: any): void

  /**
   * 获取数据
   */
  _onLoadData(): Promise<{}>

  id?: string

}


type PageProps = Props & ImageLibraryEditAndAddPropsType & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {

}

const mapState = (state: RootState) => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class ImageLibraryEditAndAdd extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);

    this.state = {

    }

  }
  componentDidMount() {
  }

  _onFinish = async (values: any): Promise<void> => {

    console.log('_onFinish', this.props.type, values)

    switch (this.props.type) {
      case 'edit': {
        const files: any[] = values.files
        const file = files[0]
        const res = await this._putImage({
          ...values,
          id: this.props.id,
          file: file,
        })
        this.props.onFinish && this.props.onFinish(this.props, res.id)
      }
        break
      case 'add': {

        const files: any[] = values.files

        for (const file of files) {
          const res = await this._postImage({
            ...values,
            file,
          })
        }
        this.props.onFinish && this.props.onFinish(this.props, 'xxx')
      }
        break
    }
  }

  _onLoadData = async (): Promise<any> => {
    switch (this.props.type) {
      case 'edit': {
        const res = await this._getImage({ id: this.props.id })
        return {
          ...res,
          files: [{
            uid: '-1',
            name: res.url.split('.').pop(),
            status: 'done',
            url: res.url,
            thumbUrl: res.url,
          }],
        }
      }
      case 'add': {



        return {

        }
      }


    }
  };

  _getImage = async (param: GetImageParameters) => {
    const res = await api.GetImage(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _getImageList = async (param: GetImageListParameters) => {
    const res = await api.GetImageList(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _putImage = async (param: PutImageParameters) => {
    const res = await api.PutImage(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }
  _postImage = async (param: PostImageParameters) => {
    const res = await api.PostImage(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }




  _formListData = (): FormItemData[] => {
    switch (this.props.type) {
      case 'edit': {
        return [

          {
            id: 'name',
            label: '名称',
            rules: [
              {
                required: false,
              }
            ],
          },
          {
            id: 'files',
            label: '圖片',
            rules: [
              {
                required: true,
                message: `請上傳圖片`,
              }
            ],
            componet: (<JJ_FromUploadImage
              uploadProps={{
                multiple: false,
              }}
              maxCount={1}
            />)
          },
          {
            id: 'submit',
          },
        ]
      }
      case 'add': {


        return [
          {
            id: 'files',
            label: '圖片',
            rules: [
              {
                required: true,
                message: `請上傳圖片`,
              }
            ],
            componet: (<JJ_FromUploadImage
              uploadProps={{
                multiple: true
              }}
            />)
          },
          {
            id: 'submit',
          },
        ]
      }


    }
  }

  render() {
    return (
      <div id="ImageEditAndAdd">
        <JJ_From
          formListData={this._formListData()}
          onLoadData={this._onLoadData}
          onFinish={this._onFinish}
        />
      </div>
    )
  }

}
export default connector(ImageLibraryEditAndAdd)
