import React, { ReactElement, Key } from 'react';
import { Upload, Button, Image, Modal, Card, Space } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { RcFile, UploadProps } from 'antd/lib/upload/interface';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { GetImageRes } from '../api';
import ImageLibraryList from '../views/imageLibrary/ImageLibraryList';
interface PageProps {
  value?: GetImageRes[]
  onChange?: (value: GetImageRes[]) => void;
  maxCount?: number
  rowSelectionType: "checkbox" | "radio"
}

interface PageState {
  show?: boolean
  selectedRows: GetImageRes[]
}

interface Page {

}

export default class JJ_FromSelectImage extends React.Component<PageProps, PageState> implements Page {

  componentDidMount() {
  }

  constructor(props: PageProps) {
    super(props);
    this.state = {
      selectedRows: props.value || []
    }
  }


  _view = () => {

    const values = this.props.value || []
    return (

      <Space
        style={{
          flexWrap: 'wrap',
        }}
        align='center' >
        {values.map((image, index) => {
          return (

            <Card
              key={index}
              hoverable={true}
              size={'small'}
              style={{
                marginRight: 10,

              }}
            >
              <Image
                width={100}
                src={image.url}
                preview={true}
                style={{
                  flexWrap: 'wrap',
                }}
              />

              <Button
                onClick={() => {
                  this.props.onChange && this.props.onChange(values.filter(value => value.id !== image.id))
                }}
                style={{
                  position: 'absolute',
                  right: 10,
                  top: 10,
                }}
                shape="circle"
                icon={<DeleteOutlined />} size={'small'} />

            </Card>


          )
        })}
        {values.length >= (this.props.maxCount || 1)
          ? <div />
          : <Card
            size={'small'}
            hoverable={true}
            onClick={() => {
              this.setState({ show: true })
            }}
          >
            <div
              style={{
                width: 100,
                height: 100,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <PlusOutlined style={{ fontSize: 80 }} />
            </div>
          </Card>
        }

      </Space>


    )

  }

  render() {
    return (
      <>
        {this._view()}
        <Modal
          maskClosable={false}
          title={'選擇圖片'}
          visible={this.state.show}
          onOk={() => {
            this.props.onChange && this.props.onChange([...this.props.value || [], ...this.state.selectedRows])
            this.setState(state => ({
              show: false,
            }))
          }}
          onCancel={() => this.setState(state => ({
            show: false,
          }))}
          width={'70%'}
        >
          <ImageLibraryList
            tableProps={{
              rowSelection: {
                type: this.props.rowSelectionType,
                selectedRowKeys: this.state.selectedRows.map(item => item.id),
                onChange: (selectedRowKeys, selectedRows) => {
                  this.setState({ selectedRows })
                },
              },
              scroll: { x: 1200, y: 500 }
            }}
          />
        </Modal>
      </>
    )
  }

}

