
import config from '../config';

const API_URL = config.REACT_APP_API

export interface ApiConfigType {
  url: string
  timeout: number
}

export const ApiConfig: ApiConfigType = {
  url: API_URL,
  timeout: 10000,
}
