import React, { } from 'react';
import { Modal, message, Spin, Button, Tag, Transfer, Layout, Select, Space } from 'antd';
import api, { GetFoodCardUserMemberFamilyRes, GetFoodCardUserMemberRes, GetKioskVendingRes, GetFoodCardUserMemberFamilyListParameters, GetKioskVendingListParameters, PostFoodCardUserMemberFamilyChangeCodeParameters, GetFoodCardUserMemberListParameters } from '../../api';
import { Page } from '../home/Home.interface';
import JJ_TableTransfer from '../../components/JJ_TableTransfer';
import { ColumnsType } from 'antd/lib/table';


interface PageState {
  families: (GetFoodCardUserMemberFamilyRes & { foodCardUserMembers: GetFoodCardUserMemberRes[] })[]
  familiyIds: string[]
  kioskVendingId?: string
  kioskVendings: GetKioskVendingRes[]
  spinning?: boolean
}


export interface Props {
  onSave(familiyIds: PageState['familiyIds']): void
  title: string
  visible?: boolean
  onCancel(): void
}

type PageProps = Props


class FoodCardUserMemberFamilyChangeCodeModal extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);
    this.state = {
      families: [],
      familiyIds: [],
      kioskVendings: [],
    }
  }

  componentDidMount() {

    this._onLoadData()
  }


  _onSave = async () => {
    try {
      console.log('_onSave', this.state)
      this.setState({
        spinning: true
      })
      const kioskVendingId = this.state.kioskVendingId
      if (kioskVendingId) {
        await Promise.all(
          this.state.familiyIds.map(async familiyId => {
            const res = await this._postFoodCardUserMemberFamilyChangeCode({ foodCardUserMemberFamilyId: familiyId, kioskVendingId: kioskVendingId })
            return res
          })
        )
      }
      this.props.onSave(this.state.familiyIds)
      this.setState({
        spinning: false
      })
    } catch (error) {
      console.log(error)
      this.setState({
        spinning: false
      })
    }
  }


  _getFoodCardUserMemberFamilyList = async (params: GetFoodCardUserMemberFamilyListParameters) => {
    const res = await api.GetFoodCardUserMemberFamilyList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getFoodCardUserMemberList = async (params: GetFoodCardUserMemberListParameters) => {
    const res = await api.GetFoodCardUserMemberList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _postFoodCardUserMemberFamilyChangeCode = async (params: PostFoodCardUserMemberFamilyChangeCodeParameters) => {
    const res = await api.PostFoodCardUserMemberFamilyChangeCode(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getKioskVendingList = async (params: GetKioskVendingListParameters) => {
    const res = await api.GetKioskVendingList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _onLoadData = async (): Promise<any> => {

    try {
      this.setState({
        spinning: true
      })
      const emptyFamilyList = await this._getFoodCardUserMemberFamilyList({ count: 1000, isEmptyFoodCardCode: true })
      const kioskVendingList = await this._getKioskVendingList({ count: 1000 })
      this.setState({
        families: await Promise.all(
          emptyFamilyList.data.map(async family => {
            const list = await this._getFoodCardUserMemberList({ count: 1000, foodCardUserMemberFamilyId: family.id })
            const foodCardUserMembers = list.data
            return {
              ...family,
              foodCardUserMembers,
            }
          })

        ),
        kioskVendings: kioskVendingList.data,
        spinning: false,
      })

    } catch (error) {
      this.setState({
        spinning: false
      })
      console.log(error)
    }
  };


  _getColumuns = () => {
    const columns: ColumnsType<GetFoodCardUserMemberFamilyRes & { foodCardUserMembers: GetFoodCardUserMemberRes[] }> = [

      {
        title: 'JcConsole',
        dataIndex: 'jcConsole',
        key: 'jcConsole',
      },

      {
        title: '成員姓名',
        dataIndex: 'name',
        key: 'name',
        render: (value, record) => {

          if (record.foodCardUserMembers.length <= 0) {
            return
          }
          return record.foodCardUserMembers[0].name
        }
      },
      {
        title: '電話號碼',
        dataIndex: 'phone',
        key: 'phone',
        render: (value, record) => {
          const [areaCode, phoneCode] = String(record.phone).split('-')
          if (phoneCode) {
            return phoneCode
          }
        }
      },

    ]

    return columns
  }

  _view = () => {
    return (
      <Layout
        style={{
          backgroundColor: 'white',
        }}
      >
        <Space
          direction='vertical'
        >
          <JJ_TableTransfer
            transferProps={{
              titles: ['未選擇用戶家庭', '已選擇用戶家庭'],
              listStyle: {
              },
              dataSource: this.state.families.map(family => ({
                ...family,
                key: family.id,
              })),
              targetKeys: this.state.familiyIds,
              showSearch: true,
              onChange: targetKeys => this.setState({ familiyIds: targetKeys }),
              filterOption: (inputValue, item) => {
                return String(item.phone || '').indexOf(inputValue) !== -1 || String(item.name || '').indexOf(inputValue) !== -1 || String(item.jcConsole || '').indexOf(inputValue) !== -1
              }
            }}

            leftColumns={this._getColumuns()}

            rightColumns={this._getColumuns()}

          />

          <Select
            style={{
              width: 300
            }}
            allowClear={true}
            placeholder={'請選擇售賣機'}
            value={this.state.kioskVendingId}
            onChange={e => this.setState({ kioskVendingId: e })}
            showSearch={true}
            filterOption={(input: any, option) => {
              if (option && option.data) {
                const data: GetKioskVendingRes = option.data
                return (
                  data.code.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  String(data.name || '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                )
              }
              return false
            }}
          >
            {this.state.kioskVendings.map(kioskVending => {
              return (
                <Select.Option
                  key={kioskVending.id}
                  value={kioskVending.id}
                  data={kioskVending}
                >
                  <Space>
                    <Tag color='blue'> {kioskVending.code}</Tag>
                    <Tag color='success'> {kioskVending.name}</Tag>
                  </Space>
                </Select.Option>
              )
            })}
          </Select>
        </Space>

      </Layout>
    )
  }





  render() {

    return (

      <Modal
        maskClosable={false}
        title={this.props.title}
        visible={this.props.visible}
        onCancel={this.props.onCancel}
        width={'85%'}
        onOk={async () => await this._onSave()}
        okButtonProps={{
          disabled: !(this.state.kioskVendingId && this.state.familiyIds.length > 0)
        }}
      >

        <Spin
          spinning={this.state.spinning}
        >
          {this._view()}
        </Spin>

      </Modal>

    )
  }

}

export default FoodCardUserMemberFamilyChangeCodeModal
