import {createStore, applyMiddleware, compose} from 'redux'
import JJ_RootReducer from '../reducers/JJ_RootReducer'
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

const persistedReducer = persistReducer({
    key: 'root',
    storage,
    blacklist: [],//黑名单
    whitelist: ['config'],//白名单
}, JJ_RootReducer)



const configureStore = (preloadedState?: any): any => {
    const newWindow = <any>window
    return createStore(
        persistedReducer,
        preloadedState,
        newWindow.devToolsExtension
            ? compose(
                applyMiddleware(thunk),
                newWindow.devToolsExtension(),
            )
            : compose(
                applyMiddleware(thunk),
            )
    );
}

const store = configureStore();
const persistor = persistStore(store)

export { store, persistor };












