import React, { } from 'react';
import { Menu, Dropdown, Button } from 'antd';
import { CaretDownFilled } from '@ant-design/icons';

interface PageState {

}
interface MenuItemType {
  type: 'item'
  key: string
  name: string
  disabled?: boolean
  onAction(key: string): void
}

interface MenuItemGroupType {
  disabled?: boolean
  type: 'group'
  title: string
  menuItems: MenuItemType[]
}

interface SubMenuType {
  disabled?: boolean
  type: 'sub'
  title: string
  menuItems: MenuItemType[]
}

interface Props {
  disabled?: boolean
  title?: string
  menus: (MenuItemType | MenuItemGroupType | SubMenuType)[]
  defaultKey?: string
}

type PageProps = Props

interface Page {

}

export default class JJ_TableItemAction extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {

  }


  render() {
    const menu = (
      <Menu>
        {this.props.menus.map((menu, index) => {
          const menuItemComponent = (res: MenuItemType) => {
            return (
              <Menu.Item
                key={res.key}
                disabled={res.disabled}
                onClick={() => res.onAction(res.key)}
              >
                {res.name}
              </Menu.Item>
            )
          }

          const menuItemGroupComponent = (res: MenuItemGroupType) => {
            return (
              <Menu.ItemGroup
                disabled={res.disabled}
                title={res.title}
                key={res.title}
              >
                {res.menuItems.map(item => menuItemComponent(item))}
              </Menu.ItemGroup>
            )
          }

          const subMenuComponent = (res: SubMenuType) => {
            return (
              <Menu.SubMenu
                disabled={res.disabled}
                title={res.title}
                key={res.title}
              >
                {res.menuItems.map(item => menuItemComponent(item))}
              </Menu.SubMenu>
            )
          }


          switch (menu.type) {
            case 'item': {
              return menuItemComponent(menu)
            }
            case 'group': {
              return menuItemGroupComponent(menu)
            }
            case 'sub': {
              return subMenuComponent(menu)
            }
          }

        })}
      </Menu>
    )

    if (!this.props.defaultKey) {
      return (
        <Dropdown overlay={menu}>
          <Button
            type={'primary'}
            onClick={e => e.preventDefault()}
            disabled={this.props.disabled}
            icon={<CaretDownFilled />}
          >
            {this.props.title}
          </Button>
        </Dropdown>
      )
    } else {
      const defaultMenu = (this.props.menus.find(menu => (menu as MenuItemType).key === this.props.defaultKey) as MenuItemType)
      return (
        <Dropdown.Button
          type="primary"
          overlay={menu}
          onClick={() => defaultMenu.onAction(defaultMenu.key)}
        >
          {defaultMenu?.name}
        </Dropdown.Button>
      )
    }
  }

}
