import React, { } from 'react';
import { Modal, message, Spin, Button, Tag, Transfer, Layout, Select, Space } from 'antd';
import api, { GetFoodCardUserMemberFamilyRes, GetFoodCardUserMemberRes, GetKioskVendingRes, GetFoodCardUserMemberFamilyListParameters, GetKioskVendingListParameters, PostFoodCardUserMemberFamilyChangeCodeParameters, GetFoodCardUserMemberListParameters, GetFoodCardUserMemberFamilyParameters } from '../../api';
import { Page } from '../home/Home.interface';


interface PageState {
  kioskVendingId?: string
  kioskVendings: GetKioskVendingRes[]
  spinning?: boolean
  family?: GetFoodCardUserMemberFamilyRes
}


export interface Props {
  familyId: string
  onSave(id: this['familyId']): void
  title: string
  visible?: boolean
  onCancel(): void
}

type PageProps = Props


class FoodCardUserMemberFamilyChangeOldModal extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);
    this.state = {
      kioskVendings: [],
    }
  }

  componentDidMount() {

    this._onLoadData()
  }


  _onSave = async () => {
    try {
      console.log('_onSave', this.state)
      this.setState({
        spinning: true
      })
      const kioskVendingId = this.state.kioskVendingId
      if (kioskVendingId) {
        await this._postFoodCardUserMemberFamilyChangeCode({ foodCardUserMemberFamilyId: this.props.familyId, kioskVendingId })
      }
      this.props.onSave(this.props.familyId)
      this.setState({
        spinning: false
      })
    } catch (error) {
      console.log(error)
      this.setState({
        spinning: false
      })
    }
  }


  _getFoodCardUserMemberFamilyList = async (params: GetFoodCardUserMemberFamilyListParameters) => {
    const res = await api.GetFoodCardUserMemberFamilyList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getFoodCardUserMemberFamily = async (params: GetFoodCardUserMemberFamilyParameters) => {
    const res = await api.GetFoodCardUserMemberFamily(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getFoodCardUserMemberList = async (params: GetFoodCardUserMemberListParameters) => {
    const res = await api.GetFoodCardUserMemberList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _postFoodCardUserMemberFamilyChangeCode = async (params: PostFoodCardUserMemberFamilyChangeCodeParameters) => {
    const res = await api.PostFoodCardUserMemberFamilyChangeCode(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getKioskVendingList = async (params: GetKioskVendingListParameters) => {
    const res = await api.GetKioskVendingList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _onLoadData = async (): Promise<any> => {

    try {
      this.setState({
        spinning: true
      })
      const kioskVendingList = await this._getKioskVendingList({ count: 1000 })
      const family = await this._getFoodCardUserMemberFamily({ id: this.props.familyId })
      this.setState({
        kioskVendings: kioskVendingList.data,
        spinning: false,
        family,
      })

    } catch (error) {
      this.setState({
        spinning: false
      })
      console.log(error)
    }
  };


  

  _view = () => {
    return (
      <Layout
        style={{
          backgroundColor: 'white',
        }}
      >
        <Space
          direction='vertical'
        >

          <Select
            style={{
              width: 300
            }}
            allowClear={true}
            placeholder={'請選擇售賣機'}
            value={this.state.kioskVendingId}
            onChange={e => this.setState({ kioskVendingId: e })}
            showSearch={true}
            filterOption={(input: any, option) => {
              if (option && option.data) {
                const data: GetKioskVendingRes = option.data
                return (
                  data.code.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  String(data.name || '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                )
              }
              return false
            }}
          >
            {this.state.kioskVendings.map(kioskVending => {
              return (
                <Select.Option
                  key={kioskVending.id}
                  value={kioskVending.id}
                  data={kioskVending}
                >
                  <Space>
                    <Tag color='blue'> {kioskVending.code}</Tag>
                    <Tag color='success'> {kioskVending.name}</Tag>
                  </Space>
                </Select.Option>
              )
            })}
          </Select>
        </Space>

      </Layout>
    )
  }





  render() {

    return (

      <Modal
        maskClosable={false}
        title={this.props.title}
        visible={this.props.visible}
        onCancel={this.props.onCancel}
        width={'85%'}
        onOk={async () => await this._onSave()}
        okButtonProps={{
          disabled: !(this.state.kioskVendingId)
        }}
      >

        <Spin
          spinning={this.state.spinning}
        >
          {this._view()}
        </Spin>

      </Modal>

    )
  }

}

export default FoodCardUserMemberFamilyChangeOldModal
