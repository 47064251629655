
import { AnyAction, Store } from "redux"
let myStore:Store<any, AnyAction>

export const getStore = (store:Store<any, AnyAction>)=>myStore = store
export const dispatch = (action:any) => myStore.dispatch(action)
export const getState = () => myStore.getState()






