import React, { } from 'react';
import JJ_From, { FormItemData } from "../../components/JJ_From";
import api, { GetKioskVendingRes, GetKioskVendingListParameters, GetSurveyParameters, PutSurveyParameters, PostSurveyParameters, GetSurveyListParameters, GetSurveyRes } from '../../api';
import { connect, ConnectedProps } from 'react-redux';
import { DatePicker, Switch } from 'antd';
import moment from 'moment';


interface PageState {
}

export type SurveyEditAndAddPropsType = { type: 'add' } | { type: 'edit', id: string }

interface Props {
  onFinish?(props: PageProps, id: string): void
}



interface Page {
  /**
   * 保存数据
   */
  _onFinish(values: any): void

  /**
   * 获取数据
   */
  _onLoadData(): Promise<{}>

  id?: string

}


type PageProps = Props & SurveyEditAndAddPropsType & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {

}

const mapState = (state: RootState) => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class SurveyEditAndAdd extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);

    this.state = {
    }

  }
  componentDidMount() {
  }

  _onFinish = async (values: any): Promise<void> => {

    console.log('_onFinish', this.props.type, values)

    switch (this.props.type) {
      case 'edit': {
        const res = await this._putSurvey({
          ...values,
          id: this.props.id,
          beginAt: values.beginAt ? moment(values.beginAt).toISOString() : undefined,
          endAt: values.endAt ? moment(values.endAt).toISOString() : undefined,
        })

        this.props.onFinish && this.props.onFinish(this.props, res.id)
      }
        break
      case 'add': {

        const res = await this._postSurvey({
          ...values,
          beginAt: values.beginAt ? moment(values.beginAt).toISOString() : undefined,
          endAt: values.endAt ? moment(values.endAt).toISOString() : undefined,
        })
        this.props.onFinish && this.props.onFinish(this.props, res.id)
      }
        break
    }
  }

  _onLoadData = async (): Promise<any> => {
    switch (this.props.type) {
      case 'edit': {
        const res = await this._getSurvey({ id: this.props.id })
        return {
          ...res,
          beginAt: res.beginAt ? moment(res.beginAt) : undefined,
          endAt: res.endAt ? moment(res.endAt) : undefined,
        }
      }
      case 'add': {
        return {
          isEnable: true,
          optional: false,
        }
      }


    }
  };

  _getSurvey = async (param: GetSurveyParameters) => {
    const res = await api.GetSurvey(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _getSurveyList = async (param: GetSurveyListParameters) => {
    const res = await api.GetSurveyList(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _putSurvey = async (param: PutSurveyParameters) => {
    const res = await api.PutSurvey(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }
  _postSurvey = async (param: PostSurveyParameters) => {
    const res = await api.PostSurvey(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }



  _getKioskVendingList = async (params: GetKioskVendingListParameters) => {
    const res = await api.GetKioskVendingList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }



  _formListData = (): FormItemData[] => {
    switch (this.props.type) {
      case 'edit': {
        return [
          {
            id: 'i18nText',
            name: 'title',
            label: '標題',
            rules: [
              {
                required: true,
              }
            ],
          },

          {
            props: { valuePropName: 'checked' },
            id: 'optional',
            label: '是否可選',
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <Switch checked={true} />
            )
          },

          {
            props: { valuePropName: 'checked' },
            id: 'isEnable',
            label: '是否啟動',
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <Switch />
            )
          },


          {
            id: 'beginAt',
            label: '開啟時間',
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <DatePicker picker='date' />
            )
          },

          {
            id: 'endAt',
            label: '結束時間',
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <DatePicker picker='date' />
            )
          },

          {
            id: 'remark',
            label: '備註',
            rules: [
              {
                required: false,
                message: `請輸入 備註`
              }
            ],
          },
          {
            id: 'submit',
          },
        ]
      }
      case 'add': {


        return [

          {
            id: 'i18nText',
            name: 'title',
            label: '標題',
            rules: [
              {
                required: true,
              }
            ],
          },

          {
            props: { valuePropName: 'checked' },
            id: 'optional',
            label: '是否可選',
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <Switch checked={true} />
            )
          },

          {
            props: { valuePropName: 'checked' },
            id: 'isEnable',
            label: '是否啟動',
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <Switch />
            )
          },


          {
            id: 'beginAt',
            label: '開啟時間',
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <DatePicker picker='date' />
            )
          },

          {
            id: 'endAt',
            label: '結束時間',
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <DatePicker picker='date' />
            )
          },

          {
            id: 'remark',
            label: '備註',
            rules: [
              {
                required: false,
                message: `請輸入 備註`
              }
            ],
          },
          {
            id: 'submit',
          },
        ]
      }


    }
  }

  render() {
    return (
      <div id="SurveyEditAndAdd">
        <JJ_From
          formListData={this._formListData()}
          onLoadData={this._onLoadData}
          onFinish={this._onFinish}
        />
      </div>
    )
  }

}
export default connector(SurveyEditAndAdd)
