import React, { } from 'react';
import api, { PostSurveySetUserParameters, GetSurveyResponseListParameters, GetFoodCardUserMemberFamilyRes, GetFoodCardUserMemberFamilyListParameters, } from '../../api';
import { connect, ConnectedProps } from 'react-redux';
import { Space, Modal, Spin, Layout } from 'antd';
import JJ_TableTransfer from '../../components/JJ_TableTransfer';
import { ColumnsType } from 'antd/lib/table';


interface PageState {
  foodCardUserMemberFamily: GetFoodCardUserMemberFamilyRes[]
  selelctUserIds: string[]
  spinning?: boolean
}

export type SurveySetUserModalPropsType = { surveyId: string }

interface Props {
  onSave(familiyIds: PageState['selelctUserIds']): void
  title: string
  visible?: boolean
  onCancel(): void

}



type FromItemData = PostSurveySetUserParameters


interface Page {

}


type PageProps = Props & SurveySetUserModalPropsType & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {

}

const mapState = (state: RootState) => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class SurveySetUserModal extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);

    this.state = {
      foodCardUserMemberFamily: [],
      selelctUserIds: [],
    }

  }
  componentDidMount() {
    this._onLoadData()
  }


  _onLoadData = async (): Promise<any> => {

    try {
      this.setState({
        spinning: true,
      })

      const surveyResponseList = await this._getSurveyResponseList({ surveyId: this.props.surveyId, count: 100000, })
      const foodCardUserMemberFamilyList = await this._getFoodCardUserMemberFamilyList({ count: 10000 })
      this.setState({
        selelctUserIds: surveyResponseList.data.map(surveyResponse => surveyResponse.foodCardUserMemberFamily.userId),
        foodCardUserMemberFamily: foodCardUserMemberFamilyList.data,
        spinning: false,

      })
    } catch (error) {
      this.setState({
        spinning: false,
      })
    }


  }


  _getSurveyResponseList = async (param: GetSurveyResponseListParameters) => {
    const res = await api.GetSurveyResponseList(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _getFoodCardUserMemberFamilyList = async (param: GetFoodCardUserMemberFamilyListParameters) => {
    const res = await api.GetFoodCardUserMemberFamilyList(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }


  _postSurveySetUser = async (param: PostSurveySetUserParameters) => {
    const res = await api.PostSurveySetUser(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }


  _getColumuns = () => {
    const columns: ColumnsType<GetFoodCardUserMemberFamilyRes> = [
      {
        title: 'JcConsole',
        dataIndex: 'jcConsole',
        key: 'jcConsole',
      },
      {
        title: '食品卡號碼',
        dataIndex: 'foodCardCode',
        key: 'foodCardCode',
      },
    ]
    return columns
  }


  _onSave = async () => {
    try {
      console.log('_onSave', this.state)
      this.setState({
        spinning: true
      })
      await this._postSurveySetUser({ userIds: this.state.selelctUserIds, surveyId: this.props.surveyId })
      this.props.onSave(this.state.selelctUserIds)
      this.setState({
        spinning: false
      })
    } catch (error) {
      console.log(error)
      this.setState({
        spinning: false
      })
    }
  }



  _view = () => {
    return (
      <Layout
        style={{
          backgroundColor: 'white',
        }}
      >
        <Space
          direction='vertical'
        >
          <JJ_TableTransfer
            transferProps={{
              titles: ['未選擇用戶家庭', '已選擇用戶家庭'],
              listStyle: {
              },
              dataSource: this.state.foodCardUserMemberFamily.map(family => ({
                ...family,
                key: family.userId,
              })),
              targetKeys: this.state.selelctUserIds,
              showSearch: true,
              onChange: targetKeys => this.setState({ selelctUserIds: targetKeys }),
              filterOption: (inputValue, item) => {
                return String(item.JcConsole || '').indexOf(inputValue) !== -1 ||
                  String(item.foodCardCode || '').indexOf(inputValue) !== -1
              }
            }}
            leftColumns={this._getColumuns()}
            rightColumns={this._getColumuns()}
            tableProps={{
              scroll: { y: 400 },
              pagination: {
                pageSizeOptions: ['20', '50', '100', '200', '500'],
                defaultPageSize: 100,
              }
            }}
          />

        </Space>

      </Layout>
    )
  }


  render() {

    return (

      <Modal
        maskClosable={false}
        title={this.props.title}
        visible={this.props.visible}
        onCancel={this.props.onCancel}
        width={'85%'}
        onOk={async () => await this._onSave()}
      >
        <Spin
          spinning={this.state.spinning}
        >
          {this._view()}
        </Spin>

      </Modal>

    )
  }

}
export default connector(SurveySetUserModal)
