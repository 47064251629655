import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, message, Spin, Button, Tag, Space, Typography, Avatar } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import api, { GetFoodCardUserMemberListParameters, GetFoodCardListParameters, GetKioskVendingListParameters, GetSignupFormRes, DeleteSignupFormParameters, GetSignupFormListParameters, GetFoodCardUserMemberFamilyRes, GetFoodCardUserMemberFamilyAreaRes, GetFoodCardUserMemberFamilyAreaListParameters } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import JJ_TableItemAction from '../../components/JJ_TableItemAction';
import JJ_TableItemMoment from '../../components/JJ_TableItemMoment';
import { PlusOutlined, DownloadOutlined } from '@ant-design/icons';
import { connect, ConnectedProps } from 'react-redux';
import JJ_TableItemCopyableId from '../../components/JJ_TableItemCopyableId';
import config from '../../config';
import moment from 'moment';
import SignupFormDetailsModal from './SignupFormDetailsModal';

const { confirm } = Modal;
const { Paragraph, Text } = Typography;


interface PageState {
  spinDownloadXlsx?: boolean
  familyAreas: GetFoodCardUserMemberFamilyAreaRes[]
  isSpinLoading: boolean
  detailsModal?: {
    show: boolean
    key: any
    signupFormId: string
  }
}
interface Props {
}


interface Page {

}


type PageProps = Props & RouteChildrenProps & ConnectedProps<typeof connector>

interface RootState {

}

const mapState = () => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)






type TableData = GetSignupFormRes

class SignupFormList extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: true,
      familyAreas: [],
    }
  }
  tableRef?: JJ_Table<TableData> | null
  componentDidMount() {
    this._initData()
  }
  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true
      })
      const familyAreaLisRes = await this._getFoodCardUserMemberFamilyAreaList({ count: 1000 })



      this.setState({
        isSpinLoading: false,
        familyAreas: familyAreaLisRes.data,

      })
    } catch (error) {
      this.setState({
        isSpinLoading: false
      })
    }
  }


  _getFoodCardUserMemberFamilyAreaList = async (params: GetFoodCardUserMemberFamilyAreaListParameters) => {
    const res = await api.GetFoodCardUserMemberFamilyAreaList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getKioskVendingList = async (params: GetKioskVendingListParameters) => {
    const res = await api.GetKioskVendingList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _deleteSignupForm = async (params: DeleteSignupFormParameters) => {
    const res = await api.DeleteSignupForm(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getFoodCardUserMemberList = async (params: GetFoodCardUserMemberListParameters) => {
    const res = await api.GetFoodCardUserMemberList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _postSignupFormDownloadXlsx = async () => {
    const res = await api.PostSignupFormDownloadXlsx()
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }




  _getFoodCardList = async (params: GetFoodCardListParameters) => {
    const res = await api.GetFoodCardList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }





  _getStatusItemView = (status: GetSignupFormRes['status']) => {
    switch (status) {
      case 'APPROVED': {
        return <Tag color='success'>已審批</Tag>
      }
      case 'DRAFT': {
        return <Tag color='default'>未完成</Tag>
      }
      case 'EXPIRED': {
        return <Tag color='red'>已過期</Tag>
      }
      case 'REJECTED': {
        return <Tag color="red">已拒絕</Tag>
      }
      case 'NOT_APPROVED': {
        return <Tag color="red">已不批准</Tag>
      }
      case 'SELECTED_EFOOD_BANK': {
        return <Tag color="red">已選 E+ 食</Tag>
      }
      case 'SELECTED_HOT_MEAL': {
        return <Tag color="red">已選趁熱食</Tag>
      }
      case 'SUBMITTED': {
        return <Tag color='green'>已提交</Tag>
      }
      case 'ACCEPTED': {
        return <Tag color='green'>已接纳</Tag>
      }
    }
  }


  _signupFormDetailsModal = () => {

    const detailsModal = this.state.detailsModal
    if (detailsModal) {
      return (
        <SignupFormDetailsModal
          key={detailsModal.key}
          title={'申請表格詳情'}
          visible={detailsModal.show}
          onCancel={() => {
            this.setState({
              detailsModal: { ...detailsModal, show: false }
            })
          }}
          onOk={async (status) => {


            switch (status) {
              case 'APPROVED': {
                this.tableRef && await this.tableRef.refreshData()
                message.success(`審批成功`)
              }
                break
              case 'REJECTED': {
                this.tableRef && await this.tableRef.refreshData()
                message.success(`拒絕成功`)
              }
                break

              case 'DRAFT': {
                this.tableRef && await this.tableRef.refreshData()
                message.success(`解鎖成功`)
              }
                break

              case 'RESEND': {
                this.tableRef && await this.tableRef.refreshData()
                message.success(`解鎖並重發短訊成功`)
              }
                break

            }
            this.setState({
              detailsModal: { ...detailsModal, show: false }
            })
          }}
          signupFormId={detailsModal.signupFormId}
        />
      )
    }


  }


  render() {
    return (
      <div id="SignupFormList"
      >
        {this._signupFormDetailsModal()}

        <Space>

          <Button
            type='primary'
            loading={this.state.spinDownloadXlsx}
            style={{ marginBottom: 5 }}
            onClick={async () => {
              try {
                this.setState({
                  spinDownloadXlsx: true
                })
                const data = await this._postSignupFormDownloadXlsx()
                window.open(data.url)
                this.setState({
                  spinDownloadXlsx: false
                })
              } catch (error) {
                this.setState({
                  spinDownloadXlsx: false
                })
                console.log(error)
              }

            }}
            icon={<DownloadOutlined />}
          >
            {'從清單導出 xlsx'}
          </Button>

        </Space>

        <Spin spinning={this.state.isSpinLoading}>
          {!this.state.isSpinLoading ? (
            <JJ_Table<TableData, {
              desc: 'ascend' | 'descend'
              createAt?: [moment.Moment, moment.Moment] | null
              status?: GetSignupFormListParameters['status']
              searchTimeType?: GetSignupFormListParameters['searchTimeType']
              isFirstOpened?: 'true' | 'false'
              isRenewal?: 'true' | 'false'
              foodCardUserMemberFamilyAreaId?: string

            }>
              ref={ref => this.tableRef = ref}
              isSearchText={true}
              sourceItems={[
                {
                  type: 'select',
                  defaultValue: undefined,
                  key: 'status',
                  placeholder: '請選擇任意狀態',
                  span: 4,
                  allowClear: true,
                  options: [
                    'DRAFT',
                    'SUBMITTED',
                    'REJECTED',
                    'EXPIRED',
                    'SELECTED_HOT_MEAL',
                    'SELECTED_EFOOD_BANK',
                    'APPROVED',
                    'NOT_APPROVED',
                    'ACCEPTED',
                  ].map((item: any) => {
                    return {
                      value: item,
                      name: this._getStatusItemView(item),
                      disabled: false,
                    }
                  })
                },

                {
                  type: 'select',
                  defaultValue: undefined,
                  key: 'foodCardUserMemberFamilyAreaId',
                  placeholder: '請選擇任意區域',
                  allowClear: true,
                  showSearch: true,
                  filterOption: (input: string, option: any) => {
                    if (option && option.children.props.children.length) {
                      const text = option.children.props.children[0].props.children + '' + option.children.props.children[1].props.children;
                      return text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }
                    return false;
                  },
                  options: this.state.familyAreas.map(area => {
                    return {
                      value: area.id,
                      name: (
                        <>
                          <span>{area.title}</span>
                          <Tag style={{ marginLeft: 8 }}>{area.code}</Tag>
                        </>
                      ),
                      disabled: false,
                    }
                  })
                },

                {
                  type: 'select',
                  defaultValue: undefined,
                  placeholder: '是否選擇第一次打開',
                  key: 'isFirstOpened',
                  allowClear: true,
                  options: [
                    {
                      value: 'false',
                      name: <Tag color="red">未打開</Tag>,
                      disabled: false,
                    },
                    {
                      value: 'true',
                      name: <Tag color="blue">已打開</Tag>,
                      disabled: false,
                    },
                  ]
                },
                {
                  type: 'select',
                  defaultValue: undefined,
                  placeholder: '類別',
                  key: 'isRenewal',
                  allowClear: true,
                  span: 3,
                  options: [
                    {
                      value: 'true',
                      name: <Tag color="red">續期</Tag>,
                      disabled: false,
                    },
                    {
                      value: 'false',
                      name: <Tag>首次</Tag>,
                      disabled: false,
                    },
                  ]
                },

                {
                  type: 'select',
                  defaultValue: undefined,
                  placeholder: '请选择任意筛选时间类型',
                  key: 'searchTimeType',
                  allowClear: true,
                  options:
                    [
                      { value: 'firstOpenedAt', title: '打開時間' },
                      { value: 'operatedAt', title: '管理操作時間' },
                      { value: 'expiredAt', title: '過期時間' },
                      { value: 'submittedAt', title: '提交時間' },
                      { value: 'createdAt', title: '創建時間' },
                    ].map(item => ({
                      value: item.value,
                      name: item.title,
                      disabled: false,
                    }))
                },

                {
                  type: 'rangePicker',
                  key: 'createAt',
                  span: 5,

                  props: {
                    placeholder: ['開始日期', '結束日期']

                  }
                },

              ]}
              columns={[
                {
                  key: 'action',
                  width: 120,
                  render: (value: any, record) => {
                    return (
                      <JJ_TableItemAction
                        defaultKey="detail"
                        menus={[
                          {
                            type: 'item',
                            disabled: false,
                            key: 'detail',
                            name: '詳情',
                            onAction: () => {
                              this.setState({
                                detailsModal: { signupFormId: record.id, show: true, key: Date.now() }
                              })
                            }
                          },
                          {
                            type: 'item',
                            disabled: false,
                            key: 'url',
                            name: '顯示表格連結',
                            onAction: () => {
                              const url = `${config.REACT_APP_SIGNUP_FORM_HOST}/${record.id}`;
                              Modal.info({
                                title: '表格連結',
                                content: (
                                  <Space direction="vertical" style={{ width: '100%' }}>
                                    <div><a href={url} target="_blank">{url}</a></div>
                                    <div>
                                      驗証碼: <Text code>{record.token}</Text>
                                    </div>
                                  </Space>
                                )
                              })
                            }
                          },
                          {
                            type: 'item',
                            disabled: config.REACT_APP_DELETE_DISABLED,
                            key: 'delete',
                            name: '删除',
                            onAction: () => {
                              confirm({
                                title: `是否刪除${record.id}`,
                                icon: <ExclamationCircleOutlined />,
                                okText: 'Yes',
                                okType: 'danger',
                                cancelText: 'No',
                                onOk: async () => {
                                  try {
                                    await this._deleteSignupForm({ id: record.id })
                                    this.tableRef && this.tableRef.refreshData()
                                    message.success(`${record.id} 删除成功`)
                                  } catch (error) {
                                    message.error(`${record.id} 删除失败 [${error.message}]`);
                                  }
                                },
                                onCancel() {
                                },
                              });
                            }
                          },
                        ]}
                      />
                    )

                  },
                },
                {
                  title: 'ID',
                  dataIndex: 'id',
                  key: 'id',
                  width: 50,
                  render: (value) => (
                    <JJ_TableItemCopyableId
                      text={value}
                    />
                  )
                },

                {
                  title: 'JcConsole',
                  dataIndex: ['foodCardUserMemberFamily', 'jcConsole'],
                  key: 'foodCardUserMemberFamily.jcConsole',
                  width: 150,
                },

                {
                  title: '類別',
                  dataIndex: 'isRenewal',
                  key: 'isRenewal',
                  width: 50,
                  render: (value) => {
                    return value
                      ? <Avatar size="small" style={{ backgroundColor: '#ff0000' }}>續</Avatar>
                      : <Avatar size="small">首</Avatar>
                  }
                },

                {
                  title: '狀態',
                  dataIndex: 'status',
                  key: 'status',
                  width: 150,
                  render: (value, record) => {
                    return this._getStatusItemView(record.status)
                  }
                },

                {
                  title: '申請人',
                  dataIndex: ['user', 'nickname'],
                  key: 'phouser.nickname',
                  width: 200,
                },


                {
                  title: '家庭區域',
                  dataIndex: ['foodCardUserMemberFamily', 'area'],
                  key: 'foodCardUserMemberFamily.area',
                  width: 200,
                  render: (value) =>
                    <div
                      style={{
                        maxHeight: 70,
                        overflow: 'auto',
                      }}>
                      {(() => {
                        const area = this.state.familyAreas.find(area => area.id === value)
                        return area ? area.title : '-'
                      })()}
                    </div>
                },


                {
                  title: '電話號碼',
                  dataIndex: 'phone',
                  key: 'phone',
                  width: 200,
                  render: (value, record) => {
                    const [areaCode, phoneCode] = String((record.foodCardUserMemberFamily as GetFoodCardUserMemberFamilyRes).phone).split('-')
                    if (phoneCode) {
                      return phoneCode
                    }
                  }
                },

                // {
                //   title: '驗證碼',
                //   dataIndex: ['token'],
                //   key: 'token',
                //   width: 100,
                // },


                {
                  title: '用戶地址',
                  width: 200,
                  dataIndex: ['foodCardUserMemberFamily', 'address'],
                  key: 'foodCardUserMemberFamily.address',
                  render: (value) =>
                    <div
                      style={{
                        maxHeight: 70,
                        overflow: 'auto',
                      }}>
                      {value}
                    </div>
                },

                {
                  title: '電郵',
                  dataIndex: ['foodCardUserMemberFamily', 'email'],
                  key: 'foodCardUserMemberFamily.email',
                  width: 200,
                },

                {
                  title: '申請日期',
                  dataIndex: ['foodCardUserMemberFamily', 'appliedAt'],
                  key: 'foodCardUserMemberFamily.appliedAt',
                  width: 110,
                  render: (value) => {
                    if (value) {
                      return (
                        <JJ_TableItemMoment
                          date={value}
                        />
                      )
                    }
                  }
                },

                {
                  title: '打開時間',
                  width: 150,
                  dataIndex: 'firstOpenedAt',
                  key: 'firstOpenedAt',
                  render: (value) => (
                    <JJ_TableItemMoment
                      date={value}
                    />
                  )
                },

                {
                  title: '提交時間',
                  width: 150,
                  dataIndex: 'submittedAt',
                  key: 'submittedAt',
                  render: (value) => (
                    <JJ_TableItemMoment
                      date={value}
                    />
                  )
                },

                {
                  title: '過期時間',
                  width: 150,
                  dataIndex: 'expiredAt',
                  key: 'expiredAt',
                  render: (value) => (
                    <JJ_TableItemMoment
                      date={value}
                    />
                  )
                },

                {
                  title: '管理操作時間',
                  width: 150,
                  dataIndex: 'operatedAt',
                  key: 'operatedAt',
                  render: (value) => (
                    <JJ_TableItemMoment
                      date={value}
                    />
                  )
                },

                {
                  title: '備註',
                  dataIndex: ['foodCardUserMemberFamily', 'remark'],
                  key: 'foodCardUserMemberFamily.remark',
                  width: 250,
                  render: (value, record) => {
                    return (
                      <Paragraph
                        ellipsis={{
                          rows: 1,
                          expandable: true,
                        }}
                        style={{
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        {value}
                      </Paragraph>
                    )
                  }
                },

                {
                  title: '創建時間',
                  width: 120,
                  fixed: 'right',
                  dataIndex: 'createdAt',
                  key: 'createdAt',
                  render: (value) => (
                    <JJ_TableItemMoment
                      date={value}
                    />
                  )
                },
              ]}
              title={'申請表格清單'}
              tableProps={{
                scroll: {
                  x: 1200,
                }
              }}

              onDataSource={async (body) => {
                const { sourceItemBody } = body
                const params: {
                  isAscend?: GetSignupFormListParameters['isAscend']
                  beginAt?: GetSignupFormListParameters['beginAt']
                  endAt?: GetSignupFormListParameters['endAt']
                  status?: GetSignupFormListParameters['status']
                  isFirstOpened?: GetSignupFormListParameters['isFirstOpened']
                  isRenewal?: GetSignupFormListParameters['isRenewal']
                  searchTimeType?: GetSignupFormListParameters['searchTimeType']
                  foodCardUserMemberFamilyAreaId?: GetSignupFormListParameters['foodCardUserMemberFamilyAreaId']
                } = {}
                if (sourceItemBody) {
                  if (sourceItemBody.desc) {
                    params.isAscend = sourceItemBody.desc === 'ascend' ? true : false
                  }
                  params.status = sourceItemBody.status
                  const createAt = sourceItemBody.createAt

                  if (sourceItemBody.isFirstOpened === 'false') {
                    params.isFirstOpened = false
                  }

                  if (sourceItemBody.isFirstOpened === 'true') {
                    params.isFirstOpened = true
                  }

                  if (sourceItemBody.isRenewal === 'false') {
                    params.isRenewal = false
                  }

                  if (sourceItemBody.isRenewal === 'true') {
                    params.isRenewal = true
                  }


                  params.searchTimeType = sourceItemBody.searchTimeType
                  params.foodCardUserMemberFamilyAreaId = sourceItemBody.foodCardUserMemberFamilyAreaId

                  if (createAt && createAt.length === 2) {
                    const [beginAtMoment, endAtMoment,] = createAt
                    params.beginAt = beginAtMoment.toISOString()
                    params.endAt = endAtMoment.toISOString()
                  }


                }

                const res = await api.GetSignupFormList({
                  ...body,
                  ...params,
                  scope: ['operatedUser', 'foodCardUserMemberFamily', 'user'],
                })

                if (res.kind !== 'ok') throw new Error(res.kind)

                const totalCount = res.data.totalCount


                return {
                  totalCount,
                  data: res.data.data,
                }
              }}
            />
          )
            : (<div style={{ height: 500 }} />)}
        </Spin>

      </div>
    )
  }

}
export default connector(SignupFormList)

