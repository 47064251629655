import React, { Component, ReactElement } from 'react';
import { HashRouter as Router, Link, Route, Redirect, Switch, HashRouter, BrowserRouter } from 'react-router-dom';
import Main from "../views/main/Main";
import Login from "../views/login/Login";


const requireAuth = (Page: any, props: any): ReactElement => {
  return <Page {...props} />
}



class App extends Component {
  render() {
    return (
      <Router
      >
        <Switch>
          <Route path="/login" component={Login} ></Route>
          <Route path="/" component={(props: any) => requireAuth(Main, props)}></Route>
        </Switch>
      </Router>
    );
  }
}


export default App


