import React, { ReactElement } from 'react';
import { Tabs, Card, Input, Modal, TimePicker, Select, Button } from 'antd';
import { GetQuotaIntervalRes, GetQuotaIntervalDataRes } from '../api';
import moment from 'moment';
import JJ_From, { FormItemData } from './JJ_From';
import {
  PlusOutlined,
  DeleteOutlined,
  FormOutlined,
} from '@ant-design/icons';
const format = 'HH:mm';
interface PageProps {
  value?: GetQuotaIntervalRes['datas']
  onChange?: (value?: GetQuotaIntervalRes['datas']) => void;
}

interface PageState {
  modalNewQuotaInterval?: {
    show?: boolean
    key?: any
    data?: GetQuotaIntervalDataRes
    type?: 'add' | 'edit',
    index?: number
  }
}

interface Page {

}


export default class JJ_FromQuotaIntervalDatas extends React.Component<PageProps, PageState> implements Page {

  componentDidMount() {


  }


  constructor (props: any) {
    super(props);
    this.state = {}
  }

  _formListData = (): FormItemData[] => {


    return [

      {
        id: 'weekDay',
        label: '日期',
        rules: [
          {
            required: true,
            message: `請輸入日期`,
          }
        ],
        componet: (
          <Select
            style={{ width: 120 }}
            options={[1, 2, 3, 4, 5, 6, 0].map((item) => {
              return {
                value: item,
                label: moment().day(item).format('dddd'),
              }
            })}
          />
        )

      },
      {
        id: 'startToEndTime',
        label: '開始到結束日期',
        rules: [
          {
            required: true,
            message: `請輸入開始到結束日期`,
          }
        ],
        componet: (<TimePicker.RangePicker format={'HH:mm'} />)

      },


      {
        id: 'submit',
      },
    ]
  }

  _onChange = (datas: PageProps['value']) => {
    this.props.onChange && this.props.onChange(datas)
  }

  _onFinish = async (values: any): Promise<void> => {

    console.log('_onFinish', values)

    const [startTime, endTime] = values.startToEndTime

    switch (this.state.modalNewQuotaInterval?.type) {
      case 'add': {
        this._onChange([
          ...this.props.value || [],
          {
            weekDay: values.weekDay,
            startTime: moment(startTime).format(format),
            endTime: moment(endTime).format(format),
          },
        ])
      }
        break
      case 'edit': {
        this._onChange(

          this.props.value?.map((item, index) => {
            if (index === this.state.modalNewQuotaInterval?.index) {
              return {
                weekDay: values.weekDay,
                startTime: moment(startTime).format(format),
                endTime: moment(endTime).format(format),
              }
            }
            return item
          })
        )
      }
        break
    }


    this.setState(state => ({
      modalNewQuotaInterval: { ...state.modalNewQuotaInterval, show: false, }
    }))

  }

  _onLoadData = async (): Promise<any> => {
    const endTime = this.state.modalNewQuotaInterval?.data?.endTime ? moment(this.state.modalNewQuotaInterval?.data?.endTime, format) : undefined
    const startTime = this.state.modalNewQuotaInterval?.data?.startTime ? moment(this.state.modalNewQuotaInterval?.data?.startTime, format) : undefined

    const data = {
      weekDay: this.state.modalNewQuotaInterval?.data?.weekDay,
      startToEndTime: [startTime, endTime]
    }
    console.log('_onLoadData', data)
    return data
  };


  render() {
    return (
      <>
        <Card
          bodyStyle={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}
        >
          {this.props.value?.map((data, index) => {
            return (
              <Card
                key={index}
                style={{
                  height: 150,
                  width: 150,
                  margin: 5,
                }}
                bodyStyle={{
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                  flexDirection: 'column',
                }}
                hoverable

              >
                <span>{moment().day(data.weekDay).format('dddd')}</span>
                <span>{`${data.startTime} - ${data.endTime}`}</span>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: 10,
                  }}
                >
                  <Button
                    size='small'
                    type="primary"
                    icon={<FormOutlined />}
                    style={{
                      marginRight: 10,
                    }}
                    onClick={() => {
                      this.setState(state => ({
                        modalNewQuotaInterval: { ...state.modalNewQuotaInterval, show: true, key: Date.now(), data, type: 'edit', index, }
                      }))
                    }}
                  />

                  <Button
                    size='small'
                    type='ghost'
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      this._onChange(this.props.value?.filter((item, newIndex) => newIndex !== index))
                    }}
                  />
                </div>

              </Card>
            )
          })}
          <Card
            style={{
              height: 150,
              width: 150,
              margin: 5,
            }}
            bodyStyle={{
              height: '100%',
            }}
            hoverable
            onClick={() => {
              this.setState(state => ({
                modalNewQuotaInterval: { ...state.modalNewQuotaInterval, show: true, key: Date.now(), data: undefined, type: 'add' }
              }))
            }}
          >
            <div
              style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                flexDirection: 'column',
              }}
            >

              <PlusOutlined
                style={{
                  fontSize: 50,
                  color: '#65b919',
                }}
              />

            </div>
          </Card>
        </Card>

        <Modal
          key={this.state.modalNewQuotaInterval?.key}
          maskClosable={false}
          title={'新的配額時段'}
          visible={this.state.modalNewQuotaInterval?.show}
          footer={null}
          onCancel={() => {
            if (this.state.modalNewQuotaInterval) {
              this.setState({
                modalNewQuotaInterval: { ...this.state.modalNewQuotaInterval, show: false }
              })
            }

          }}
          width={'60%'}
        >
          <JJ_From
            formListData={this._formListData()}
            onLoadData={this._onLoadData}
            onFinish={this._onFinish}
          />
        </Modal>
      </>

    )
  }

}


