import { I18nAndDefault, GetUserRes, I18n } from "../api";

export const getI18nText = (data?: I18nAndDefault | I18n, langs?: GetUserRes['language']) => {

    if (!data) {
        return '-'
    }

    const dataDefault = (data as I18nAndDefault)
    const dataBase = (data as I18n)

    if (dataDefault.default) {
        return dataDefault[langs || 'default'] || dataDefault['default'] || '-'
    }

    return dataBase[langs || 'en'] || '-'

} 


export const getAreaCodes = [
    'CENTRAL_AND_WESTERN',
    'WAN_CHAI',
    'SOUTH_AREA',
    'EASTERN',
    'ISLANDS',
    'KOWLOON_CITY',
    'KWUN_TONG',
    'SHAM_SHUI_PO',
    'WONG_TAI_SIN',
    'YAU_TSIM_MONG',
    'KWAI_TSING',
    'TSUEN_WAN',
    'SHATIN',
    'SAI_KUNG',
    'TAI_PO',
    'NORTH',
    'TUEN_MUN',
    'YUEN_LONG',
]