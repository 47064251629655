import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, message, Spin, Button } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import api, { GetPartnerCompanyListParameters, GetPartnerCompanyRes } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import JJ_TableItemAction from '../../components/JJ_TableItemAction';
import JJ_TableItemMoment from '../../components/JJ_TableItemMoment';
import JJ_TableItemCopyableText from '../../components/JJ_TableItemCopyableText';
import ProductEditAndAdd from './PartnerCompanyEditAndAdd';
import { PlusOutlined } from '@ant-design/icons';
import { connect, ConnectedProps } from 'react-redux';
import JJ_TableItemCopyableId from '../../components/JJ_TableItemCopyableId';
import config from '../../config';

const { confirm } = Modal;

type EditAndAddModalType = { type: 'add' } | { type: 'edit', id: string }

interface PageState {
  isSpinLoading: boolean
  editAndAddModal: {
    show: boolean
    key: string
  } & EditAndAddModalType
}
interface Props {
}


interface Page {
  tableRef?: JJ_Table<GetPartnerCompanyRes> | null
}


type PageProps = Props & RouteChildrenProps & ConnectedProps<typeof connector>

interface RootState {

}

const mapState = () => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class PartnerCompanyList extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: true,
      editAndAddModal: {
        type: 'add',
        show: false,
        key: new Date().toString()
      }
    }
  }
  tableRef?: JJ_Table<GetPartnerCompanyRes> | null
  componentDidMount() {
    this._initData()
  }
  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true
      })
      await Promise.resolve()
      this.setState({
        isSpinLoading: false
      })
    } catch (error) {
      this.setState({
        isSpinLoading: false
      })
    }
  }
  render() {
    return (
      <div id="PartnerCompanyList"
      >
        <Button
          type='primary'
          style={{ marginBottom: 5 }}
          onClick={() => this.setState(state => ({
            editAndAddModal: { ...state.editAndAddModal, show: true, key: new Date().toString(), type: 'add' }
          }))}
          icon={<PlusOutlined />}
        >
          {`添加社區`}
        </Button>
        <Modal
          maskClosable={false}
          title={this.state.editAndAddModal.type === 'edit' ? '編輯社區' : '新建社區'}
          visible={this.state.editAndAddModal.show}
          footer={null}
          onCancel={() => this.setState(state => ({
            editAndAddModal: { ...state.editAndAddModal, show: false }
          }))}
          width={'85%'}
        >
          <ProductEditAndAdd
            {...this.state.editAndAddModal}
            onFinish={async (props) => {
              this.setState(state => ({
                editAndAddModal: { ...state.editAndAddModal, show: false }
              }))
              switch (props.type) {
                case 'add': {
                  this.tableRef && await this.tableRef.refreshData()
                  message.success(`添加成功`)
                }
                  break
                case 'edit': {
                  this.tableRef && await this.tableRef.refreshData()
                  message.success(`編輯成功`)
                }
              }

            }}
          />
        </Modal>

        <Spin spinning={this.state.isSpinLoading}>
          {!this.state.isSpinLoading && (
            <JJ_Table<GetPartnerCompanyRes, {
              desc: 'ascend' | 'descend'
            }>
              ref={ref => this.tableRef = ref}
              isSearchText={true}
              sourceItems={[
                {
                  type: 'select',
                  defaultValue: 'ascend',
                  key: 'desc',
                  options: [
                    {
                      value: 'ascend',
                      name: '升序',
                      disabled: false,
                    },
                    {
                      value: 'descend',
                      name: '降序',
                      disabled: false,
                    },
                  ]
                },
              ]}
              columns={[
                {
                  key: 'action',
                  width: 50,
                  render: (value: any, record) => {
                    return (
                      <JJ_TableItemAction
                        menus={[
                          {
                            type: 'item',
                            disabled: false,
                            key: 'edit',
                            name: '編輯',
                            onAction: () => {
                              this.setState(state => ({
                                editAndAddModal: { ...state.editAndAddModal, show: true, type: 'edit', id: record.id, key: new Date().toString() }
                              }))
                            }
                          },
                          {
                            type: 'item',
                            disabled: config.REACT_APP_DELETE_DISABLED,
                            key: 'delete',
                            name: '刪除',
                            onAction: () => {
                              confirm({
                                title: `是否删除${record.name}`,
                                icon: <ExclamationCircleOutlined />,
                                okText: 'Yes',
                                okType: 'danger',
                                cancelText: 'No',
                                onOk: async () => {
                                  try {
                                    await api.DeletePartnerCompany({ id: record.id })
                                    this.tableRef && this.tableRef.refreshData()
                                    message.success(`${record.name} 删除成功`)
                                  } catch (error) {
                                    message.error(`${record.name} 删除失败 [${error.message}]`);
                                  }
                                },
                                onCancel() {
                                },
                              });
                            }
                          },
                        ]}
                      />
                    )

                  },
                },
                {
                  title: 'ID',
                  dataIndex: 'id',
                  key: 'id',
                  width: 50,
                  render: (value) => (
                    <JJ_TableItemCopyableId
                      text={value}
                    />
                  )
                },
                {
                  title: '名稱',
                  dataIndex: 'name',
                  key: 'name',
                },
                {
                  title: '代號',
                  dataIndex: 'code',
                  key: 'code',
                },

                {
                  title: '创建时间',
                  dataIndex: 'createdAt',
                  key: 'createdAt',
                  render: (value) => (
                    <JJ_TableItemMoment
                      date={value}
                    />
                  )
                },
              ]}
              title={'社區清單'}
              onDataSource={async (body) => {
                const { sourceItemBody } = body
                const params: {
                  isAscend?: GetPartnerCompanyListParameters['isAscend']
                } = {}
                if (sourceItemBody) {
                  if (sourceItemBody.desc) {
                    params.isAscend = sourceItemBody.desc === 'ascend' ? true : false
                  }
                }
                const res = await api.GetPartnerCompanyList({
                  ...body,
                  ...params,
                })
                if (res.kind !== 'ok') throw new Error(res.kind)
                return res.data
              }}
            />
          )}
        </Spin>

      </div>
    )
  }

}
export default connector(PartnerCompanyList)

