import React, { ReactElement } from 'react';
import { Upload, message, Modal } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import tool from '../tool';
import { UploadFile, UploadChangeParam } from 'antd/lib/upload/interface';

interface PageProps {
  value?: string
  onChange?: (value?: string) => void;
}

interface PageState {
  loading: boolean
}

interface Page {

}

export default class JJ_FromIcon extends React.Component<PageProps, PageState> implements Page {


  componentDidMount() {
  }

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
    };
  }


  handleChange = (info: UploadChangeParam) => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    this.setState({ loading: false });

    if (info.file.status === 'error') {
      info.file.error && message.error(info.file.error.message)
      return;
    }

    if (info.file.status === 'done') {
      this.props.onChange && this.props.onChange(info.file.response.url)
    }
  };

  render() {
    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return (
      <Upload
        name="file"
        headers = {{
          Authorization:localStorage['token'] || ''
        }}
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        action={tool.http.defaults.baseURL + '/upload'}
        onChange={this.handleChange}
      >
        {this.props.value ? <img src={this.props.value} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
      </Upload>

    )
  }

}

