import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, message, Spin, Button, Tag, Space, Image } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import api, { GetProductLabelListParameters, DeleteProductLabelParameters, GetProductLabelRes, GetLabelRes } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import JJ_TableItemAction from '../../components/JJ_TableItemAction';
import JJ_TableItemMoment from '../../components/JJ_TableItemMoment';
import ProductLabelEditAndAdd, { ProductLabelEditAndAddPropsType } from './ProductLabelEditAndAdd';
import { PlusOutlined } from '@ant-design/icons';
import { connect, ConnectedProps } from 'react-redux';
import JJ_TableItemCopyableId from '../../components/JJ_TableItemCopyableId';
import config from '../../config';
import moment from 'moment';
import { ActionUserRes } from '../../actions/ActionUser';


const { confirm } = Modal;

type EditAndAddModalType = ProductLabelEditAndAddPropsType

interface PageState {
  isSpinLoading: boolean
  editAndAddModal: {
    show: boolean
    key: string
  } & EditAndAddModalType
}
interface Props {
  productId: string
}


interface Page {
  tableRef?: JJ_Table<GetProductLabelRes> | null

}


type PageProps = Props & ConnectedProps<typeof connector>

interface RootState {
  user: ActionUserRes

}

const mapState = (state: RootState) => ({
  user: state.user,
})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class ProductLabelList extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: true,
      editAndAddModal: {
        type: 'add',
        show: false,
        key: new Date().toString(),
        productId: '',
      }
    }
  }
  tableRef?: JJ_Table<GetProductLabelRes> | null
  componentDidMount() {
    this._initData()
  }
  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true
      })
      await Promise.resolve()
      this.setState({
        isSpinLoading: false
      })
    } catch (error) {
      this.setState({
        isSpinLoading: false
      })
    }
  }


  _deleteProductLabel = async (params: DeleteProductLabelParameters) => {
    const res = await api.DeleteProductLabel(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  render() {
    return (
      <div id="ProductLabelList"
      >
        <Button
          type='primary'
          style={{ marginBottom: 5 }}
          onClick={() => {
            this.setState(state => ({
              editAndAddModal: {
                ...state.editAndAddModal,
                show: true,
                key: new Date().toString(),
                type: 'add',
                productId: this.props.productId,
              }
            }))
          }}
          icon={<PlusOutlined />}
        >
          {`添加產品標籤`}
        </Button>

        <Modal
          maskClosable={false}
          title={'新建產品標籤'}
          visible={this.state.editAndAddModal.show}
          footer={null}
          onCancel={() => this.setState(state => ({
            editAndAddModal: { ...state.editAndAddModal, show: false }
          }))}
          width={'85%'}
        >
          <ProductLabelEditAndAdd
            {...this.state.editAndAddModal}
            onFinish={async (props) => {
              this.setState(state => ({
                editAndAddModal: { ...state.editAndAddModal, show: false }
              }))
              switch (props.type) {
                case 'add': {
                  message.success(`添加成功`)
                }
                  break

              }

              this.tableRef && await this.tableRef.refreshData()


            }}
          />
        </Modal>

        <Spin spinning={this.state.isSpinLoading}>
          {!this.state.isSpinLoading && (
            <JJ_Table<GetProductLabelRes, {
              createRange?: [moment.Moment, moment.Moment] | null
            }>
              ref={ref => this.tableRef = ref}
              isSearchText={true}
              sourceItems={[
                {
                  type: 'rangePicker',
                  key: 'createRange',
                  span: 8,
                  props: {
                    placeholder: ['創建開始時間', '創建結束時間'],
                  }
                },
              ]}
              columns={[
                {
                  key: 'action',
                  width: 50,
                  render: (value: any, record) => {



                    return (
                      <JJ_TableItemAction
                        menus={[
                          {
                            type: 'item',
                            disabled: config.REACT_APP_DELETE_DISABLED,
                            key: 'delete',
                            name: '删除',
                            onAction: () => {
                              confirm({
                                title: `是否刪除${(record.label as GetLabelRes).title[this.props.user.data.language]}`,
                                icon: <ExclamationCircleOutlined />,
                                okText: 'Yes',
                                okType: 'danger',
                                cancelText: 'No',
                                onOk: async () => {
                                  try {
                                    await this._deleteProductLabel({ id: record.id })
                                    this.tableRef && this.tableRef.refreshData()
                                    message.success(`${(record.label as GetLabelRes).title[this.props.user.data.language]} 删除成功`)
                                  } catch (error) {
                                    message.error(`${(record.label as GetLabelRes).title[this.props.user.data.language]} 删除失败 [${error.message}]`);
                                  }
                                },
                                onCancel() {
                                },
                              });
                            }
                          },
                        ]}
                      />
                    )

                  },
                },
                {
                  title: 'ID',
                  dataIndex: 'id',
                  key: 'id',
                  width: 50,
                  render: (value) => (
                    <JJ_TableItemCopyableId
                      text={value}
                    />
                  )
                },
                {
                  title: '縮略圖',
                  dataIndex: '縮略圖',
                  key: '縮略圖',
                  render: (value, record) => {
                    const label = (record.label as GetLabelRes)
                    return (
                      < Image
                        width={150}
                        src={label.image.url}
                        preview={true}
                      />
                    )
                  }
                },
                {
                  title: '標題',
                  dataIndex: 'title',
                  key: 'title',
                  render: (value, record) => {
                    const label = (record.label as GetLabelRes)
                    return label.title[this.props.user.data.language]
                  }
                },
                {
                  title: '描述',
                  dataIndex: 'description?',
                  key: 'description?',
                  width: 250,
                  render: (value, record) => {
                    const label = (record.label as GetLabelRes)
                    return label.description ? label.description[this.props.user.data.language] : '-'
                  }
                },
                {
                  title: '序列',
                  dataIndex: 'label.order',
                  key: 'label.order',
                },
                {
                  title: '創建時間',
                  dataIndex: 'createdAt',
                  key: 'createdAt',
                  render: (value) => (
                    <JJ_TableItemMoment
                      date={value}
                    />
                  )
                },
              ]}
              title={'產品標籤列表'}
              tableProps={{

              }}
              onDataSource={async (body) => {
                const { sourceItemBody } = body
                const params: {
                  beginAt?: GetProductLabelListParameters['beginAt']
                  endAt?: GetProductLabelListParameters['endAt']

                } = {}
                if (sourceItemBody) {

                  const createRange = sourceItemBody.createRange
                  if (createRange && createRange.length === 2) {
                    const [createRangeBeginAtMoment, createRangeEndAtMoment,] = createRange
                    params.beginAt = createRangeBeginAtMoment.toISOString()
                    params.endAt = createRangeEndAtMoment.toISOString()
                  }

                }

                const res = await api.GetProductLabelList({
                  ...body,
                  ...params,
                  productId: this.props.productId,
                  scope: ['label']
                })
                if (res.kind !== 'ok') throw new Error(res.kind)
                return res.data
              }}
            />
          )}
        </Spin>

      </div>
    )
  }

}
export default connector(ProductLabelList)

