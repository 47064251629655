import React, { } from 'react';
import JJ_From, { FormItemData } from "../../components/JJ_From";
import api, { GetPartnerCompanyListParameters, GetCommunityListParameters, PostFoodCardGroupDataParameters, GetFoodCardGroupXlsxPreviewRes, GetFoodCardUserMemberFamilyRes, GetFoodCardUserMemberFamilyListParameters, PostFoodCardGroupDataData } from '../../api';
import { connect, ConnectedProps } from 'react-redux';
import { Select, Spin, Input, Tag, Space } from 'antd';
import JJ_FromSwitch from '../../components/JJ_FromSwitch';
import JJ_FromUploadXlsx from '../../components/JJ_FromUploadXlsx';
import { RcFile } from 'antd/lib/upload/interface';
import config from '../../config';
import FoodCardGroupXlsxPreViewModal from './FoodCardGroupXlsxPreViewModal';

interface PageState {
  foodCardGroupXlsxPreviews?: GetFoodCardGroupXlsxPreviewRes[]
  foodCardGroupXlsxPreViewModal: {
    key: string
    visible: boolean
  }
  spinLoading: boolean
  foodCardUserMemberFamilys: GetFoodCardUserMemberFamilyRes[]
}

type Type = { type: 'edit', id: string } | { type: 'addXlsx' } | { type: 'add' }

interface Props {
  onFinish?(props: PageProps, data: any): void
}



interface Page {
  /**
   * 保存数据
   */
  _onFinish(values: any): void

  /**
   * 获取数据
   */
  _onLoadData(): Promise<{}>

  id?: string

}


type PageProps = Props & Type & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {

}

const mapState = () => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class FoodCardGroupGroupEditAndAdd extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);

    this.state = {
      foodCardUserMemberFamilys: [],
      foodCardGroupXlsxPreViewModal: {
        key: new Date().toISOString(),
        visible: false,
      },
      spinLoading: false,
    }

  }
  componentDidMount() {
  }

  _onFinish = async (values: any): Promise<void> => {

    switch (this.props.type) {
      case 'edit': {
        const res = await api.PutFoodCardGroup({
          foodCardUserMemberFamilyId: values.foodCardUserMemberFamilyId,
          externalId: values.externalId,
          type: 'OCTOPUS',
          isEnable: values.isEnable,
          id: this.props.id,
        })
        if (res.kind !== 'ok') throw Error(res.kind)
        this.props.onFinish && this.props.onFinish(this.props, res.data.id)
      }
        break
      case 'addXlsx': {
        const file: RcFile = values.file[0]
        const res = await api.PostFoodCardGroupXlsxPreview({
          file: file,
          type: 'OCTOPUS',
          isEnable: values.isEnable,
        })
        if (res.kind !== 'ok') throw Error(res.kind)
        this.setState({
          foodCardGroupXlsxPreViewModal: { ...this.state.foodCardGroupXlsxPreViewModal, visible: true, key: new Date().toISOString() },
          foodCardGroupXlsxPreviews: res.data.data,
        })
      }
        break
      case 'add': {

        const res = await api.PostFoodCardGroup({
          foodCardUserMemberFamilyId: values.foodCardUserMemberFamilyId,
          externalId: values.externalId,
          type: 'OCTOPUS',
          isEnable: values.isEnable,
        })
        if (res.kind !== 'ok') throw Error(res.kind)
        this.props.onFinish && this.props.onFinish(this.props, res.data)

      }
        break

    }
  }


  _getPartnerCompanyList = async (params: GetPartnerCompanyListParameters) => {
    const res = await api.GetPartnerCompanyList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getFoodCardUserMemberFamilyList = async (params: GetFoodCardUserMemberFamilyListParameters) => {
    const res = await api.GetFoodCardUserMemberFamilyList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }
  _getCommunityList = async (params: GetCommunityListParameters) => {
    const res = await api.GetCommunityList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _onLoadData = async (): Promise<any> => {

    switch (this.props.type) {
      case 'edit': {
        const res = await api.GetFoodCardGroup({ id: this.props.id })

        const foodCardUserMemberFamilyList = await this._getFoodCardUserMemberFamilyList({ count: 100000 })
        this.setState({
          foodCardUserMemberFamilys: foodCardUserMemberFamilyList.data,
        })
        if (res.kind === 'ok') {
          return {
            ...res.data,
            foodCardUserMemberFamilyId: res.data.foodCardUserMemberFamily,
          }
        }
        throw Error(res.kind)
      }
      case 'addXlsx': {

        return { isEnable: true }

      }
      case 'add': {
        const foodCardUserMemberFamilyList = await this._getFoodCardUserMemberFamilyList({ count: 100000 })
        this.setState({
          foodCardUserMemberFamilys: foodCardUserMemberFamilyList.data,
        })
        return { isEnable: true }
      }

    }
  };


  _formListData = (): FormItemData[] => {
    switch (this.props.type) {
      case 'edit': {
        return [
          {
            id: 'foodCardUserMemberFamilyId',
            label: '用戶家庭',
            rules: [
              {
                required: true,
                message: `請選擇用戶家庭`,
              }
            ],
            componet: (
              <Select
                style={{
                  width: 300
                }}
                allowClear={true}
                placeholder={'請選擇用戶家庭'}
                showSearch={true}
                filterOption={(input: any, option) => {
                  if (option && option.data) {
                    const data: GetFoodCardUserMemberFamilyRes = option.data
                    return (
                      String(data.jcConsole || '').toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                      String(data.foodCardCode || '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                    )
                  }
                  return false
                }}
              >
                {this.state.foodCardUserMemberFamilys.map(family => {
                  return (
                    <Select.Option
                      key={family.id}
                      value={family.id}
                      data={family}
                    >
                      <Space>
                        <Tag color='blue'> {family.jcConsole}</Tag>
                        <Tag color='success'> {family.foodCardCode}</Tag>
                      </Space>
                    </Select.Option>
                  )
                })}
              </Select>
            )
          },
          {
            id: 'isEnable',
            label: '是否啟用',
            rules: [
              {
                required: false,
              }
            ],
            componet: (<JJ_FromSwitch />)
          },
          {
            id: 'externalId',
            label: '八達通號',
            extra: '請輸入需要綁定的八達通號',
            rules: [
              {
                required: false,
                message: `請輸入八達通號`,
              }
            ],
            componet: (<Input disabled={true} />)
          },
          {
            id: 'submit',
          },
        ]
      }

      case 'add': {
        return [

          {
            id: 'foodCardUserMemberFamilyId',
            label: '用戶家庭',
            rules: [
              {
                required: true,
                message: `請選擇用戶家庭`,
              }
            ],
            componet: (
              <Select
                style={{
                  width: 300
                }}
                allowClear={true}
                placeholder={'請選擇用戶家庭'}
                showSearch={true}
                filterOption={(input: any, option) => {
                  if (option && option.data) {
                    const data: GetFoodCardUserMemberFamilyRes = option.data
                    return (
                      String(data.jcConsole || '').toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                      String(data.foodCardCode || '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                    )
                  }
                  return false
                }}
              >
                {this.state.foodCardUserMemberFamilys.map(family => {
                  return (
                    <Select.Option
                      key={family.id}
                      value={family.id}
                      data={family}
                    >
                      <Space>
                        <Tag color='blue'> {family.jcConsole}</Tag>
                        <Tag color='success'> {family.foodCardCode}</Tag>
                      </Space>
                    </Select.Option>
                  )
                })}
              </Select>
            )
          },
          {
            id: 'externalId',
            label: '八達通號',
            extra: '請輸入需要綁定的八達通號 (例: 38353589(8)或者38353589(*)，*號則代表跳過驗證八達通)',
            rules: [
              {
                required: true,
                message: `請輸入八達通號`,
              }
            ],
          },
          {
            id: 'isEnable',
            label: '是否啟用',
            rules: [
              {
                required: true,
              }
            ],
            componet: (<JJ_FromSwitch />)
          },


          {
            id: 'submit',
          },
        ]
      }

      case 'addXlsx': {
        return [

          {
            id: 'isEnable',
            label: '是否啟用',
            rules: [
              {
                required: true,
              }
            ],
            componet: (<JJ_FromSwitch />)
          },
          {
            id: 'file',
            label: 'Excel檔案',
            extra: (
              <div>
                {'下载 '}
                <a target='_blank' href={`${config.REACT_APP_STATIC}/images/card-group-template.xlsx`}>Excel模板</a>
              </div>
            ),
            rules: [
              {
                required: true,
                message: `請上傳Excel檔案`,
              }
            ],
            componet: (<JJ_FromUploadXlsx />)
          },

          {
            id: 'submit',
            label: '預覽',
          },
        ]
      }

    }
  }

  _postFoodCardGroupData = async (params: PostFoodCardGroupDataParameters) => {
    const res = await api.PostFoodCardGroupData(params)
    if (res.kind !== 'ok') throw new Error
    return res.data
  }

  render() {
    return (
      <div id="FoodCardGroupGroupEditAndAdd">
        <Spin spinning={this.state.spinLoading}>
          <JJ_From
            formListData={this._formListData()}
            onLoadData={this._onLoadData}
            onFinish={this._onFinish}
          />
          {this.state.foodCardGroupXlsxPreviews && (
            <FoodCardGroupXlsxPreViewModal
              key={this.state.foodCardGroupXlsxPreViewModal.key}
              title={'預覽生成數據'}
              foodCardGroupXlsxPreviews={this.state.foodCardGroupXlsxPreviews}
              visible={this.state.foodCardGroupXlsxPreViewModal.visible}
              onCancel={() => this.setState({ foodCardGroupXlsxPreViewModal: { ...this.state.foodCardGroupXlsxPreViewModal, visible: false } })}
              onFinish={async (res) => {
                try {

                  this.setState({
                    foodCardGroupXlsxPreViewModal: { ...this.state.foodCardGroupXlsxPreViewModal, visible: false },
                    spinLoading: true
                  })

                  let postFoodCardGroupDataData: PostFoodCardGroupDataData[] = []

                  postFoodCardGroupDataData = res.reduce((pValue, cValue) => {
                    if (cValue.foodCardUserMemberFamily) {
                      pValue.push({
                        foodCardUserMemberFamilyId: cValue.foodCardUserMemberFamily.id,
                        externalId: cValue.externalId,
                        type: cValue.type,
                        isEnable: cValue.isEnable,
                      })
                    }
                    return pValue
                  }, postFoodCardGroupDataData)

                  const postFoodCardGroups = await this._postFoodCardGroupData({ data: postFoodCardGroupDataData })
                  this.setState({
                    spinLoading: false
                  })
                  this.props.onFinish && this.props.onFinish(this.props, postFoodCardGroups.ids)
                } catch (error) {
                  console.log(error)
                  this.setState({
                    spinLoading: false
                  })
                }

              }}
            />
          )}
        </Spin>


      </div>
    )
  }

}
export default connector(FoodCardGroupGroupEditAndAdd)


