import React, { } from 'react';
import { Tag, Modal, Button } from 'antd';

import JJ_Table from '../../components/JJ_Table';
import JJ_TableItemMoment from '../../components/JJ_TableItemMoment';
import { GetFoodCardXlsxPreviewRes } from '../../api';


interface PageState {

}

interface Props {
  title: string
  foodCardXlsxPreviews: GetFoodCardXlsxPreviewRes[]
  visible: boolean
  onCancel(): void
  onFinish(res: GetFoodCardXlsxPreviewRes[]): void
}



interface Page {

  tableRef?: JJ_Table<GetFoodCardXlsxPreviewRes & { id: string }> | null

}


type PageProps = Props



class FoodCardXlsxPreViewModal extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);

    this.state = {

    }

  }
  componentDidMount() {
  }

  tableRef?: JJ_Table<GetFoodCardXlsxPreviewRes & { id: string }> | null

  _tableView = () => {
    return (
      <JJ_Table<GetFoodCardXlsxPreviewRes & { id: string }>

        tableProps={{
          pagination: false,
          scroll: {
            y: window.innerHeight * 0.6,
          }
        }}
        ref={ref => this.tableRef = ref}
        isSearchText={true}
        columns={[
          {
            title: 'key',
            width: 80,
            dataIndex: 'cellKey',
            key: 'cellKey',
          },
          {
            width: 200,

            title: '代號',
            dataIndex: 'code',
            key: 'code',
          },
          {
            width: 120,
            title: '社区名称',
            dataIndex: ['community', 'name'],
            key: 'community.name',
          },
          {
            width: 120,
            title: '合作机构名称',
            dataIndex: ['partnerCompany', 'name'],
            key: 'partnerCompany.name',
          },

          {
            title: '是否启动',
            width: 100,
            dataIndex: 'isEnable',
            key: 'isEnable',
            render: (value, record) => (
              record.isEnable
                ? <Tag color="blue">启动</Tag>
                : <Tag color="red">停用</Tag>
            )
          },
          {
            title: '启动时间',
            dataIndex: 'beginAt',
            key: 'beginAt',
            render: (value) =>
              value
                ? (<JJ_TableItemMoment
                  date={value}
                />)
                : '--:--'
          },
          {
            title: '错误描述',
            dataIndex: 'codeError',
            key: 'codeError',
            render: (value) => value ? <Tag color='error'>{value}</Tag> : `--`
          },
        ]}
        title={'食品卡列表'}
        onDataSource={async (body) => {
          const data = await Promise.resolve(this.props.foodCardXlsxPreviews)
          return {
            data: data
              .map((item: any, index: number) => ({ ...item, id: String(index) }))
              .sort((a: any, b: any) => a.codeError ? -1 : 1)
              .filter(item => {
                const searchText = body.searchText || ''
                if (searchText) {
                  const codeError = item.codeError || ''
                  return String(codeError).indexOf(searchText) !== -1 || String(item.cellKey).indexOf(searchText) !== -1 || String(item.code).indexOf(searchText) !== -1
                } else {
                  return true
                }
              })
            ,
            totalCount: 0,
          }
        }}
      />
    )
  }


  render() {

    return (
      <div id="FoodCardXlsxPreViewModal">
        <Modal
          maskClosable={false}
          title={this.props.title}
          visible={this.props.visible}
          footer={[
            <Button key="submit"
              type="primary"
              disabled={this.props.foodCardXlsxPreviews.some(item => item.codeError) || this.props.foodCardXlsxPreviews.length <= 0}
              onClick={() => this.props.onFinish(this.props.foodCardXlsxPreviews)}>
              批量生成
            </Button>,
          ]}
          onCancel={this.props.onCancel}
          width={'85%'}
        >
          {this._tableView()}
        </Modal>
      </div>
    )
  }

}
export default FoodCardXlsxPreViewModal


