import React, { } from 'react';
import api, { GetFoodCardUserMemberFamilyRes, GetFoodCardUserMemberRes, GetFoodCardUserMemberFamilyParameters, GetFoodCardUserMemberListParameters } from '../../api';
import { connect, ConnectedProps } from 'react-redux';
import { Spin, Modal, Button, Layout, Carousel, Space, message, } from 'antd';
import FoodCardUserMemberList from '../foodCardUserMember/FoodCardUserMemberList';
import { PlusOutlined } from '@ant-design/icons';
import FoodCardUserMemberEditAndAdd, { FoodCardUserMemberEditAndAddPropsType } from '../foodCardUserMember/FoodCardUserMemberEditAndAdd';
import { ActionUserRes } from '../../actions/ActionUser';

type EditAndAddModalType = FoodCardUserMemberEditAndAddPropsType

interface PageState {
  spinning?: boolean
  foodCardUserMemberFamily?: GetFoodCardUserMemberFamilyRes
  foodCardUserMembers: GetFoodCardUserMemberRes[]
  editAndAddModal: {
    show: boolean
    key: string
  } & EditAndAddModalType

  foodCardUserMemberListProps: {
    key?: any
  }
}

interface Props {
  title: string
  visible: boolean
  onCancel(): void
  id: string
}

interface Page {

  /**
   * 获取数据
   */
  _onLoadData(): Promise<{}>

}


type PageProps = Props & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {
  user: ActionUserRes
}

const mapState = (state: RootState) => ({
  user: state.user
})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class FoodCardUserMemberFamilyDetailsModal extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);

    this.state = {
      foodCardUserMembers: [],
      editAndAddModal: {
        type: 'add',
        show: false,
        key: new Date().toString(),
        foodCardUserMemberFamilyId: '',
      },
      foodCardUserMemberListProps: {},
    }

  }
  componentDidMount() {
    this._onLoadData()
  }


  _getFoodCardUserMemberFamily = async (params: GetFoodCardUserMemberFamilyParameters) => {
    const res = await api.GetFoodCardUserMemberFamily(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _getFoodCardUserMemberList = async (params: GetFoodCardUserMemberListParameters) => {
    const res = await api.GetFoodCardUserMemberList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }



  _onLoadData = async (): Promise<any> => {

    try {
      this.setState({
        spinning: true
      })

      const foodCardUserMemberFamily = await this._getFoodCardUserMemberFamily({ id: this.props.id })
      const memberList = await this._getFoodCardUserMemberList({ foodCardUserMemberFamilyId: this.props.id, count: 1000 })
      this.setState({
        foodCardUserMemberFamily,
        foodCardUserMembers: memberList.data,
        spinning: false,
      })

    } catch (error) {
      this.setState({
        spinning: false
      })
      console.log(error)
    }
  };

  refCarousel?: Carousel | null
  isRefCarousel?: undefined | boolean


  _getAddAllFoodCardUserMembers = () => {

    if (this.state.foodCardUserMemberFamily?.foodCardCode) {
      return this.state.foodCardUserMembers.filter(member => !!!member.foodCardCode)
    }

    return []
  }

  _contentView = (foodCardUserMemberFamily?: PageState['foodCardUserMemberFamily']) => {

    if (foodCardUserMemberFamily) {
      return (
        <div>

          <Space
            align='center'
            style={{
              marginBottom: 20,
            }}
          >
            <h2>{foodCardUserMemberFamily.foodCardCode || foodCardUserMemberFamily.phone}</h2>

          </Space>
          <FoodCardUserMemberList
            foodCardUserMemberFamilyId={this.props.id}
            key={this.state.foodCardUserMemberListProps.key}
            onAddFoodCardSuccessAction={this._onLoadData}
            onAddSuccessAction={this._onLoadData}
            onEditSuccessAction={this._onLoadData}
            onDeleteFoodCardSuccessAction={this._onLoadData}
          />
        </div>
      )

    }

    return <div
      style={{
        height: 400
      }}
    />
  }

  _view = () => {

    return (
      <Layout
        style={{
          backgroundColor: 'white',
        }}
      >
        {this._contentView(this.state.foodCardUserMemberFamily)}
      </Layout>
    )
  }


  render() {
    return (

      <Modal
        maskClosable={false}
        title={this.props.title}
        visible={this.props.visible}
        onCancel={this.props.onCancel}
        width={'90%'}
        footer={null}
      >
        <div id="FoodCardUserMemberFamilyDetailsModal"
        >
          <Spin
            spinning={this.state.spinning}
          >
            {this._view()}
          </Spin>

          <Modal
            maskClosable={false}
            title={this.state.editAndAddModal.type === 'edit'
              ? '編輯用戶家庭成員'
              : '新建用戶家庭成員'
            }
            visible={this.state.editAndAddModal.show}
            footer={null}
            onCancel={() => this.setState(state => ({
              editAndAddModal: { ...state.editAndAddModal, show: false }
            }))}
            width={'85%'}
          >
            <FoodCardUserMemberEditAndAdd
              {...this.state.editAndAddModal}
              user={this.props.user.data}
              onFinish={async (props) => {
                this.setState(state => ({
                  editAndAddModal: { ...state.editAndAddModal, show: false }
                }))
                switch (props.type) {
                  case 'add': {
                    message.success(`添加成功`)
                  }
                    break
                  case 'edit': {
                    message.success(`編輯成功`)
                  }
                    break

                }
                this.setState(state => ({
                  foodCardUserMemberListProps: { ...state.foodCardUserMemberListProps, key: Date.now() }
                }))

              }}
            />
          </Modal>

        </div>
      </Modal>
    )
  }

}
export default connector(FoodCardUserMemberFamilyDetailsModal)


