import React, { } from 'react';
import JJ_From, { FormItemData } from "../../components/JJ_From";
import api, { GetQuotaIntervalParameters, PutQuotaIntervalParameters, PostQuotaIntervalParameters, GetQuotaIntervalListParameters } from '../../api';
import { connect, ConnectedProps } from 'react-redux';
import JJ_FromI18nInputText from '../../components/JJ_FromI18nInputText';
import { Input } from 'antd';
import JJ_FromI18nInputTextByDefault from '../../components/JJ_FromI18nInputTextByDefault';
import TextArea from 'antd/lib/input/TextArea';
import JJ_FromQuotaIntervalDatas from '../../components/JJ_FromQuotaIntervalDatas';

interface PageState {

}


export type QuotaIntervalEditAndAddPropsType = { type: 'add' } | { type: 'edit', id: string }

interface Props {
  onFinish?(props: PageProps, id: string): void
}



interface Page {
  /**
   * 保存数据
   */
  _onFinish(values: any): void

  /**
   * 获取数据
   */
  _onLoadData(): Promise<{}>

  id?: string

}


type PageProps = Props & QuotaIntervalEditAndAddPropsType & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {

}

const mapState = (state: RootState) => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class QuotaIntervalEditAndAdd extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);

    this.state = {

    }

  }
  componentDidMount() {
  }

  _onFinish = async (values: any): Promise<void> => {

    console.log('_onFinish', this.props.type, values)

    switch (this.props.type) {
      case 'edit': {
        const res = await this._putQuotaInterval({
          id: this.props.id,
          datas: values.datas,
          name: values.name,
        })
        this.props.onFinish && this.props.onFinish(this.props, res.id)
      }
        break
      case 'add': {
        const res = await this._postQuotaInterval({
          datas: values.datas,
          name: values.name,
        })
        this.props.onFinish && this.props.onFinish(this.props, res.id)
      }
        break
    }
  }

  _onLoadData = async (): Promise<any> => {
    switch (this.props.type) {
      case 'edit': {
        const res = await this._getQuotaInterval({ id: this.props.id })
        return {
          ...res,
        }
      }
      case 'add': {

        return {

        }
      }


    }
  };

  _getQuotaInterval = async (param: GetQuotaIntervalParameters) => {
    const res = await api.GetQuotaInterval(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _getQuotaIntervalList = async (param: GetQuotaIntervalListParameters) => {
    const res = await api.GetQuotaIntervalList(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _putQuotaInterval = async (param: PutQuotaIntervalParameters) => {
    const res = await api.PutQuotaInterval(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }
  _postQuotaInterval = async (param: PostQuotaIntervalParameters) => {
    const res = await api.PostQuotaInterval(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }


  _formListData = (): FormItemData[] => {
    switch (this.props.type) {
      case 'edit': {
        return [
          {
            id: 'datas',
            label: '時段',
            rules: [
              {
                required: true,
                message: `請輸入`,
              }
            ],
            componet: (<JJ_FromQuotaIntervalDatas />)
          },

          {
            id: 'name',
            label: '名稱',
            rules: [
              {
                required: true,
                message: `請輸入名稱`,
              }
            ],
            componet: (<JJ_FromI18nInputTextByDefault />)
          },
          {
            id: 'submit',
          },
        ]
      }
      case 'add': {
        return [


          {
            id: 'datas',
            label: '時段',
            rules: [
              {
                required: true,
                message: `請輸入`,
              }
            ],
            componet: (<JJ_FromQuotaIntervalDatas />)
          },

          {
            id: 'name',
            label: '名稱',
            rules: [
              {
                required: true,
                message: `請輸入名稱`,
              }
            ],
            componet: (<JJ_FromI18nInputTextByDefault />)
          },

          {
            id: 'submit',
          },
        ]
      }


    }
  }

  render() {
    return (
      <div id="QuotaIntervalEditAndAdd">
        <JJ_From
          formListData={this._formListData()}
          onLoadData={this._onLoadData}
          onFinish={this._onFinish}
        />
      </div>
    )
  }

}
export default connector(QuotaIntervalEditAndAdd)
