
import { combineReducers } from 'redux'
import ActionType from '../actions/ActionType';
import { ActionUserRes } from '../actions/ActionUser';
import { ActionConfigRes } from '../actions/ActionConfig';
const getLang = (lang: any) => {
    console.log('lang', lang)
    switch (lang) {
        case 'zh-cn':
        case 'zh-hans':
        case 'zh-hans-cn':
        case 'zh-hant':
        case 'zh-hant-cn':
        case 'zh-hk-cn':
        case 'cn':
            return 'zh-cn'
        case 'zh-tw':
        case 'zh-hant-tw':
        case 'zh-hant-mo':
        case 'zh-hant-hk':
        case 'zh-hk':
        case 'zh':
        case 'tw':
            return 'zh-tw'
        case 'en-us':
        default:
            return 'en'
    }
}
const userState = (): ActionUserRes => {
    const { language = 'zh-tw' } = window.navigator
    return {
        data: <any>{

        }
    }
}

const user = (state = userState(), action: { type: any; }) => {
    switch (action.type) {

        case ActionType.userType.GET_DATA:
            return { ...state, ...action };
        case ActionType.userType.UP_DATE:
            return { ...state, ...action };
        case ActionType.userType.GET_PERMISSION_DATA:
            return { ...state, ...action };
        case ActionType.userType.INIT_DATA:
            return userState();

        default:
            return state;
    }
}

const configState = (): ActionConfigRes => {
    return {
        momentType: 'fromNow'
    }
}

const config = (state = configState(), action: { type: any; }) => {
    switch (action.type) {

        case ActionType.configType.UP_DATE:
            return { ...state, ...action };
        case ActionType.configType.INIT_DATA:
            return configState();
        default:
            return state;
    }
}



export default combineReducers({
    user,
    config,
});