import React, { } from 'react';
import JJ_From, { FormItemData } from "../../components/JJ_From";
import api, { GetHealthTipParameters, PutHealthTipParameters, PostHealthTipParameters, GetHealthTipListParameters } from '../../api';
import { connect, ConnectedProps } from 'react-redux';
import JJ_FromI18nInputText from '../../components/JJ_FromI18nInputText';
import { Input } from 'antd';

interface PageState {

}


export type HealthTipEditAndAddPropsType = { type: 'add' } | { type: 'edit', id: string }

interface Props {
  onFinish?(props: PageProps, id: string): void
}



interface Page {
  /**
   * 保存数据
   */
  _onFinish(values: any): void

  /**
   * 获取数据
   */
  _onLoadData(): Promise<{}>

  id?: string

}


type PageProps = Props & HealthTipEditAndAddPropsType & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {

}

const mapState = (state: RootState) => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class HealthTipEditAndAdd extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);

    this.state = {

    }

  }
  componentDidMount() {
  }

  _onFinish = async (values: any): Promise<void> => {

    console.log('_onFinish', this.props.type, values)

    switch (this.props.type) {
      case 'edit': {
        const res = await this._putHealthTip({
          ...values,
          id: this.props.id,
        })
        this.props.onFinish && this.props.onFinish(this.props, res.id)
      }
        break
      case 'add': {
        const res = await this._postHealthTip({ ...values, })
        this.props.onFinish && this.props.onFinish(this.props, res.id)
      }
        break
    }
  }

  _onLoadData = async (): Promise<any> => {
    switch (this.props.type) {
      case 'edit': {
        const res = await this._getHealthTip({ id: this.props.id })
        return {
          ...res,
        }
      }
      case 'add': {

        return {

        }
      }


    }
  };

  _getHealthTip = async (param: GetHealthTipParameters) => {
    const res = await api.GetHealthTip(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _getHealthTipList = async (param: GetHealthTipListParameters) => {
    const res = await api.GetHealthTipList(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _putHealthTip = async (param: PutHealthTipParameters) => {
    const res = await api.PutHealthTip(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }
  _postHealthTip = async (param: PostHealthTipParameters) => {
    const res = await api.PostHealthTip(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }


  _formListData = (): FormItemData[] => {
    switch (this.props.type) {
      case 'edit': {
        return [
          {
            id: 'i18nText',
            name: 'title',
            label: '標題',
            rules: [
              {
                required: true,
                message: `請輸入標題`,
              }
            ],
            componet: (<JJ_FromI18nInputText />)
          },
          {
            id: 'submit',
          },
        ]
      }
      case 'add': {

        return [
          {
            id: 'i18nText',
            name: 'title',
            label: '標題',
            rules: [
              {
                required: true,
                message: `請輸入標題`,
              }
            ],
            componet: (<JJ_FromI18nInputText />)
          },

          {
            id: 'submit',
          },
        ]
      }


    }
  }

  render() {
    return (
      <div id="HealthTipEditAndAdd">
        <JJ_From
          formListData={this._formListData()}
          onLoadData={this._onLoadData}
          onFinish={this._onFinish}
        />
      </div>
    )
  }

}
export default connector(HealthTipEditAndAdd)
