import React, { } from 'react';
import JJ_From, { FormItemData } from "../../components/JJ_From";
import api, { GetFoodCardUserMemberFamilyRes, GetFoodCardUserMemberFamilyListParameters, GetKioskVendingRes, GetKioskVendingListParameters, PostMessagePushParameters } from '../../api';
import { connect, ConnectedProps } from 'react-redux';
import { Input, TreeSelect, Space, Tag, DatePicker, message, Modal } from 'antd';
import { DataNode } from 'antd/lib/tree';
import moment from 'moment';


interface PageState {
  families: GetFoodCardUserMemberFamilyRes[]
  selelctFamilies?: GetFoodCardUserMemberFamilyRes[]
}


export type MessageRecordEditAndAddPropsType = {
  type: 'add'
  messageId: string
}


interface Props {
  onFinish?(props: PageProps, id: string): void
}



interface Page {
  /**
   * 保存数据
   */
  _onFinish(values: any): void

  /**
   * 获取数据
   */
  _onLoadData(): Promise<{}>

  id?: string

}


type PageProps = Props & MessageRecordEditAndAddPropsType & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {

}

const mapState = (state: RootState) => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class MessageRecordEditAndAdd extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);

    this.state = {
      families: []
    }

  }
  componentDidMount() {
  }

  _getPostMessagePushFamilies = (codes: string[]) => {

    const families = this.state.families.filter(family => {

      const familyCode = family.foodCardCode

      if (!familyCode) {
        return false
      }

      if (codes[0] === 'ALL') {
        return true
      }

      if (codes.some(code => familyCode.indexOf(code) !== -1)) {
        return true
      }

    })
    return families
  }

  _onFinish = async (values: any): Promise<void> => {

    console.log('_onFinish', this.props.type, values)

    switch (this.props.type) {
      case 'add': {

        const codes: string[] = values.codes
        const sendAt: string = values.sendAt

        const sendAction = async () => {
          const res = await this._postMessagePush({
            messageId: this.props.messageId,
            sendAt: moment(sendAt).toISOString(),
            userIds: this._getPostMessagePushFamilies(codes).map(family => family.userId)
          })
          this.props.onFinish && this.props.onFinish(this.props, this.props.messageId)
        }


        if (sendAt) {
          await sendAction()
        } else {

          Modal.confirm({
            title: `不填發送時間則立刻發送`,
            okText: '立刻發送',
            okType: 'danger',
            cancelText: '取消發送',
            onOk: async () => {
              await sendAction()
            },
            onCancel() {

            },
          });

        }

      }
        break
    }
  }

  _onLoadData = async (): Promise<any> => {
    switch (this.props.type) {

      case 'add': {

        const familyListRes = await this._getFoodCardUserMemberFamilyList({ count: 1000000 })

        this.setState({
          families: familyListRes.data,
        })

        return {

        }

      }


    }
  };


  _postMessagePush = async (param: PostMessagePushParameters) => {
    const res = await api.PostMessagePush(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }


  _getFoodCardUserMemberFamilyList = async (param: GetFoodCardUserMemberFamilyListParameters) => {
    const res = await api.GetFoodCardUserMemberFamilyList(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }




  _formListData = (): FormItemData[] => {
    switch (this.props.type) {

      case 'add': {


        let treeData: DataNode[] = []

        treeData = this.state.families
          .reduce((previousValue, currentValue, currentIndex, array) => {
            const kioskFoodCardCode = currentValue.foodCardCode?.slice(0, 10)

            if (kioskFoodCardCode) {

              if (previousValue.find(item => item.key === kioskFoodCardCode)) {
                //存在
                return previousValue

              } else {
                //不存在

                return [...previousValue,
                {
                  title: kioskFoodCardCode,
                  key: kioskFoodCardCode,
                  children: array.reduce((pv, cv) => {

                    const foodCardCode = cv.foodCardCode

                    if (!foodCardCode) {
                      return pv
                    }

                    if (foodCardCode.length === 14 && foodCardCode.indexOf(kioskFoodCardCode) !== -1) {
                      //存在
                      return [...pv, {
                        title: foodCardCode,
                        key: foodCardCode,
                      }]
                    }

                    return pv

                  }, treeData)
                }
                ]
              }


            }

            return previousValue

          }, treeData)
        return [
          {
            id: 'codes',
            label: '家庭',
            rules: [
              {
                required: true,
                message: `請選擇家庭`
              }
            ],
            componet: (
              <TreeSelect
                dropdownStyle={{ maxHeight: 400, overflow: 'scroll' }}
                treeData={[{
                  key: 'ALL',
                  title: '全選',
                  children: treeData,
                }]}
                treeCheckable={true}
                showCheckedStrategy={'SHOW_PARENT'}
                // placeholder = {} 
                allowClear
                showSearch={true}
                multiple={true}
              />

            )
          },

          {
            id: 'sendAt',
            label: '發送時間',
            extra: '（不填則立刻發送）',
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <DatePicker showTime picker='date' />
            )
          },
          {
            id: 'submit',
            label: `發送${(this.state.selelctFamilies && this.state.selelctFamilies.length > 0) ? ` (${this.state.selelctFamilies.length})` : ``}`,
          },
        ]
      }


    }
  }

  render() {
    return (
      <div id="MessageRecordEditAndAdd">
        <JJ_From
          formListData={this._formListData()}
          onLoadData={this._onLoadData}
          onFinish={this._onFinish}
          formProps={{
            onFieldsChange: e => {
              const codeDatas = e.find((e: any) => {
                return e.name[0] === 'codes'
              })

              if (codeDatas && codeDatas.value) {
                this.setState({
                  selelctFamilies: this._getPostMessagePushFamilies(codeDatas.value)
                })
              }

            }
          }}
        />
      </div>
    )
  }

}
export default connector(MessageRecordEditAndAdd)
