import React, { } from 'react';
import { Modal, message, Spin, Button, Tag, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import api, { GetSurveyListParameters, DeleteFoodCardUserMemberParameters, GetFoodCardUserMemberFamilyRes, GetFoodMenuRes, GetSurveyRes, DeleteSurveyParameters } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import JJ_TableItemAction from '../../components/JJ_TableItemAction';
import JJ_TableItemMoment from '../../components/JJ_TableItemMoment';
import SurveyEditAndAdd, { SurveyEditAndAddPropsType } from './SurveyEditAndAdd';
import { PlusOutlined } from '@ant-design/icons';
import { connect, ConnectedProps } from 'react-redux';
import JJ_TableItemCopyableId from '../../components/JJ_TableItemCopyableId';
import config from '../../config';
import { ActionUserRes } from '../../actions/ActionUser';
import moment from 'moment';
import SurveySubjectList from '../surveySubject/SurveySubjectList';
import SurveyResponseList from '../surveyResponse/SurveyResponseList';

const { confirm } = Modal;

type EditAndAddModalType = SurveyEditAndAddPropsType

interface PageState {
  isSpinLoading: boolean
  editAndAddModal: {
    show: boolean
    key: string
  } & EditAndAddModalType
  surveySubjectListModalProps?: {
    show: boolean
    key: string
    surveyId: string
  }
  surveyResponsetListModalProps?: {
    show: boolean
    key: string
    surveyId: string
  }
}
interface Props {

}


interface Page {


}


type PageProps = Props & ConnectedProps<typeof connector>

interface RootState {
  user: ActionUserRes

}

const mapState = (state: RootState) => ({
  user: state.user,
})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class SurveyList extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: true,
      editAndAddModal: {
        type: 'add',
        show: false,
        key: new Date().toString(),
      }
    }
  }
  tableRef?: JJ_Table<GetSurveyRes> | null
  componentDidMount() {
    this._initData()
  }
  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true
      })
      await Promise.resolve()
      this.setState({
        isSpinLoading: false
      })
    } catch (error) {
      this.setState({
        isSpinLoading: false
      })
    }
  }


  _deleteSurvey = async (params: DeleteSurveyParameters) => {
    const res = await api.DeleteSurvey(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  render() {
    return (
      <div id="SurveyList"
      >
        <Button
          type='primary'
          style={{ marginBottom: 5 }}
          onClick={() => {
            this.setState(state => ({
              editAndAddModal: {
                ...state.editAndAddModal,
                show: true,
                key: new Date().toString(),
                type: 'add',
              }
            }))
          }}
          icon={<PlusOutlined />}
        >
          {`添加問卷調查`}
        </Button>

        <Modal
          maskClosable={false}
          title={this.state.editAndAddModal.type === 'edit'
            ? '編輯問卷調查'
            : '新建問卷調查'
          }
          visible={this.state.editAndAddModal.show}
          footer={null}
          onCancel={() => this.setState(state => ({
            editAndAddModal: { ...state.editAndAddModal, show: false }
          }))}
          width={'85%'}
        >
          <SurveyEditAndAdd
            {...this.state.editAndAddModal}
            onFinish={async (props) => {
              this.setState(state => ({
                editAndAddModal: { ...state.editAndAddModal, show: false }
              }))
              switch (props.type) {
                case 'add': {
                  message.success(`添加成功`)
                }
                  break
                case 'edit': {
                  message.success(`編輯成功`)
                }
                  break

              }

              this.tableRef && await this.tableRef.refreshData()


            }}
          />
        </Modal>


        {this.state.surveySubjectListModalProps && (
          <Modal
            maskClosable={false}
            visible={this.state.surveySubjectListModalProps.show}
            footer={null}
            onCancel={() => {
              const surveySubjectListModalProps = this.state.surveySubjectListModalProps
              if (surveySubjectListModalProps) {
                this.setState(state => ({
                  surveySubjectListModalProps: { ...surveySubjectListModalProps, show: false }
                }))
              }

            }}
            width={'85%'}
          >
            <SurveySubjectList
              key={this.state.surveySubjectListModalProps.key}
              surveyId={this.state.surveySubjectListModalProps.surveyId}
            />
          </Modal>
        )}



        {this.state.surveyResponsetListModalProps && (
          <Modal
            maskClosable={false}
            visible={this.state.surveyResponsetListModalProps.show}
            footer={null}
            onCancel={() => {
              const surveyResponsetListModalProps = this.state.surveyResponsetListModalProps
              if (surveyResponsetListModalProps) {
                this.setState(state => ({
                  surveyResponsetListModalProps: { ...surveyResponsetListModalProps, show: false }
                }))
              }

            }}
            width={'85%'}
          >
            <SurveyResponseList
              key={this.state.surveyResponsetListModalProps.key}
              surveyId={this.state.surveyResponsetListModalProps.surveyId}
            />
          </Modal>
        )}

        <Spin spinning={this.state.isSpinLoading}>
          {!this.state.isSpinLoading && (
            <JJ_Table<GetSurveyRes, {
              desc: 'ascend' | 'descend'
            }>
              ref={ref => this.tableRef = ref}
              isSearchText={true}
              sourceItems={[
                {
                  type: 'select',
                  defaultValue: 'ascend',
                  key: 'desc',
                  options: [
                    {
                      value: 'ascend',
                      name: '升序',
                      disabled: false,
                    },
                    {
                      value: 'descend',
                      name: '降序',
                      disabled: false,
                    },
                  ]
                },
              ]}
              columns={[
                {
                  key: 'action',
                  width: 50,
                  render: (value: any, record) => {



                    return (
                      <JJ_TableItemAction
                        menus={[
                          {
                            type: 'item',
                            disabled: false,
                            key: 'edit',
                            name: '編輯',
                            onAction: () => {
                              this.setState(state => ({
                                editAndAddModal: { ...state.editAndAddModal, show: true, type: 'edit', id: record.id, key: new Date().toString() }
                              }))
                            }
                          },

                          {
                            type: 'item',
                            disabled: false,
                            key: 'showSurveySubjectList',
                            name: '顯示題目',
                            onAction: () => {
                              this.setState(state => ({
                                surveySubjectListModalProps: { ...state.surveySubjectListModalProps || {}, show: true, surveyId: record.id, key: record.id }
                              }))
                            }
                          },
                          {
                            type: 'item',
                            disabled: false,
                            key: 'showSurveyResponseList',
                            name: '顯示用戶問卷調查回應',
                            onAction: () => {
                              this.setState(state => ({
                                surveyResponsetListModalProps: { ...state.surveyResponsetListModalProps || {}, show: true, surveyId: record.id, key: record.id }
                              }))
                            }
                          },
                          {
                            type: 'item',
                            disabled: config.REACT_APP_DELETE_DISABLED,
                            key: 'delete',
                            name: '删除',
                            onAction: () => {
                              confirm({
                                title: `是否刪除${record.title}`,
                                icon: <ExclamationCircleOutlined />,
                                okText: 'Yes',
                                okType: 'danger',
                                cancelText: 'No',
                                onOk: async () => {
                                  try {
                                    await this._deleteSurvey({ id: record.id })
                                    this.tableRef && this.tableRef.refreshData()
                                    message.success(`${record.title} 删除成功`)
                                  } catch (error) {
                                    message.error(`${record.title} 删除失败 [${error.message}]`);
                                  }
                                },
                                onCancel() {
                                },
                              });
                            }
                          },
                        ]}
                      />
                    )

                  },
                },
                {
                  title: 'ID',
                  dataIndex: 'id',
                  key: 'id',
                  width: 50,
                  render: (value) => (
                    <JJ_TableItemCopyableId
                      text={value}
                    />
                  )
                },

                {
                  title: '標題',
                  dataIndex: 'title',
                  key: 'title',
                  render: (value, record) => record.title[this.props.user.data.language]
                },

                {
                  title: '是否啟動',
                  dataIndex: 'isEnable',
                  key: 'isEnable',
                  render: (value, record) => {
                    return (
                      record.isEnable
                        ? <Tag color="blue">啟用</Tag>
                        : <Tag color="red">停用</Tag>
                    )
                  }
                },

                {
                  title: '是否可選',
                  dataIndex: 'optional',
                  key: 'optional',
                  render: (value, record) => {
                    return (
                      record.optional
                        ? <Tag color="blue">可選</Tag>
                        : <Tag color="red">不可選</Tag>
                    )
                  }
                },

                {
                  title: '開始時間',
                  dataIndex: 'beginAt',
                  key: 'beginAt',
                  render: (value) => value && moment(value).format('YYYY-MM-DD')
                },

                {
                  title: '結束時間',
                  dataIndex: 'endAt',
                  key: 'endAt',
                  render: (value) => value && moment(value).format('YYYY-MM-DD')
                },

                {
                  title: '備註',
                  dataIndex: 'remark',
                  key: 'remark',
                },
                {
                  title: '創建時間',
                  dataIndex: 'createdAt',
                  key: 'createdAt',
                  render: (value) => (
                    <JJ_TableItemMoment
                      date={value}
                    />
                  )
                },
              ]}
              title={'問卷調查列表'}
              onDataSource={async (body) => {
                const { sourceItemBody } = body
                const params: {
                  isAscend?: GetSurveyListParameters['isAscend']

                } = {}
                if (sourceItemBody) {
                  if (sourceItemBody.desc) {
                    params.isAscend = sourceItemBody.desc === 'ascend' ? true : false
                  }

                }

                const res = await api.GetSurveyList({
                  ...body,
                  ...params,
                })
                if (res.kind !== 'ok') throw new Error(res.kind)
                return res.data
              }}
            />
          )}
        </Spin>

      </div>
    )
  }

}
export default connector(SurveyList)

