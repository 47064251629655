import React, { } from 'react';
import { Transfer, Table, Button } from 'antd';
import { TransferProps } from 'antd/lib/transfer';
import { ColumnsType, TableProps } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';


interface PageState {

}

interface Props {
  transferProps: TransferProps
  leftColumns: ColumnsType<any>
  rightColumns: ColumnsType<any>
  tableProps?: TableProps<any>
}

type PageProps = Props

interface Page {

}

export default class JJ_TableTransfer extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {

  }



  render() {


    return (
      <Transfer
        {...this.props.transferProps} showSelectAll={false}
      >
        {({
          direction,
          filteredItems,
          onItemSelectAll,
          onItemSelect,
          selectedKeys: listSelectedKeys,
          disabled: listDisabled,
        }) => {

          const columns = direction === 'left' ? this.props.leftColumns : this.props.rightColumns;

          let dataSource: any = filteredItems
          const rowSelection: TableRowSelection<any> = {
            getCheckboxProps: item => ({ disabled: listDisabled || item.disabled }),
            onSelectAll(selected, selectedRows) {
              const treeSelectedKeys = selectedRows
                .filter(item => !item.disabled)
                .map(({ key }) => key);
              // const diffKeys = selected
              //   ? difference(treeSelectedKeys, listSelectedKeys)
              //   : difference(listSelectedKeys, treeSelectedKeys);
              onItemSelectAll(treeSelectedKeys, selected);
            },
            onSelect({ key }, selected) {
              onItemSelect(key, selected);
            },
            selectedRowKeys: listSelectedKeys,
          };

          return (
            <Table
              {...this.props.tableProps}
              rowSelection={rowSelection}
              columns={columns}
              dataSource={dataSource}
              size="small"
              style={{ pointerEvents: listDisabled ? 'none' : undefined, ... this.props.tableProps?.style || {} }}
              onRow={({ key, disabled: itemDisabled }) => ({
                onClick: () => {
                  if (itemDisabled || listDisabled) return;
                  onItemSelect(key, !listSelectedKeys.includes(key));
                },
              })}
            />
          );
        }}
      </Transfer>
    )
  }

}
