import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, message, Spin, Button, Tag, Space } from 'antd';
import api, { GetReplenishRequestRes, GetReplenishRequestListParameters, I18n } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import ReplenishRequestImportExcelModal from './ReplenishRequestImportExcelModal';
import { PlusOutlined } from '@ant-design/icons';
import { connect, ConnectedProps } from 'react-redux';
import moment from 'moment';
import { ActionUserRes } from '../../actions/ActionUser';
import JJ_TableItemAction from '../../components/JJ_TableItemAction';
import ReplenishRequestDetailList from './ReplenishRequestDetailList';

type EditAndAddModalType = { type: 'edit', id: string } | { type: 'edits', ids: string[] } | { type: 'addXlsx' }

interface PageState {
  pageKey?: any
  isSpinLoading: boolean
  editAndAddModal: {
    show: boolean
    key: string
  } & EditAndAddModalType
  dateList: string[]
  detailsModal?: {
    show: boolean
    key: string
    kioskId: string
    date: string
  }
}
interface Props {
  kioskSupplierKey: string
}

type DateListItem = {
  [x: string]: number
}

type GetReplenishRequestTableRes = GetReplenishRequestRes & { id: string } & DateListItem

interface Page {
  tableRef?: JJ_Table<GetReplenishRequestTableRes> | null
}


type PageProps = Props & RouteChildrenProps & ConnectedProps<typeof connector>

interface RootState {
  user: ActionUserRes
}

const mapState = (state: RootState) => ({
  user: state.user,

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class ReplenishRequestList extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: true,
      editAndAddModal: {
        type: 'addXlsx',
        show: false,
        key: new Date().toString()
      },
      dateList: []
    }
  }
  tableRef?: JJ_Table<GetReplenishRequestTableRes> | null
  componentDidMount() {
    this._initData()
  }



  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true
      })
      this.setState({
        isSpinLoading: false,
      })
    } catch (error) {
      this.setState({
        isSpinLoading: false
      })
    }
  }
  render() {
    console.log('ReplenishRequestList',this.props)
    return (
      <div id="ReplenishRequest">
        <Space align='center' >
          <Button
            type='primary'
            style={{ marginBottom: 5 }}
            onClick={() => this.setState(state => ({
              editAndAddModal: { ...state.editAndAddModal, show: true, key: new Date().toString(), type: 'addXlsx' }
            }))}
            icon={<PlusOutlined />}
          >
            {`導入Excel`}
          </Button>
        </Space>

        <Modal
          maskClosable={false}
          title={(() => {
            switch (this.state.editAndAddModal.type) {
              case 'addXlsx': {
                return '導入Excel'
              }
            }
          })()}
          visible={this.state.editAndAddModal.show}
          footer={null}
          onCancel={() => this.setState(state => ({
            editAndAddModal: { ...state.editAndAddModal, show: false }
          }))}
          width={'50%'}
        >
          <ReplenishRequestImportExcelModal
            {...this.state.editAndAddModal}
            kioskSupplierKey={this.props.kioskSupplierKey}
            onFinish={async (props) => {
              this.setState(state => ({
                editAndAddModal: { ...state.editAndAddModal, show: false }
              }))
              switch (props.type) {
                case 'addXlsx': {
                  this.tableRef && await this.tableRef.refreshData()
                  message.success(`已成功創建補貨單`)
                }
                  break
                case 'edit': {
                  this.tableRef && await this.tableRef.refreshData()
                  message.success(`編輯成功`)
                }
                  break
                case 'edits': {
                  this.tableRef && await this.tableRef.refreshData()
                  message.success(`編輯成功`)
                }
                  break
              }

            }}
          />
        </Modal>

        {this.state.detailsModal && (
          <Modal
            maskClosable={false}
            title={'補貨單详情列表'}
            visible={this.state.detailsModal.show}
            footer={null}
            onCancel={() => {
              if (this.state.detailsModal) {
                this.setState({
                  detailsModal: { ...this.state.detailsModal, show: false }
                })
              }
            }}
            width={'80%'}
          >
            <ReplenishRequestDetailList
              kioskId={this.state.detailsModal.kioskId}
              date={this.state.detailsModal.date}
              key={this.state.detailsModal.key}
              kioskSupplierKey={this.props.kioskSupplierKey}
            />
          </Modal>
        )}


        <Spin spinning={this.state.isSpinLoading}>
          {!this.state.isSpinLoading && (
            <JJ_Table<GetReplenishRequestTableRes, {
              createRange?: [moment.Moment, moment.Moment] | null
            }>

              ref={ref => this.tableRef = ref}
              sourceItems={[
                {
                  type: 'rangePicker',
                  key: 'createRange',
                  defaultValue: [moment().startOf('day'), moment().startOf('day').add(2, 'day')],
                  span: 6,
                  props: {
                    allowClear: false
                  }
                },
              ]}
              tableProps={{
                pagination: false,
                scroll: {
                  x: 180 + 120 + this.state.dateList.length * 180
                }
              }}
              columns={
                [
                  {
                    title: '機器名',
                    dataIndex: 'kioskVending.name',
                    key: 'kioskVending.name',
                    fixed: 'left',
                    width: 180,
                    render: (value, record, index) => {
                      if (record.kioskVending) {
                        return (record.kioskVending.name as I18n)[this.props.user.data.language]
                      }
                    }
                  },
                  {
                    title: '機器號',
                    dataIndex: 'kioskVending.code',
                    key: 'kioskVending.code',
                    fixed: 'left',
                    width: 120,
                    render: (value, record, index) => {
                      if (record.kioskVending) {
                        return record.kioskVending.code
                      }
                    }
                  },
                  ...this.state.dateList.map(date => ({
                    title: moment(date).format('YYYY-MM-DD (ddd)'),
                    dataIndex: date,
                    key: date,
                    render: (value: number, record: GetReplenishRequestTableRes) => {

                      const replenish = record.replenishRequests[value]
                      if (replenish) {
                        return (
                          <Space>
                            <Button
                              size='small'
                              type={replenish.total === replenish.completed ? 'primary' : 'default'}
                              disabled={(!record.kioskVending) || replenish.total === 0}
                              onClick={() => {
                                if (record.kioskVending) {
                                  this.setState({
                                    detailsModal: {
                                      show: true,
                                      key: Date.now().toString(),
                                      kioskId: record.kioskVending.kioskId,
                                      date: replenish.date,
                                    }
                                  })
                                }
                              }}
                            >
                              {replenish.completed} / {replenish.total}
                            </Button>
                          </Space>
                        )
                      }


                    }

                  }))
                ]}
              title={'補貨單列表'}
              onDataSource={async (body) => {
                const { sourceItemBody } = body
                const params: {
                  startTime?: GetReplenishRequestListParameters['startTime']
                  endTime?: GetReplenishRequestListParameters['endTime']
                } = {}
                if (sourceItemBody) {

                  const createRange = sourceItemBody.createRange
                  if (createRange && createRange.length === 2) {
                    const [createRangeBeginAtMoment, createRangeEndAtMoment,] = createRange
                    params.startTime = createRangeBeginAtMoment.format('YYYY-MM-DD')
                    params.endTime = createRangeEndAtMoment.format('YYYY-MM-DD')
                  }

                  if (params.startTime && params.endTime) {
                    const res = await api.GetReplenishRequestList({
                      startTime: params.startTime,
                      endTime: params.endTime,
                      kioskSupplierKey: this.props.kioskSupplierKey,
                    })
                    if (res.kind !== 'ok') throw new Error(res.kind)

                    const dateList = res.data.data[0].replenishRequests.map(replenishRequest => replenishRequest.date)

                    this.setState({
                      dateList
                    })


                    let dataOjb: DateListItem = {}

                    dataOjb = dateList.reduce((pV, cV, index) => {
                      pV[cV] = index
                      return pV
                    }, dataOjb)

                    const data: any = res.data.data.map((item, index) => ({
                      ...item,
                      id: `${index}`,
                      ...dataOjb,
                    }))

                    return {
                      totalCount: 0,
                      data,
                    }

                  }
                }
                return { totalCount: 0, data: [] }


              }}
            />
          )}
        </Spin>

      </div>
    )
  }

}
export default connector(ReplenishRequestList)



