import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, message, Spin, Button, Tag, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import api, { GetProductListParameters, DeleteProductParameters, GetProductRes, GetQuotaRes } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import JJ_TableItemAction from '../../components/JJ_TableItemAction';
import JJ_TableItemMoment from '../../components/JJ_TableItemMoment';
import ProductEditAndAdd, { ProductEditAndAddPropsType } from './ProductEditAndAdd';
import { PlusOutlined } from '@ant-design/icons';
import { connect, ConnectedProps } from 'react-redux';
import JJ_TableItemCopyableId from '../../components/JJ_TableItemCopyableId';
import config from '../../config';
import moment from 'moment';
import { ActionUserRes } from '../../actions/ActionUser';
import ProductLabelList from '../productLabel/ProductLabelList';
import { getI18nText } from '../../public';


const { confirm } = Modal;

type EditAndAddModalType = ProductEditAndAddPropsType

interface PageState {
  isSpinLoading: boolean
  editAndAddModal: {
    show: boolean
    key: string
  } & EditAndAddModalType
  productLabelListModal?: {
    show: boolean
    key: string
    productId: string
  }
}
interface Props {

}


interface Page {
  tableRef?: JJ_Table<GetProductRes> | null

}


type PageProps = Props & ConnectedProps<typeof connector>

interface RootState {
  user: ActionUserRes

}

const mapState = (state: RootState) => ({
  user: state.user,
})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class ProductList extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: true,
      editAndAddModal: {
        type: 'add',
        show: false,
        key: new Date().toString(),
      }
    }
  }
  tableRef?: JJ_Table<GetProductRes> | null
  componentDidMount() {
    this._initData()
  }
  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true
      })
      await Promise.resolve()
      this.setState({
        isSpinLoading: false
      })
    } catch (error) {
      this.setState({
        isSpinLoading: false
      })
    }
  }


  _deleteProduct = async (params: DeleteProductParameters) => {
    const res = await api.DeleteProduct(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  render() {
    return (
      <div id="ProductList"
      >
        <Button
          type='primary'
          style={{ marginBottom: 5 }}
          onClick={() => {
            this.setState(state => ({
              editAndAddModal: {
                ...state.editAndAddModal,
                show: true,
                key: new Date().toString(),
                type: 'add',
              }
            }))
          }}
          icon={<PlusOutlined />}
        >
          {`添加產品`}
        </Button>

        <Modal
          maskClosable={false}
          title={this.state.editAndAddModal.type === 'edit'
            ? '編輯產品'
            : '新建產品'
          }
          visible={this.state.editAndAddModal.show}
          footer={null}
          onCancel={() => this.setState(state => ({
            editAndAddModal: { ...state.editAndAddModal, show: false }
          }))}
          width={'85%'}
        >
          <ProductEditAndAdd
            {...this.state.editAndAddModal}
            user={this.props.user.data}
            onFinish={async (props) => {
              this.setState(state => ({
                editAndAddModal: { ...state.editAndAddModal, show: false }
              }))
              switch (props.type) {
                case 'add': {
                  message.success(`添加成功`)
                }
                  break
                case 'edit': {
                  message.success(`編輯成功`)
                }
                  break

              }

              this.tableRef && await this.tableRef.refreshData()


            }}
          />
        </Modal>

        {this.state.productLabelListModal && (
          <Modal
            key={this.state.productLabelListModal.key}
            maskClosable={false}
            title={'查看產品標籤'}
            visible={this.state.productLabelListModal.show}
            footer={null}
            onCancel={() => {
              if (this.state.productLabelListModal) {
                this.setState({
                  productLabelListModal: { ... this.state.productLabelListModal, show: false, }
                })
              }

            }}
            width={'85%'}
          >
            <ProductLabelList
              productId={this.state.productLabelListModal.productId}
            />
          </Modal>
        )}



        <Spin spinning={this.state.isSpinLoading}>
          {!this.state.isSpinLoading && (
            <JJ_Table<GetProductRes, {
              desc: 'ascend' | 'descend'
              createRange?: [moment.Moment, moment.Moment] | null
            }>
              ref={ref => this.tableRef = ref}
              isSearchText={true}
              sourceItems={[
                {
                  type: 'select',
                  defaultValue: 'ascend',
                  key: 'desc',
                  options: [
                    {
                      value: 'ascend',
                      name: '升序',
                      disabled: false,
                    },
                    {
                      value: 'descend',
                      name: '降序',
                      disabled: false,
                    },
                  ]
                },
                {
                  type: 'rangePicker',
                  key: 'createRange',
                  span: 8,
                  props: {
                    placeholder: ['創建開始時間', '創建結束時間'],
                  }
                },
              ]}
              columns={[
                {
                  key: 'action',
                  width: 50,
                  render: (value: any, record) => {



                    return (
                      <JJ_TableItemAction
                        menus={[
                          {
                            type: 'item',
                            disabled: false,
                            key: 'edit',
                            name: '編輯',
                            onAction: () => {
                              this.setState(state => ({
                                editAndAddModal: { ...state.editAndAddModal, show: true, type: 'edit', id: record.id, key: new Date().toString() }
                              }))
                            }
                          },
                          {
                            type: 'item',
                            disabled: false,
                            key: '查看產品標籤',
                            name: '查看產品標籤',
                            onAction: () => {
                              this.setState(state => ({
                                productLabelListModal: {
                                  ...state.productLabelListModal,
                                  show: true,
                                  key: new Date().toString(),
                                  productId: record.id
                                }
                              }))
                            }
                          },
                          {
                            type: 'item',
                            disabled: config.REACT_APP_DELETE_DISABLED,
                            key: 'delete',
                            name: '删除',
                            onAction: () => {
                              confirm({
                                title: `是否刪除${record.title[this.props.user.data.language]}`,
                                icon: <ExclamationCircleOutlined />,
                                okText: 'Yes',
                                okType: 'danger',
                                cancelText: 'No',
                                onOk: async () => {
                                  try {
                                    await this._deleteProduct({ id: record.id })
                                    this.tableRef && this.tableRef.refreshData()
                                    message.success(`${record.title[this.props.user.data.language]} 删除成功`)
                                  } catch (error) {
                                    message.error(`${record.title[this.props.user.data.language]} 删除失败 [${error.message}]`);
                                  }
                                },
                                onCancel() {
                                },
                              });
                            }
                          },
                        ]}
                      />
                    )

                  },
                },
                {
                  title: 'ID',
                  dataIndex: 'id',
                  key: 'id',
                  width: 50,
                  render: (value) => (
                    <JJ_TableItemCopyableId
                      text={value}
                    />
                  )
                },
                {
                  title: '標題',
                  dataIndex: 'title',
                  key: 'title',
                  render: (value, record) => getI18nText(record.title, this.props.user.data.language)
                },
                {
                  title: '配額',
                  dataIndex: 'quota',
                  key: 'quota',
                  render: (value, record) => getI18nText((record?.quota as GetQuotaRes)?.name, this.props.user.data.language)
                },
                {
                  title: '唯一碼',
                  dataIndex: 'uniqueCode',
                  key: 'uniqueCode',
                },
                {
                  title: '最大貨道容量',
                  dataIndex: 'maxCount',
                  key: 'maxCount',
                },
                {
                  title: '重量',
                  dataIndex: 'weight',
                  key: 'weight',
                  render: (value, record) =>value?`${value} g`:undefined
                },
                {
                  title: '創建時間',
                  dataIndex: 'createdAt',
                  key: 'createdAt',
                  render: (value) => (
                    <JJ_TableItemMoment
                      date={value}
                    />
                  )
                },
              ]}
              title={'產品列表'}
              tableProps={{

              }}
              onDataSource={async (body) => {
                const { sourceItemBody } = body
                const params: {
                  isAscend?: GetProductListParameters['isAscend']
                  beginAt?: GetProductListParameters['beginAt']
                  endAt?: GetProductListParameters['endAt']

                } = {}
                if (sourceItemBody) {
                  if (sourceItemBody.desc) {
                    params.isAscend = sourceItemBody.desc === 'ascend' ? true : false
                    const createRange = sourceItemBody.createRange
                    if (createRange && createRange.length === 2) {
                      const [createRangeBeginAtMoment, createRangeEndAtMoment,] = createRange
                      params.beginAt = createRangeBeginAtMoment.toISOString()
                      params.endAt = createRangeEndAtMoment.toISOString()
                    }
                  }

                }

                const res = await api.GetProductList({
                  ...body,
                  ...params,
                  scope: ['quota'],
                })
                if (res.kind !== 'ok') throw new Error(res.kind)
                return res.data
              }}
            />
          )}
        </Spin>

      </div>
    )
  }

}
export default connector(ProductList)

