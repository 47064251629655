import React, { ReactElement } from 'react';
import { Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/lib/upload/interface';

interface PageProps {
  value?: RcFile[]
  onChange?: (value: any[]) => void;
}

interface PageState {
}

interface Page {

}

export default class JJ_FromUploadXlsx extends React.Component<PageProps, PageState> implements Page {

  componentDidMount() {
  }

  constructor(props: any) {
    super(props);
    this.state = {

    }
  }

  render() {
    const { value = [] } = this.props
    return (
      <>
        <Upload
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          fileList={value}
          onRemove={(file) => {
            this.props.onChange && this.props.onChange([])
          }}
          beforeUpload={(file) => {
            this.props.onChange && this.props.onChange([file])
            return false;
          }}
        >
          <Button icon={<UploadOutlined />}>選擇xlsx檔案</Button>
        </Upload>
      </>
    )
  }

}

