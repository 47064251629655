import React, { } from 'react';
import { Tag, Modal, Button, Input, message, Spin } from 'antd';
import api, { GetStatisticKioskVendingSellDayParameters, GetStatisticKioskVendingSellDayTimeItem, GetStatisticKioskVendingSellDayRes, GetStatisticKioskVendingSellDayDataRes } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import moment from 'moment';

interface PageState {
  isSpinLoading: boolean
  statisticKioskVendingSellDayData: GetStatisticKioskVendingSellDayDataRes[]
}

interface Props {
  kioskVendingIds: GetStatisticKioskVendingSellDayParameters['kioskVendingIds']
  title: string
  visible?: boolean
  onCancel(): void
  beginAtMoment: moment.Moment
  endAtMoment: moment.Moment
}


interface Page {

}


type PageProps = Props


class KioskVendingSellDayListModal extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);

    this.state = {
      isSpinLoading: true,
      statisticKioskVendingSellDayData: []
    }

  }
  componentDidMount() {
    this._onLoadData()
  }



  _onLoadData = async (): Promise<any> => {
    this.setState({
      isSpinLoading: false
    })
  };

  tableRef?: JJ_Table<GetStatisticKioskVendingSellDayTimeItem & { id: string }> | null


  _view = () => {
    return (
      <div>
        <JJ_Table<GetStatisticKioskVendingSellDayTimeItem & { id: string }, {
          rangePicker?: [moment.Moment, moment.Moment] | null
          isShowError?: 'false' | 'true'

        }>
          ref={ref => this.tableRef = ref}
          isSearchText={false}
          tableProps={{
            pagination: false,
            scroll: {
              y: '65vh',
              x: 600 + this.state.statisticKioskVendingSellDayData.length * 150
            }
          }}
          sourceItems={[
            {
              type: 'rangePicker',
              key: 'rangePicker',
              defaultValue: [moment(this.props.beginAtMoment), moment(this.props.endAtMoment)],
              props: {
              }
            },
            {
              type: 'select',
              defaultValue: 'false',
              key: 'isShowError',
              placeholder: '任意記錄',
              allowClear: true,
              options: [
                {
                  value: 'true',
                  name: <Tag color='error'>顯示失敗記錄</Tag>,
                  disabled: false,
                },
                {
                  value: 'false',
                  name: <Tag color='success'>不顯示失敗記錄</Tag>,
                  disabled: false,
                },
              ]
            },
          ]}
          columns={[
            {
              title: '時間',
              dataIndex: 'time',
              key: 'value',
              fixed: 'left',
              width: 130,
            },
            ...this.state.statisticKioskVendingSellDayData.map(item => ({
              title: moment(item.date).format('YYYY-MM-DD (ddd)'),
              dataIndex: item.date,
              key: item.date,
            })),
          ]}
          title={this.props.title}
          onDataSource={async (body) => {
            const { sourceItemBody } = body
            const params: {
              isAscend?: GetStatisticKioskVendingSellDayParameters['isAscend']
              scope?: GetStatisticKioskVendingSellDayParameters['scope']
              beginAt?: GetStatisticKioskVendingSellDayParameters['beginAt']
              endAt?: GetStatisticKioskVendingSellDayParameters['endAt']
              kioskVendingIds?: GetStatisticKioskVendingSellDayParameters['kioskVendingIds']
              isShowError?: GetStatisticKioskVendingSellDayParameters['isShowError']
            } = {}
            if (sourceItemBody) {

              if (sourceItemBody.isShowError === 'false') {
                params.isShowError = false
              }

              if (sourceItemBody.isShowError === 'true') {
                params.isShowError = true
              }

              const rangePicker = sourceItemBody.rangePicker
              if (rangePicker && rangePicker.length === 2) {
                const [beginAtMoment, endAtMoment,] = rangePicker
                params.beginAt = beginAtMoment.toISOString()
                params.endAt = endAtMoment.toISOString()
              }
            }

            params.kioskVendingIds = this.props.kioskVendingIds
            params.scope = ['community']
            const res = await api.GetStatisticKioskVendingSellDay({
              ...body,
              ...params,
            })
            if (res.kind !== 'ok') throw new Error(res.kind)
            this.setState({
              statisticKioskVendingSellDayData: res.data.data
            })


            //格式化
            const newDataItemModals: { [x: string]: number | string }[] = []
            const newDatas = res.data.data[0].timeItem.reduce((previousValue, currentValue, currentIndex, array) => {

              const time = currentValue.time
              const data: { [x: string]: any } = {}
              data['time'] = time
              res.data.data.forEach(item => {
                data[item.date] = item.timeItem.reduce((p, c) => {
                  return p + (c.time === time ? c.count : 0)
                }, 0)
              })
              previousValue.push(data)
              return previousValue
            }, newDataItemModals)

            //合计 00:00-07:00
            const hourTotalData: any = {}

            // //日取餐量
            const dayTotalData: any = {}


            newDatas.forEach((item, index) => {

              if (index < 7) {
                Object.keys(item).forEach(key => {
                  hourTotalData[key] = (Number(item[key]) || 0) + (Number(hourTotalData[key]) || 0)
                })
              }

              Object.keys(item).forEach(key => {
                dayTotalData[key] = (Number(item[key]) || 0) + (Number(dayTotalData[key]) || 0)
              })


            })

            hourTotalData['time'] = '00.00-07:00'

            dayTotalData['time'] = '日取餐量'


            newDatas.splice(
              0,
              7,
              hourTotalData,
            )


            newDatas.push(dayTotalData)
            //總計取餐量
            const totalCount = Object.keys(dayTotalData).reduce((p, c) => {
              return p + Number(dayTotalData[c]) || 0
            }, 0)

            //總計取餐量
            newDatas.push({
              'time': '總計取餐量',
              [res.data.data[0].date]: totalCount
            })

            const data = {
              data: newDatas.map((item: any) => ({ ...item, id: item.time })),
              totalCount: newDatas.length,
            }
            console.log(22333, data)
            return data
          }}
        />
      </div>
    )
  }

  render() {

    return (
      <div id="KioskVendingSellDayListModal">
        <Modal
          footer={null}
          maskClosable={false}
          title={null}
          visible={this.props.visible}
          onCancel={this.props.onCancel}
          width={'85%'}
        >
          <Spin spinning={this.state.isSpinLoading}>
            {!this.state.isSpinLoading && this._view()}
          </Spin>

        </Modal>
      </div>
    )
  }

}
export default KioskVendingSellDayListModal


