import React, { } from 'react';
import { Tag, Modal, Button, Input, message } from 'antd';
import JJ_From, { FormItemData } from '../../components/JJ_From';
import api from '../../api';

interface PageState {

}

interface Props {
  userId: string
  title: string
  visible: boolean
  onCancel(): void
  onFinish(res?: any): void
}


interface Page {

}


type PageProps = Props


class UserAuthPasswordChangeModal extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);

    this.state = {

    }

  }
  componentDidMount() {
  }

  _formListData = (): FormItemData[] => {
    return [
      {
        id: 'password',
        label: '用戶密碼',
        rules: [
          {
            required: true,
            message: `請輸入 用戶密碼`,
          }
        ]
      },
      {
        id: 'passwordNew01',
        label: '新密碼',
        rules: [
          {
            required: true,
            message: `請輸入 6~16位 字段新密碼`,
            max: 16,
            min: 6,
          }
        ],
        componet: (<Input type='password' />)
      },
      {
        id: 'passwordNew02',
        label: '確定新密碼',
        rules: [
          {
            required: true,
            message: `請輸入 6~16位 字段新密碼`,
            max: 16,
            min: 6,
          }
        ],
        componet: (<Input type='password' />)
      },
      {
        id: 'submit',
      },
    ]
  }

  _onFinish = async (values: any): Promise<void> => {

    const {
      password,
      passwordNew01,
      passwordNew02,
    } = values

    if (passwordNew01 !== passwordNew02) {
      message.error('新密碼不匹配')
      return
    }

    const res = await api.PostAuthPasswordChange({
      userId: this.props.userId,
      password,
      passwordNew: passwordNew02,
    })

    if (res.kind !== 'ok') throw Error(res.kind)
    this.props.onFinish && this.props.onFinish()
  }

  _onLoadData = async (): Promise<any> => {

    return
  };


  render() {

    return (
      <div id="UserAuthPasswordChangeModal">
        <Modal
          footer={null}
          maskClosable={false}
          title={this.props.title}
          visible={this.props.visible}
          onCancel={this.props.onCancel}
          width={'85%'}
        >
          <JJ_From
            formListData={this._formListData()}
            onLoadData={this._onLoadData}
            onFinish={this._onFinish}
          />
        </Modal>
      </div>
    )
  }

}
export default UserAuthPasswordChangeModal


