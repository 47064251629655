import axios, { AxiosResponse } from 'axios'
import { ApiConfigType } from "./api-config"

import *as antd from "antd";
import { getApiErrorCodeText } from './api-error-code-text';

export const createHttp = (config: ApiConfigType) => {
    const http = axios.create({
        baseURL: config.url,
        validateStatus: status => status === 200,
        // validateStatus: function (status) {
        //     return true
        // },
        withCredentials: true, // 默认的
        timeout: 60 * 1000 * 10,
    })

    http.interceptors.request.use(config => {
        return config
    })

    http.interceptors.response.use(response => {

        console.log(`${response.config.method}====>${response.config.url}`, response)
        return response
    }, async (error) => {
        const response: AxiosResponse<any> = error.response
        console.error(`${response.config.method}====>${response.config.url}`, error.response)
        const message: any | undefined = error.response?.data.errorCode
        if (message) {

            antd.message.error(getApiErrorCodeText(message))
        }

        const { status } = error.response || {}

        if (status === 403) {
            window.location.hash = '/login'
        }
        throw error

    })
    return http
}



