import React, { } from 'react';
import api, { PostFoodCardDownloadImageParameters, GetFoodCardRes, GetFoodCardListParameters, GetFoodCardQuotaRes, GetFoodCardRecordListParameters, GetKioskVendingRes, GetKioskVendingListParameters, GetFoodCardQuotaDatas } from '../../api';
import { connect, ConnectedProps } from 'react-redux';
import { Spin, Alert, Tree, Modal, message, Button, Layout, Carousel, Tag } from 'antd';
import JJ_Table from '../../components/JJ_Table';
import moment from 'moment';
import { getI18nText } from '../../public';
import { ActionUserRes } from '../../actions/ActionUser';



interface PageState {
  spinning: boolean
}

interface Props {
  foodCard: GetFoodCardRes
}

interface Page {

  /**
   * 获取数据
   */
  _onLoadData(): Promise<{}>

}


type PageProps = Props & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {
  user: ActionUserRes
}

const mapState = (state: RootState) => ({
  user: state.user
})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class FoodCardQuotaList extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);

    this.state = {
      spinning: true,
    }

  }
  componentDidMount() {
    this._onLoadData()
  }


  _getFoodCardList = async (params: GetFoodCardListParameters) => {
    const res = await api.GetFoodCardList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _onLoadData = async (): Promise<any> => {

    try {
      this.setState({
        spinning: true
      })

      this.setState({
        spinning: false,
      })
    } catch (error) {
      this.setState({
        spinning: false
      })
      console.log(error)
    }
  };


  render() {
    return (
      <div id="FoodCardQuotaList"
      >
        <Spin
          spinning={this.state.spinning}
        >
          {!this.state.spinning && (
            <JJ_Table<GetFoodCardQuotaDatas, {
            }>
              tableProps={{
                pagination: false,

              }}
              isSearchText={false}
              columns={[
                {
                  title: '名稱',
                  dataIndex: 'name',
                  key: 'name',
                  width: 120,
                  render: (value, record) => getI18nText(record.name, this.props.user.data.language)
                },

                {
                  title: '每月餘額',
                  dataIndex: 'monthCount',
                  key: 'monthCount',
                  width: 120,
                },

                {
                  title: '每周餘額',
                  dataIndex: 'weekCount',
                  key: 'weekCount',
                  width: 120,
                },

                {
                  title: '每日餘額',
                  dataIndex: 'dayCount',
                  key: 'dayCount',
                  width: 120,
                },
              ]}
              title={'剩餘配額'}
              onDataSource={async (body) => {

                const res = await api.GetFoodCardQuota({
                  id: this.props.foodCard.id,
                })
                if (res.kind !== 'ok') throw new Error(res.kind)
                return {
                  data: res.data.quotas,
                  totalCount: res.data.quotas.length,
                }
              }}
            />
          )}

        </Spin>
      </div>
    )
  }

}
export default connector(FoodCardQuotaList)


