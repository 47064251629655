import ActionType from './ActionType'
import { MyThunkDispatch } from './action-interface'
import tool from '../tool'



const updataAction = (data: ActionConfigRes) => {
    return {
        type: ActionType.configType.UP_DATE,
        ...data
    }
}



export interface ActionConfigRes {
    momentType: 'format' | 'fromNow'
}

export const setConfigData = (data: ActionConfigRes) => {
    return async (dispatch: MyThunkDispatch) => {
        let configRes = <ActionConfigRes>tool.store.getState().config
        configRes = { ...configRes, ...data }
        dispatch(updataAction(configRes))
    }
}





