import React, { ReactElement } from 'react';
import { Upload, Button, } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { RcFile, UploadProps } from 'antd/lib/upload/interface';
interface PageProps {
  value?: RcFile[]
  onChange?: (value: any[]) => void;
  uploadProps?: UploadProps
  maxCount?: number
}

interface PageState {
}

interface Page {

}

export default class JJ_FromUploadImage extends React.Component<PageProps, PageState> implements Page {

  componentDidMount() {
  }

  constructor(props: any) {
    super(props);
    this.state = {

    }
  }




  render() {
    return (
      <>
        <Upload.Dragger
          accept='image/*'
          listType='picture'
          method={'POST'}
          // action={`${config.REACT_APP_API}/v2/admin/image`}
          withCredentials={true}
          onRemove={(file) => {
            const files = (this.props.value || []).filter(value => value.uid !== file.uid)
            this.props.onChange && this.props.onChange(files)
          }}

          beforeUpload={(file: any, files) => {
            let newFiles = this.props.value || []

            if (this.props.maxCount && this.props.maxCount <= newFiles.length) {

              newFiles.shift()

            }

            Promise.all(
              [...newFiles, ...files]
                .map(async (file: any) => {
                  if (!file.thumbUrl) {
                    const r = new FileReader();
                    r.readAsDataURL(file);
                    return await new Promise((resolve) => {
                      r.onload = e => {
                        file.thumbUrl = e.target?.result
                        file.url = e.target?.result
                        file.status = 'done'
                        resolve(file)
                      };
                    })
                  } else {
                    return file
                  }
                })
            )
              .then(newFiles => {
                this.props.onChange && this.props.onChange(newFiles)
              })
            return false
          }}
          fileList={this.props.value}
          {...this.props.uploadProps}
        >
          <p className="ant-upload-drag-icon">
            <UploadOutlined />
          </p>
          <p className="ant-upload-text">上傳圖片</p>
          <p className="ant-upload-hint">
            支持上傳多個檔案，並支持拖拽互動
    						</p>
        </Upload.Dragger>
      </>
    )
  }

}

