import React, { } from 'react';
import JJ_From, { FormItemData } from "../../components/JJ_From";
import api, { GetAreaRes, GetAreaListParameters } from '../../api';
import { Select, Tag } from 'antd';
import { connect, ConnectedProps } from 'react-redux';
import { getI18nText } from '../../public';
import { ActionUserRes } from '../../actions/ActionUser';


interface PageState {
  areas: GetAreaRes[]
}

type Type = { type: 'add' } | { type: 'edit', id: string }

interface Props {
  onFinish?(props: PageProps, id: string): void
}



interface Page {
  /**
   * 保存数据
   */
  _onFinish(values: any): void

  /**
   * 获取数据
   */
  _onLoadData(): Promise<{}>

  id?: string

}


type PageProps = Props & Type & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {
  user: ActionUserRes
}

const mapState = (state: RootState) => ({
  user: state.user,

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class CommunityEditAndAdd extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);

    this.state = {
      areas: []
    }

  }
  componentDidMount() {
  }

  _onFinish = async (values: any): Promise<void> => {

    switch (this.props.type) {
      case 'edit': {
        const res = await api.PutCommunity({
          ...values,
          id: this.props.id,
        })
        if (res.kind !== 'ok') throw Error(res.kind)
        this.props.onFinish && this.props.onFinish(this.props, res.data.id)
      }
        break
      case 'add': {

        const res = await api.PostCommunity({
          ...values,
        })
        if (res.kind !== 'ok') throw Error(res.kind)
        this.props.onFinish && this.props.onFinish(this.props, res.data.id)
      }
        break
    }
  }

  _getAreaList = async (params: GetAreaListParameters) => {
    const res = await api.GetAreaList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data

  }
  _onLoadData = async (): Promise<any> => {



    switch (this.props.type) {
      case 'edit': {
        const res = await api.GetCommunity({ id: this.props.id })

        if (res.kind === 'ok') {
          return res.data
        }
        throw Error(res.kind)
      }
      case 'add': {
        const areaList = await this._getAreaList({ count: 10000 })
        this.setState({ areas: areaList.data })
        return
      }

    }
  };

  _formListData = (): FormItemData[] => {
    switch (this.props.type) {
      case 'edit': {
        return [
          {
            id: 'code',
            label: '代號',
            rules: [
              {
                required: false,
                message: `請輸入3字符代號例如：SP1`,
                min: 3,
                max: 3,
              }
            ]
          },
          {
            id: 'name',
            label: '名稱',
            rules: [
              {
                required: false,
                message: `請輸入 名稱`
              }
            ]
          },
          {
            id: 'submit',
          },
        ]
      }
      case 'add': {
        return [
          {
            id: 'areaId',
            label: '區域',
            rules: [
              {
                required: true,
                message: `請選擇區域`
              }
            ],
            componet: (
              <Select>
                {this.state.areas.map(area => {
                  return (
                    <Select.Option key={area.id} value={area.id}  >
                      {getI18nText(area.name, this.props.user.data.language)} <Tag color={area.status === 'NORMAL' ? 'blue' : 'red'}>{area.status === 'NORMAL' ? '正常' : '測試'}</Tag>
                    </Select.Option>
                  )
                })}
              </Select>
            ),
          },
          {
            id: 'code',
            label: '代號',
            rules: [
              {
                required: true,
                message: `請輸入3字段代號例如：SP1`,
                min: 3,
                max: 3,
              }
            ]
          },
          {
            id: 'name',
            label: '名稱',
            rules: [
              {
                required: true,
                message: `请输入 名稱`
              }
            ]
          },
          {
            id: 'submit',
          },
        ]
      }

    }
  }

  render() {
    return (
      <div id="CommunityEditAndAdd">
        <JJ_From
          formListData={this._formListData()}
          onLoadData={this._onLoadData}
          onFinish={this._onFinish}
        />
      </div>
    )
  }

}
export default connector(CommunityEditAndAdd)
