import React, { } from 'react';
import JJ_From, { FormItemData } from "../../components/JJ_From";
import api, { GetFoodCardXlsxPreviewRes, GetKioskVendingRes } from '../../api';
import { connect, ConnectedProps } from 'react-redux';
import { DatePicker, Button, Spin, message } from 'antd';
import moment from 'moment';
import JJ_FromSwitch from '../../components/JJ_FromSwitch';
import JJ_FromUploadXlsx from '../../components/JJ_FromUploadXlsx';
import { RcFile } from 'antd/lib/upload/interface';
import config from '../../config';


interface PageState {
  kioskVendings: GetKioskVendingRes[]
  foodCardXlsxPreviews?: GetFoodCardXlsxPreviewRes[]
  foodCardXlsxPreViewModal: {
    key: string
    visible: boolean
  }
  spinLoading?: boolean
}

type Type = { type: 'edit', id: string } | { type: 'edits', ids: string[] } | { type: 'addXlsx' }

interface Props {
  onFinish?(props: PageProps, data: any): void
  kioskSupplierKey: string
}



interface Page {
  /**
   * 保存数据
   */
  _onFinish(values: any): void

  /**
   * 获取数据
   */
  _onLoadData(): Promise<{}>

  id?: string

}


type PageProps = Props & Type & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>



const mapState = () => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class ReplenishRequestImportExcelModal extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);

    this.state = {
      kioskVendings: [],
      foodCardXlsxPreViewModal: {
        key: new Date().toISOString(),
        visible: false,
      },
      spinLoading: false,
    }

  }
  componentDidMount() {
  }

  _onFinish = async (values: any): Promise<void> => {

    switch (this.props.type) {
      case 'edit': {
        const res = await api.PutFoodCard({
          ...values,
          endAt: values.endAt ? values.endAt : undefined,
          beginAt: values.beginAt ? new Date(values.beginAt).toISOString() : undefined,
          id: this.props.id,
        })
        if (res.kind !== 'ok') throw Error(res.kind)
        this.props.onFinish && this.props.onFinish(this.props, res.data.id)
      }
        break
      case 'edits': {
        const data = await Promise.all(
          this.props.ids.map(async id => {
            const res = await api.PutFoodCard({
              ...values,
              endAt: values.endAt ? values.endAt : undefined,
              beginAt: values.beginAt ? new Date(values.beginAt).toISOString() : undefined,
              id: id,
            })
            if (res.kind !== 'ok') throw Error(res.kind)
            return res.data
          })
        )
        this.props.onFinish && this.props.onFinish(this.props, data)
      }
        break
      case 'addXlsx': {
        const file: RcFile = values.file[0]
        const remark = values.remark.trim() || '';
        const res = await api.PostReplenishRequest({ file, remark, kioskSupplierKey: this.props.kioskSupplierKey })
        if (res.kind !== 'ok') {
          message.error(res.kind)
        } else {
          this.props.onFinish && this.props.onFinish(this.props, res)
        }
      }
        break
    }
  }

  _onLoadData = async (): Promise<any> => {

    switch (this.props.type) {
      case 'edit': {
        const res = await api.GetFoodCard({ id: this.props.id })

        if (res.kind === 'ok') {
          const beginAt = res.data.beginAt ? moment(res.data.beginAt) : res.data.beginAt
          const endAt = res.data.endAt ? moment(res.data.endAt) : res.data.endAt
          return {
            ...res.data,
            beginAt,
            endAt
          }
        }
        throw Error(res.kind)
      }
      case 'edits': {
        const res = await api.GetFoodCard({ id: this.props.ids[0] })

        if (res.kind === 'ok') {
          const beginAt = res.data.beginAt ? moment(res.data.beginAt) : res.data.beginAt
          const endAt = res.data.endAt ? moment(res.data.endAt) : res.data.endAt
          return {
            ...res.data,
            beginAt,
            endAt
          }
        }
        throw Error(res.kind)
      }
      case 'addXlsx': {
        return { remark: moment().format('YYYY-MM-DD') }
      }
    }
  };


  _formListData = (): FormItemData[] => {
    switch (this.props.type) {
      case 'edit': {
        return [
          {
            id: 'isEnable',
            label: '是否啟用',
            rules: [
              {
                required: false,
              }
            ],
            componet: (<JJ_FromSwitch />)
          },
          {
            id: 'beginAt',
            label: '開啟時間',
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <DatePicker picker='date' />
            )
          },
          {
            id: 'endAt',
            label: '停用時間',
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <DatePicker picker='date' />
            )
          },
          {
            id: 'submit',
          },
        ]
      }
      case 'edits': {
        return [
          {
            id: 'isEnable',
            label: '是否啟用',
            rules: [
              {
                required: false,
              }
            ],
            componet: (<JJ_FromSwitch />)
          },
          {
            id: 'beginAt',
            label: '開啟時間',
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <DatePicker picker='date' />
            )
          },
          {
            id: 'endAt',
            label: '停用時間',
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <DatePicker picker='date' />
            )
          },
          {
            id: 'submit',
          },
        ]
      }
      case 'addXlsx': {
        return [
          {
            id: 'remark',
            label: '備註'
          },
          {
            id: 'file',
            label: 'Excel檔案',
            extra: (
              <div>
                <Button
                  style={{ marginTop: 4 }}
                  onClick={() => {
                    window.open(`${config.REACT_APP_API}/v2/admin/replenishment-template?kioskSupplierKey=${this.props.kioskSupplierKey}`)
                  }}
                >
                  {'下載Excel模板'}
                </Button>
              </div>
            ),
            rules: [
              {
                required: true,
                message: `請上傳Excel檔案`,
              }
            ],
            componet: (<JJ_FromUploadXlsx />)
          },
          {
            id: 'submit',
            label: '上傳',
          },
        ]
      }

    }
  }

  render() {
    return (
      <div id="FoodCardEditAndAdd">
        <Spin spinning={this.state.spinLoading}>
          <JJ_From
            formListData={this._formListData()}
            onLoadData={this._onLoadData}
            onFinish={this._onFinish}
          />
        </Spin>
      </div>
    )
  }

}
export default connector(ReplenishRequestImportExcelModal)


