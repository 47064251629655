import React, { } from 'react';
import JJ_From, { FormItemData } from "../../components/JJ_From";
import api, { GetSignupFormParameters, PutSignupFormParameters, PostFoodCardUserMemberFamilyExcelViewParameters, PostFoodCardUserMemberFamilySignupFormParameters, PostFoodCardUserMemberFamilyParameters, GetAreaListParameters, GetFoodCardUserMemberFamilyAreaListParameters, PostFoodCardUserMemberFamilyAreaParameters, GetFoodCardUserMemberFamilyAreaRes, GetFoodCardUserMemberFamilyAreaParameters } from '../../api';
import { Select, Tag, DatePicker, Space, Modal, Input } from 'antd';
import { connect, ConnectedProps } from 'react-redux';
import JJ_FromUploadXlsx from '../../components/JJ_FromUploadXlsx';
import { RcFile } from 'antd/lib/upload';




interface PageState {

}

export type FoodCardUserMemberFamilyImportSaleforceExcelModalPropsType = { type: 'batch-add-excel' }

interface Props {
  onFinish?(props: PageProps, id: string): void
}



interface Page {
  /**
   * 保存数据
   */
  _onFinish(values: any): void

  /**
   * 获取数据
   */
  _onLoadData(): Promise<{}>

  id?: string

}


type PageProps = Props & FoodCardUserMemberFamilyImportSaleforceExcelModalPropsType & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {

}

const mapState = (state: RootState) => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class FoodCardUserMemberFamilyImportSaleforceExcelModal extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);

    this.state = {

    }

  }
  componentDidMount() {
  }





  _postFoodCardUserMemberFamilyExcelView = async (params: PostFoodCardUserMemberFamilyExcelViewParameters) => {
    const res = await api.PostFoodCardUserMemberFamilyExcelView(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _postFoodCardUserMemberFamily = async (param: PostFoodCardUserMemberFamilyParameters) => {
    const res = await api.PostFoodCardUserMemberFamily(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _postFoodCardUserMemberFamilySignupForm = async (params: PostFoodCardUserMemberFamilySignupFormParameters) => {
    const res = await api.PostFoodCardUserMemberFamilySignupForm(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _getSignupForm = async (param: GetSignupFormParameters) => {
    const res = await api.GetSignupForm(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _getFoodCardUserMemberFamilyAreaList = async (param: GetFoodCardUserMemberFamilyAreaListParameters) => {
    const res = await api.GetFoodCardUserMemberFamilyAreaList(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _getFoodCardUserMemberFamilyArea = async (param: GetFoodCardUserMemberFamilyAreaParameters) => {
    const res = await api.GetFoodCardUserMemberFamilyArea(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _postFoodCardUserMemberFamilyArea = async (param: PostFoodCardUserMemberFamilyAreaParameters) => {
    const res = await api.PostFoodCardUserMemberFamilyArea(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }


  _putSignupForm = async (param: PutSignupFormParameters) => {
    const res = await api.PutSignupForm(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }


  _onLoadData = async (): Promise<any> => {


    switch (this.props.type) {
      case 'batch-add-excel': {

        return
      }


    }
  };

  _onFinish = async (values: any): Promise<void> => {

    console.log('_onFinish', this.props.type, values)

    switch (this.props.type) {
      case 'batch-add-excel': {

        const file: RcFile = values.file[0]
        const res = await this._postFoodCardUserMemberFamilyExcelView({
          count: Math.trunc(values.count),
          file: file,
        })

        await new Promise((resolve, reject) => {



          Modal.confirm({
            title: '添加家庭預覽',
            content: (
              <div>
                <div>家庭總數量： <b>{res.totalCount}</b></div>
                <div>添加家庭數量： <b>{res.data.length}</b></div>
                <div>家庭剩餘數量： <b>{res.remainCount}</b></div>
              </div>
            ),
            okText: '確定添加',
            cancelText: '關閉',
            okType: 'danger',
            onOk: async () => {
              try {

                for (const data of res.data) {
                  let area: GetFoodCardUserMemberFamilyAreaRes | undefined
                  const areaText = data.areaText
                  if (areaText) {
                    const areas = await this._getFoodCardUserMemberFamilyAreaList({ count: 10000 })
                    area = areas.data.find(area => area.title === areaText)
                    if (!area) {
                      const postAreaRes = await this._postFoodCardUserMemberFamilyArea({ title: areaText })
                      area = await this._getFoodCardUserMemberFamilyArea({ id: postAreaRes.id })
                    }
                  }

                  const postFamilyRes = await this._postFoodCardUserMemberFamily({
                    jcConsole: data.jcConsole,
                    phone: data.phone,
                    address: data.address,
                    appliedAt: data.appliedAt,
                    remark: data.remark,
                    areaId: area?.id,
                    email: data.email,
                  })
                  const postSignupFormRes = await this._postFoodCardUserMemberFamilySignupForm({
                    id: postFamilyRes.id,
                    name: data.nameTw || data.nameEn || undefined,
                    langs: data.langs,
                  })

                }
                resolve()

              } catch (error) {
                reject(error)

              }
            },
            onCancel: () => {
              resolve()
            },
          });

        })

        this.props.onFinish && this.props.onFinish(this.props, 'xxxx')
      }
        break
    }
  }



  _formListData = (): FormItemData[] => {
    switch (this.props.type) {
      case 'batch-add-excel': {
        return [
          {
            id: 'count',
            label: '添加家庭数量',
            rules: [
              {
                required: true,
                message: `請輸入添加家庭数量`,
              }
            ],
            componet: <Input type='number' placeholder='100' />
          },
          {
            id: 'file',
            label: 'Saleforce導出的xlsx',
            rules: [
              {
                required: true,
                message: `請選擇Saleforce導出的xlsx`,
              }
            ],
            componet: (<JJ_FromUploadXlsx />)
          },
          {
            id: 'submit',
            label: '下一步'
          },
        ]
      }

    }
  }

  render() {
    return (
      <div id="FoodCardUserMemberFamilyImportSaleforceExcelModal">
        <JJ_From
          formListData={this._formListData()}
          onLoadData={this._onLoadData}
          onFinish={this._onFinish}
        />
      </div>
    )
  }

}
export default connector(FoodCardUserMemberFamilyImportSaleforceExcelModal)
