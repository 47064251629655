import React, { } from 'react';
import { Modal, message, Spin, Button, Tag, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import api, { GetSurveySubjectListParameters, DeleteFoodCardUserMemberParameters, GetFoodCardUserMemberFamilyRes, GetFoodMenuRes, GetSurveySubjectRes, DeleteSurveySubjectParameters, GetSurveySubjectParameters, GetSurveySubjectAnswerRes } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import JJ_TableItemAction from '../../components/JJ_TableItemAction';
import JJ_TableItemMoment from '../../components/JJ_TableItemMoment';
import SurveySubjectEditAndAdd, { SurveySubjectEditAndAddPropsType } from './SurveySubjectEditAndAdd';
import { PlusOutlined } from '@ant-design/icons';
import { connect, ConnectedProps } from 'react-redux';
import JJ_TableItemCopyableId from '../../components/JJ_TableItemCopyableId';
import config from '../../config';
import { ActionUserRes } from '../../actions/ActionUser';

const { confirm } = Modal;

type EditAndAddModalType = SurveySubjectEditAndAddPropsType

interface PageState {
  isSpinLoading: boolean
  editAndAddModal: {
    show: boolean
    key: string
  } & EditAndAddModalType
}
interface Props {
  surveyId?: string
}


interface Page {


}


type PageProps = Props & ConnectedProps<typeof connector>

interface RootState {
  user: ActionUserRes

}

const mapState = (state: RootState) => ({
  user: state.user,
})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class SurveySubjectList extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: true,
      editAndAddModal: {
        surveyId: '',
        type: 'add',
        show: false,
        key: new Date().toString(),
      }
    }
  }
  tableRef?: JJ_Table<GetSurveySubjectRes> | null
  componentDidMount() {
    this._initData()
  }
  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true
      })
      await Promise.resolve()
      this.setState({
        isSpinLoading: false
      })
    } catch (error) {
      this.setState({
        isSpinLoading: false
      })
    }
  }


  _deleteSurveySubject = async (params: DeleteSurveySubjectParameters) => {
    const res = await api.DeleteSurveySubject(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _surveySubjectAnswerTable = (data: GetSurveySubjectRes) => {

    const surveySubjectAnswers = data.surveySubjectAnswers || []

    return (
      <JJ_Table<GetSurveySubjectAnswerRes>
        title={`答題`}
        key={`${data.id}${Date.now()}`}
        style={{ padding: 20, backgroundColor: 'rgba(0,0,0,0.1)' }}
        isSearchText={false}
        columns={[

          {
            title: '序列號',
            dataIndex: 'key',
            key: 'key',
            width: 80,
            render: (value, record, index) => String.fromCharCode(0x60 + index + 1).toUpperCase(),
          },

          {
            title: '標題',
            dataIndex: 'title',
            key: 'title',
            render: (value, record) => record.title[this.props.user.data.language]
          },

        ]}
        onDataSource={async (body) => {
          return {
            data: surveySubjectAnswers,
            totalCount: surveySubjectAnswers.length
          }
        }}
        tableProps={{ pagination: false }}

      />
    )
  }

  render() {
    return (
      <div id="SurveySubjectList"
      >
        <Button
          type='primary'
          style={{ marginBottom: 5 }}
          onClick={() => {
            const surveyId = this.props.surveyId
            if (surveyId) {
              this.setState(state => ({
                editAndAddModal: {
                  ...state.editAndAddModal,
                  show: true,
                  key: new Date().toString(),
                  type: 'add',
                  surveyId,
                }
              }))
            }

          }}
          icon={<PlusOutlined />}
        >
          {`添加問卷調查題目`}
        </Button>

        <Modal
          maskClosable={false}
          title={this.state.editAndAddModal.type === 'edit'
            ? '編輯問卷調查題目'
            : '新建問卷調查題目'
          }
          visible={this.state.editAndAddModal.show}
          footer={null}
          onCancel={() => this.setState(state => ({
            editAndAddModal: { ...state.editAndAddModal, show: false }
          }))}
          width={'85%'}
        >
          <SurveySubjectEditAndAdd
            {...this.state.editAndAddModal}
            onFinish={async (props) => {
              this.setState(state => ({
                editAndAddModal: { ...state.editAndAddModal, show: false }
              }))
              switch (props.type) {
                case 'add': {
                  message.success(`添加成功`)
                }
                  break
                case 'edit': {
                  message.success(`編輯成功`)
                }
                  break

              }

              this.tableRef && await this.tableRef.refreshData()


            }}
          />
        </Modal>

        <Spin spinning={this.state.isSpinLoading}>
          {!this.state.isSpinLoading && (
            <JJ_Table<GetSurveySubjectRes, {
              type: GetSurveySubjectListParameters['type']
              optional: undefined | 'false' | 'true'


            }>
              ref={ref => this.tableRef = ref}
              isSearchText={true}
              sourceItems={[
                {
                  type: 'select',
                  defaultValue: undefined,
                  key: 'type',
                  placeholder: '請選擇任意答題類型',
                  allowClear: true,
                  options: [
                    {
                      value: 'SINGLE_CHOICE',
                      name: <Tag color='pink'>單選</Tag>,
                      disabled: false,
                    },
                    {
                      value: 'MULTIPLE_CHOICE',
                      name: <Tag color='yellow'>多選</Tag>,
                      disabled: false,
                    },
                    {
                      value: 'TEXT',
                      name: <Tag color='orange'>文字簡答</Tag>,
                      disabled: false,
                    },
                  ]
                },
                {
                  type: 'select',
                  defaultValue: undefined,
                  key: 'optional',
                  placeholder: '請選擇是否可選',
                  allowClear: true,
                  options: [
                    {
                      value: 'false',
                      name: <Tag color="red">不可選</Tag>,
                      disabled: false,
                    },
                    {
                      value: 'true',
                      name: <Tag color="blue">可選</Tag>,
                      disabled: false,
                    },
                  ]
                },
              ]}
              columns={[
                {
                  key: 'action',
                  width: 50,
                  render: (value: any, record) => {
                    return (
                      <JJ_TableItemAction
                        menus={[
                          {
                            type: 'item',
                            disabled: false,
                            key: 'edit',
                            name: '編輯',
                            onAction: () => {
                              this.setState(state => ({
                                editAndAddModal: { ...state.editAndAddModal, show: true, type: 'edit', id: record.id, key: new Date().toString() }
                              }))
                            }
                          },
                          {
                            type: 'item',
                            disabled: config.REACT_APP_DELETE_DISABLED,
                            key: 'delete',
                            name: '删除',
                            onAction: () => {
                              confirm({
                                title: `是否刪除${record.title}`,
                                icon: <ExclamationCircleOutlined />,
                                okText: 'Yes',
                                okType: 'danger',
                                cancelText: 'No',
                                onOk: async () => {
                                  try {
                                    await this._deleteSurveySubject({ id: record.id })
                                    this.tableRef && this.tableRef.refreshData()
                                    message.success(`${record.title} 删除成功`)
                                  } catch (error) {
                                    message.error(`${record.title} 删除失败 [${error.message}]`);
                                  }
                                },
                                onCancel() {
                                },
                              });
                            }
                          },
                        ]}
                      />
                    )

                  },
                },
                {
                  title: 'ID',
                  dataIndex: 'id',
                  key: 'id',
                  width: 50,
                  render: (value) => (
                    <JJ_TableItemCopyableId
                      text={value}
                    />
                  )
                },

                {
                  title: '序列號',
                  dataIndex: 'key',
                  key: 'key',
                  width: 80,
                  render: (value, record, index) => index + 1,
                },

                {
                  title: '標題',
                  dataIndex: 'title',
                  key: 'title',
                  render: (value, record) => record.title[this.props.user.data.language]
                },


                {
                  title: '是否可選',
                  dataIndex: 'optional',
                  key: 'optional',
                  render: (value, record) => {
                    return (
                      record.optional
                        ? <Tag color="blue">可選</Tag>
                        : <Tag color="red">不可選</Tag>
                    )
                  }
                },

                {
                  title: '答題類型',
                  dataIndex: 'type',
                  key: 'type',
                  render: (value, record) => {

                    switch (record.type) {
                      case 'SINGLE_CHOICE': {
                        return <Tag color='pink'>單選</Tag>
                      }
                      case 'MULTIPLE_CHOICE': {
                        return <Tag color='yellow'>多選</Tag>
                      }
                      case 'TEXT': {
                        return <Tag color='orange'>文字簡答</Tag>
                      }
                    }

                  },
                },
                {
                  title: '創建時間',
                  dataIndex: 'createdAt',
                  key: 'createdAt',
                  render: (value) => (
                    <JJ_TableItemMoment
                      date={value}
                    />
                  )
                },
              ]}
              title={'問卷調查題目列表'}

              expandable={{
                expandedRowRender: (record) => this._surveySubjectAnswerTable(record),
                rowExpandable: (record) => record.surveySubjectAnswers && record.surveySubjectAnswers.length > 0
              }}

              onDataSource={async (body) => {
                const { sourceItemBody } = body
                const params: {
                  type?: GetSurveySubjectListParameters['type']
                  optional?: GetSurveySubjectListParameters['optional']
                } = {}
                if (sourceItemBody) {

                  if (sourceItemBody.type) {
                    params.type = sourceItemBody.type

                  }

                  if (sourceItemBody.optional) {
                    params.optional = sourceItemBody.optional === 'true'
                  }

                }

                const res = await api.GetSurveySubjectList({
                  ...body,
                  ...params,
                  showSurveySubjectAnswers: true,
                  surveyId: this.props.surveyId,
                })
                if (res.kind !== 'ok') throw new Error(res.kind)
                return res.data
              }}


            />
          )}
        </Spin>

      </div >
    )
  }

}
export default connector(SurveySubjectList)

