import React, { } from 'react';
import { Modal, message, Spin, Button, Tag, Transfer, Image } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import JJ_TableTransfer from './JJ_TableTransfer';
import { GetLabelRes } from '../api';
import JJ_Table from './JJ_Table';
import JJ_TableItemCopyableId from './JJ_TableItemCopyableId';
import { ActionUserRes } from '../actions/ActionUser';


interface PageState {

}
export interface Props {
  value?: GetLabelRes[]
  labels: GetLabelRes[]
  onChange?: (value: GetLabelRes[]) => void;
  user: ActionUserRes
}


type PageProps = Props


interface Page {

}

export default class JJ_FromProductLabelTaransfer extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);
    this.state = {

    }
  }

  tableRef?: JJ_Table<GetLabelRes> | null
  componentDidMount() {
  }



  _getColumuns = () => {
    const columns: ColumnsType<GetLabelRes> = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 100,
        render: (value) => (
          <JJ_TableItemCopyableId
            text={value}
          />
        )
      },
      {
        title: '縮略圖',
        dataIndex: '縮略圖',
        key: '縮略圖',
        render: (value, record) => (

          <Image
            width={80}
            src={record.image.url}
            preview={true}
          />
        )
      },
      {
        title: '標題',
        dataIndex: 'title',
        key: 'title',
        width: 120,
        render: (value, record) => record.title
      },

    ]

    return columns
  }

  render() {
    return (

      <JJ_TableTransfer

        transferProps={{
          titles: ['產品標籤庫', '需要添加產品標籤'],
          listStyle: {

          },
          dataSource: this.props.labels.map((label) => ({
            ...label,
            title: label.title[this.props.user.data.language],
            name: label.title[this.props.user.data.language],
            description: '',
            key: label.id,
          })),
          targetKeys: (this.props.value || []).map(item => item.id),
          showSearch: true,
          onChange: targetKeys => this.props.onChange && this.props.onChange(this.props.labels.filter(label => targetKeys.some(key => key === label.id))),
          filterOption: (inputValue, item) => {
            return item.id.indexOf(inputValue) !== -1 || item.name.indexOf(inputValue) !== -1
          }
        }}

        leftColumns={this._getColumuns()}

        rightColumns={this._getColumuns()}

      />

    )
  }

}

