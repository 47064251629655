import React, { } from 'react';
import api, { PostFoodCardDownloadImageParameters } from '../../api';
import { connect, ConnectedProps } from 'react-redux';
import { Spin, Alert, Tree, Modal, message, Button } from 'antd';
import { DataNode } from 'antd/lib/tree';


interface PageState {
  codes: string[]
  createFoodCardLoad: boolean
  selectedKeys: string[] | React.ReactText[]
  checkedKeys: string[] | React.ReactText[]
  expandedKeys: string[] | React.ReactText[]
  autoExpandParent: boolean
  confirmLoading?: boolean
}

interface Props {
  onFinish?(props: PageProps, data: any): void
  title: string
  visible: boolean
  onCancel(): void
}

interface Page {
  /**
   * 保存数据
   */
  _onFinish(values: any): void

  /**
   * 获取数据
   */
  _onLoadData(): Promise<{}>

}


type PageProps = Props & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {

}

const mapState = () => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class FoodCardDownload extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);

    this.state = {
      codes: [],
      createFoodCardLoad: true,
      selectedKeys: [],
      checkedKeys: [],
      expandedKeys: [],
      autoExpandParent: true
    }

  }
  componentDidMount() {
    this._onLoadData()
  }

  _onFinish = async (values: any): Promise<void> => {


  }



  _postFoodCardCeateImage = async () => {
    const res = await api.PostFoodCardCeateImage()
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _postFoodCardDownloadImage = async (params: PostFoodCardDownloadImageParameters) => {
    const res = await api.PostFoodCardDownloadImage(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _onLoadData = async (): Promise<any> => {

    try {
      this.setState({
        createFoodCardLoad: true
      })
      const res = await this._postFoodCardCeateImage()
      this.setState({
        codes: res.data,
        createFoodCardLoad: false
      })
    } catch (error) {
      this.setState({
        createFoodCardLoad: false
      })
      console.log(error)
    }
  };



  _view = () => {


    const treeDataItem: DataNode[] = []

    const treeData = this.state.codes
      .reduce((previousValue, currentValue, currentIndex: number, array: string[]) => {
        const value = currentValue.slice(0, 10)
        if (previousValue.find(item => item.key === value)) {
          //存在
          return previousValue

        } else {
          //不存在

          return [...previousValue,
          {
            title: value,
            key: value,
            children: array.reduce((pv, cv, ci) => {
              if (cv.length === 17 && cv.indexOf(value) !== -1) {
                //存在
                return [...pv, {
                  title: cv,
                  key: cv,
                }]
              } else {
                return pv
              }
            }, treeDataItem)
          }
          ]
        }
      }, treeDataItem)

    return (
      <Tree
        height={window.innerHeight * 0.7}
        checkable
        onExpand={expandedKeys => {
          console.log('onExpand', expandedKeys);
          this.setState({
            expandedKeys: expandedKeys,
            autoExpandParent: false
          })
        }}
        expandedKeys={this.state.expandedKeys}
        autoExpandParent={this.state.autoExpandParent}
        onCheck={(checkedKeys: any) => {
          console.log('onCheck', checkedKeys);
          this.setState({
            checkedKeys,
          })
        }}
        checkedKeys={this.state.checkedKeys}
        onSelect={(selectedKeys, info) => {
          console.log('onSelect', info);
          this.setState({
            selectedKeys,
          })
        }}
        selectedKeys={this.state.selectedKeys}
        treeData={treeData}
      />
    )
  }




  render() {
    return (

      <Modal
        maskClosable={false}
        title={this.props.title}
        visible={this.props.visible}
        onCancel={this.props.onCancel}
        width={'85%'}

        footer={[
          <Button
            key="submit"
            type="primary"
            disabled={this.state.checkedKeys.length <= 0}
            loading={this.state.confirmLoading}
            onClick={async () => {
              try {
                this.setState({
                  confirmLoading: true
                })
                const res = await this._postFoodCardDownloadImage({ foodCardCodes: (this.state.checkedKeys as string[]).filter((item) => item.length === 17) })
                window.open(res.data)
                this.setState({
                  confirmLoading: false
                })
                this.props.onCancel()
              } catch (error) {
                this.setState({
                  confirmLoading: false
                })
              }
            }}
          >
            下載
          </Button>,
        ]}
      >
        <div id="FoodCardDownload"
        >
          <Spin
            spinning={this.state.createFoodCardLoad}
          >
            {this.state.createFoodCardLoad
              ? (
                <Alert
                  message="生成圖片"
                  description='圖片正在生成中，請耐心等待 (過程可能長達幾分鐘)'
                  type="info"
                />
              )
              : this._view()}
          </Spin>
        </div>
      </Modal>
    )
  }

}
export default connector(FoodCardDownload)


