import React, { } from 'react';
import { Tooltip, Button } from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import 'moment/locale/zh-tw';
import Moment from 'react-moment';
import CopyToClipboard from 'react-copy-to-clipboard';
import { NumberOutlined } from '@ant-design/icons';

interface PageState {

}

interface Props {
  text: string
}

type PageProps = Props

interface Page {

}

export default class JJ_TableItemCopyableId extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {

  }



  render() {

    return (
      <CopyToClipboard text={this.props.text}>
        <Tooltip title={`${this.props.text && this.props.text.substr(this.props.text.length - 8, this.props.text.length)}`}>
          <Button type="text" size="small" icon={<NumberOutlined />} />
        </Tooltip>
      </CopyToClipboard>
    )
  }

}
