import React, { } from 'react';
import JJ_From, { FormItemData } from "../../components/JJ_From";
import api, { GetLabelParameters, PutLabelParameters, PostLabelParameters, GetLabelListParameters, GetImageRes } from '../../api';
import { connect, ConnectedProps } from 'react-redux';
import JJ_FromI18nInputText from '../../components/JJ_FromI18nInputText';
import JJ_FromSelectImage from '../../components/JJ_FromSelectImage';

interface PageState {

}


export type LabelEditAndAddPropsType = { type: 'add' } | { type: 'edit', id: string }

interface Props {
  onFinish?(props: PageProps, id: string): void
}



interface Page {
  /**
   * 保存数据
   */
  _onFinish(values: any): void

  /**
   * 获取数据
   */
  _onLoadData(): Promise<{}>

  id?: string

}


type PageProps = Props & LabelEditAndAddPropsType & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {

}

const mapState = (state: RootState) => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class LabelEditAndAdd extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);

    this.state = {

    }

  }
  componentDidMount() {
  }

  _onFinish = async (values: any): Promise<void> => {

    console.log('_onFinish', this.props.type, values)

    switch (this.props.type) {
      case 'edit': {
        const image: GetImageRes = values.image[0]
        const res = await this._putLabel({
          ...values,
          id: this.props.id,
          imageId: image?.id
        })
        this.props.onFinish && this.props.onFinish(this.props, res.id)
      }
        break
      case 'add': {
        const image: GetImageRes = values.image[0]
        const res = await this._postLabel({
          ...values,
          imageId: image.id
        })
        this.props.onFinish && this.props.onFinish(this.props, res.id)
      }
        break
    }
  }

  _onLoadData = async (): Promise<any> => {
    switch (this.props.type) {
      case 'edit': {
        const res = await this._getLabel({ id: this.props.id })
        return {
          ...res,
          image: [res.image],
        }
      }
      case 'add': {

        return {

        }
      }


    }
  };

  _getLabel = async (param: GetLabelParameters) => {
    const res = await api.GetLabel(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _getLabelList = async (param: GetLabelListParameters) => {
    const res = await api.GetLabelList(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _putLabel = async (param: PutLabelParameters) => {
    const res = await api.PutLabel(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }
  _postLabel = async (param: PostLabelParameters) => {
    const res = await api.PostLabel(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }


  _formListData = (): FormItemData[] => {
    switch (this.props.type) {
      case 'edit': {
        return [
          {
            id: 'image',
            label: '圖片',
            rules: [
              {
                required: false,
                message: `請選擇圖片`,
              }
            ],
            componet: (<JJ_FromSelectImage rowSelectionType={'radio'} maxCount={1} />)

          },
          {
            id: 'i18nText',
            name: 'title',
            label: '標題',
            rules: [
              {
                required: false,
                message: `請輸入標題`,
              }
            ],
            componet: (<JJ_FromI18nInputText />)
          },
          {
            id: 'i18nText',
            name: 'description',
            label: '描述',
            rules: [
              {
                required: false,
                message: `請輸入描述`,
              }
            ],
            componet: (<JJ_FromI18nInputText />)
          },

          {
            id: 'order',
            label: '序列',
            rules: [
              {
                required: false,
                message: `請選擇序列`,
              }
            ],

          },
          {
            id: 'submit',
          },
        ]
      }
      case 'add': {

        return [
          {
            id: 'image',
            label: '圖片',
            rules: [
              {
                required: true,
                message: `請選擇圖片`,
              }
            ],
            componet: (<JJ_FromSelectImage rowSelectionType={'radio'} maxCount={1} />)

          },
          {
            id: 'i18nText',
            name: 'title',
            label: '標題',
            rules: [
              {
                required: true,
                message: `請輸入標題`,
              }
            ],
            componet: (<JJ_FromI18nInputText />)
          },
          {
            id: 'i18nText',
            name: 'description',
            label: '描述',
            rules: [
              {
                required: true,
                message: `請輸入描述`,
              }
            ],
            componet: (<JJ_FromI18nInputText />)
          },

          {
            id: 'order',
            label: '序列',
            rules: [
              {
                required: false,
                message: `請選擇序列`,
              }
            ],

          },
          {
            id: 'submit',
          },
        ]
      }


    }
  }

  render() {
    return (
      <div id="LabelEditAndAdd">
        <JJ_From
          formListData={this._formListData()}
          onLoadData={this._onLoadData}
          onFinish={this._onFinish}
        />
      </div>
    )
  }

}
export default connector(LabelEditAndAdd)
