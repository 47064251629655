import React, { } from 'react';
import JJ_From, { FormItemData } from "../../components/JJ_From";
import api, { GetKioskVendingRes, GetKioskVendingListParameters, GetSurveySubjectParameters, PutSurveySubjectParameters, PostSurveySubjectParameters, GetSurveySubjectListParameters, GetSurveySubjectRes, I18n, PostSurveySubjectAnswerParameters, GetSurveySubjectAnswerRes } from '../../api';
import { connect, ConnectedProps } from 'react-redux';
import { DatePicker, Switch, Select, Space, Tag } from 'antd';
import moment from 'moment';
import { FormInstance } from 'antd/lib/form';


interface PageState {
  answerType: GetSurveySubjectRes['type']
}

export type SurveySubjectEditAndAddPropsType = { type: 'add', surveyId: string } | { type: 'edit', id: string }

interface Props {
  onFinish?(props: PageProps, id: string): void
}


type PostFromItemData = PostSurveySubjectParameters & { answerList: I18n[] }
type PutFromItemData = PutSurveySubjectParameters


interface Page {
  /**
   * 保存数据
   */
  _onFinish(values: any): void

  /**
   * 获取数据
   */
  _onLoadData(): Promise<{}>

  id?: string

}


type PageProps = Props & SurveySubjectEditAndAddPropsType & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {

}

const mapState = (state: RootState) => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class SurveySubjectEditAndAdd extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);

    this.state = {
      answerType: 'SINGLE_CHOICE'
    }

  }
  componentDidMount() {
  }

  _onFinish = async (values: any): Promise<void> => {

    console.log('_onFinish', this.props.type, values)

    switch (this.props.type) {
      case 'edit': {
        const editValues: PutFromItemData = values
        const res = await this._putSurveySubject({
          ...editValues,
          id: this.props.id,
        })

        this.props.onFinish && this.props.onFinish(this.props, res.id)
      }
        break
      case 'add': {

        const addValues: PostFromItemData = values

        const res = await this._postSurveySubject({
          ...addValues,
          surveyId: this.props.surveyId,
        })

        if (this.state.answerType !== 'TEXT') {

          const answerList = addValues.answerList
          const postSurveySubjectAnswers = await Promise.all(
            answerList.map(async answer => {
              return await this._postSurveySubjectAnswer({ surveySubjectId: res.id, title: answer })
            })
          )

        }


        this.props.onFinish && this.props.onFinish(this.props, res.id)
      }
        break
    }
  }

  _onLoadData = async (): Promise<any> => {
    switch (this.props.type) {
      case 'edit': {
        const res = await this._getSurveySubject({ id: this.props.id })
        return {
          ...res,
        }
      }
      case 'add': {
        return {
          type: this.state.answerType,
          optional: false,
          answerList: [{
            'zh-tw':'',
            'zh-cn':'',
            'en':'',
          }],
        }
      }


    }
  };

  _getSurveySubject = async (param: GetSurveySubjectParameters) => {
    const res = await api.GetSurveySubject(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _getSurveySubjectList = async (param: GetSurveySubjectListParameters) => {
    const res = await api.GetSurveySubjectList(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _putSurveySubject = async (param: PutSurveySubjectParameters) => {
    const res = await api.PutSurveySubject(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }
  _postSurveySubject = async (param: PostSurveySubjectParameters) => {
    const res = await api.PostSurveySubject(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _postSurveySubjectAnswer = async (param: PostSurveySubjectAnswerParameters) => {
    const res = await api.PostSurveySubjectAnswer(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }



  _getKioskVendingList = async (params: GetKioskVendingListParameters) => {
    const res = await api.GetKioskVendingList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }



  _formListData = (): FormItemData[] => {
    switch (this.props.type) {
      case 'edit': {
        return [
          {
            id: 'i18nText',
            name: 'title',
            label: '標題',
            rules: [
              {
                required: true,
              }
            ],
          },

          {
            id: 'type',
            label: '答題類型',
            rules: [
              {
                required: true,
                message: `請選擇答題類型`,
              }
            ],
            componet: (
              <Select
                filterOption={(input: any, option) => {
                  if (option && option.value) {
                    return String(option.value).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  return false
                }}
              >



                {[
                  { title: '單選', value: 'SINGLE_CHOICE', color: 'pink' },
                  { title: '多選', value: 'MULTIPLE_CHOICE', color: 'yellow' },
                  { title: '文字簡答', value: 'TEXT', color: 'orange' },
                ]
                  .map((item, index) => {
                    return (
                      <Select.Option
                        key={item.value}
                        value={item.value}  >
                        <Space>
                          <Tag color={item.color}>{item.title}</Tag>
                        </Space>
                      </Select.Option>
                    )
                  })
                }
              </Select>
            ),
          },

          {
            props: { valuePropName: 'checked' },
            id: 'optional',
            label: '是否可選',
            rules: [
              {
                required: true,
              }
            ],
            componet: (
              <Switch checked={true} />
            )
          },
          {
            id: 'submit',
          },
        ]
      }
      case 'add': {


        return [

          {
            id: 'i18nText',
            name: 'title',
            label: '標題',
            rules: [
              {
                required: true,
              }
            ],
          },

          {
            id: 'type',
            label: '答題類型',
            rules: [
              {
                required: true,
                message: `請選擇答題類型`,
              }
            ],
            componet: (
              <Select
                filterOption={(input: any, option) => {
                  if (option && option.value) {
                    return String(option.value).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  return false
                }}
              >
                {[
                  { title: '單選', value: 'SINGLE_CHOICE', color: 'pink' },
                  { title: '多選', value: 'MULTIPLE_CHOICE', color: 'yellow' },
                  { title: '文字簡答', value: 'TEXT', color: 'orange' },
                ]
                  .map((item, index) => {
                    return (
                      <Select.Option
                        key={item.value}
                        value={item.value}  >
                        <Space>
                          <Tag color={item.color}>{item.title}</Tag>
                        </Space>
                      </Select.Option>
                    )
                  })
                }
              </Select>
            ),
          },
          ...(this.state.answerType === 'TEXT' ? [] : [{
            id: 'listI18nText',
            name: 'answerList',
            label: '答题',
            rules: [
              {
                required: true,
                message: `请输入答题`,
              }
            ],
          }]),

          {
            props: { valuePropName: 'checked' },
            id: 'optional',
            label: '是否可選',
            rules: [
              {
                required: true,
              }
            ],
            componet: (
              <Switch checked={true} />
            )
          },
          {
            id: 'submit',
          },
        ]
      }


    }
  }

  formRef: (
    FormInstance<PostFromItemData | PutFromItemData> | null
  ) | undefined

  render() {
    return (
      <div id="SurveySubjectEditAndAdd">
        <JJ_From
          formListData={this._formListData()}
          onLoadData={this._onLoadData}
          onFinish={this._onFinish}
          formRef={res => this.formRef = res}
          formProps={{
            onValuesChange: async (value, values: any) => {
              if (this.props.type === 'add') {
                const addValues: PostSurveySubjectParameters = values
                // if (this.state.answerType === 'TEXT') {
                //   this.formRef && this.formRef.setFieldsValue({ answerList: [] })
                // }
                this.setState({
                  answerType: addValues.type
                })
              }
            }
          }}
        />
      </div>
    )
  }

}
export default connector(SurveySubjectEditAndAdd)
