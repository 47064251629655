
import *as store from './ToolStore'
import  http from './http'




export default {
    store,
    http,
}
