import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Spin, Card, Tabs } from 'antd';
import api, { GetKioskSupplierDataRes } from '../../api';
import { connect, ConnectedProps } from 'react-redux';
import { ActionUserRes } from '../../actions/ActionUser';
import ReplenishRequestList from './ReplenishRequestList';


interface PageState {
  isSpinLoading: boolean
  kioskSupplierDatas: GetKioskSupplierDataRes[]
}
interface Props {

}



interface Page {

}


type PageProps = Props & RouteChildrenProps & ConnectedProps<typeof connector>

interface RootState {
  user: ActionUserRes
}

const mapState = (state: RootState) => ({
  user: state.user,
})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class ReplenishRequestListBox extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: true,
      kioskSupplierDatas: [],
    }
  }

  componentDidMount() {
    this._initData()
  }


  _getKioskSupplierList = async () => {
    const res = await api.GetKioskSupplierList()
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true
      })
      const kioskSupplierListRes = await this._getKioskSupplierList()

      this.setState({
        isSpinLoading: false,
        kioskSupplierDatas: kioskSupplierListRes.data
      })
    } catch (error) {
      this.setState({
        isSpinLoading: false
      })
    }
  }
  render() {
    return (
      <div id="ReplenishRequest">



        <Spin spinning={this.state.isSpinLoading}>
          {!this.state.isSpinLoading && (

            <Card>
              <Tabs
                defaultActiveKey="0"
                type='card'
              >
                {this.state.kioskSupplierDatas.map((item, index) => {

                  return (
                    <Tabs.TabPane
                      tab={item.key}
                      key={index}
                    >
                      <ReplenishRequestList
                        {...this.props}
                        kioskSupplierKey={item.key}
                      />
                    </Tabs.TabPane>
                  )
                })}
              </Tabs>
            </Card>

          )}
        </Spin>

      </div>
    )
  }

}
export default connector(ReplenishRequestListBox)



