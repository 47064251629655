import React, { } from 'react';
import JJ_From, { FormItemData } from "../../components/JJ_From";
import api, { GetAreaRes, GetAreaListParameters } from '../../api';
import { connect, ConnectedProps } from 'react-redux';


interface PageState {

}

type Type = { type: 'add' } | { type: 'edit', id: string }

interface Props {
  onFinish?(props: PageProps, id: string): void
}



interface Page {
  /**
   * 保存数据
   */
  _onFinish(values: any): void

  /**
   * 获取数据
   */
  _onLoadData(): Promise<{}>

  id?: string

}


type PageProps = Props & Type & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {

}

const mapState = (state: RootState) => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class PartnerCompanyEditAndAdd extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);

    this.state = {

    }

  }
  componentDidMount() {
  }

  _onFinish = async (values: any): Promise<void> => {

    switch (this.props.type) {
      case 'edit': {
        const res = await api.PutPartnerCompany({
          ...values,
          id: this.props.id,
        })
        if (res.kind !== 'ok') throw Error(res.kind)
        this.props.onFinish && this.props.onFinish(this.props, res.data.id)
      }
        break
      case 'add': {

        const res = await api.PostPartnerCompany({
          ...values,
        })
        if (res.kind !== 'ok') throw Error(res.kind)
        this.props.onFinish && this.props.onFinish(this.props, res.data.id)
      }
        break
    }
  }


  _onLoadData = async (): Promise<any> => {



    switch (this.props.type) {
      case 'edit': {
        const res = await api.GetPartnerCompany({ id: this.props.id })

        if (res.kind === 'ok') {
          return res.data
        }
        throw Error(res.kind)
      }
      case 'add': {

        return
      }

    }
  };

  _formListData = (): FormItemData[] => {
    switch (this.props.type) {
      case 'edit': {
        return [
          {
            id: 'code',
            label: '代號',
            rules: [
              {
                required: false,
                message: `請輸入3字元代號例如：SP1`,
                min: 3,
                max: 3,
              }
            ]
          },
          {
            id: 'name',
            label: '名稱',
            rules: [
              {
                required: false,
                message: `請輸入名稱`
              }
            ]
          },
          {
            id: 'submit',
          },
        ]
      }
      case 'add': {
        return [
          {
            id: 'code',
            label: '代號',
            rules: [
              {
                required: true,
                message: `請輸入3字代號例如：SP1`,
                min: 3,
                max: 3,
              }
            ]
          },
          {
            id: 'name',
            label: '名稱',
            rules: [
              {
                required: true,
                message: `請輸入名稱`
              }
            ]
          },
          {
            id: 'submit',
          },
        ]
      }

    }
  }

  render() {
    return (
      <div id="PartnerCompanyEditAndAdd">
        <JJ_From
          formListData={this._formListData()}
          onLoadData={this._onLoadData}
          onFinish={this._onFinish}
        />
      </div>
    )
  }

}
export default connector(PartnerCompanyEditAndAdd)
