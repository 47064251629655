import React, { } from 'react';
import { Tag, Modal, Button, Input, message, Spin } from 'antd';
import api, { GetStatisticKioskVendingMemberMonthStatsParameters, GetStatisticKioskVendingMemberMonthStatsDataRes } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import moment from 'moment';
import JJ_TableItemCopyableId from '../../components/JJ_TableItemCopyableId';
import FoodCardDetailsModal from '../foodCard/FoodCardDetailsModal';
import JJ_TableItemAction from '../../components/JJ_TableItemAction';

interface PageState {
  isSpinLoading: boolean
  foodCardDetailsModal: {
    show?: boolean
    key?: any
    foodCardCode: string
  }
}

interface Props {
  kioskVendingId: string
  title: string
  visible?: boolean
  onCancel(): void
  beginAt: moment.Moment

}


interface Page {

}


type PageProps = Props


class KioskVendingMemberMonthStatsModal extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);

    this.state = {
      isSpinLoading: true,
      foodCardDetailsModal: {
        foodCardCode: '',
      },
    }

  }
  componentDidMount() {
    this._onLoadData()
  }



  _onLoadData = async (): Promise<any> => {
    this.setState({
      isSpinLoading: false
    })
  };

  tableRef?: JJ_Table<GetStatisticKioskVendingMemberMonthStatsDataRes & { id: string }> | null


  _view = () => {
    return (
      <div>
        {this.state.foodCardDetailsModal.key && (
          <FoodCardDetailsModal
            key={this.state.foodCardDetailsModal.key}
            title={'食品卡詳情頁'}
            visible={!!this.state.foodCardDetailsModal.show}
            onCancel={() => this.setState({ foodCardDetailsModal: { ...this.state.foodCardDetailsModal, show: false } })}
            foodCardCode={this.state.foodCardDetailsModal.foodCardCode}
          />
        )}

        <JJ_Table<GetStatisticKioskVendingMemberMonthStatsDataRes & { id: string }, {
          desc: 'ascend' | 'descend'
          beginAt: moment.Moment
          active: boolean
        }>
          ref={ref => this.tableRef = ref}
          isSearchText={false}
          tableProps={{
            scroll: {
              y: '50vh',
            }
          }}
          sourceItems={[
            {
              placeholder: '可以選擇任意活躍度',
              type: 'select',
              defaultValue: undefined,
              key: 'active',
              allowClear: true,
              options: [
                {
                  value: 1,
                  name: '只顯示活躍會員',
                  disabled: false,
                },
                {
                  value: 0,
                  name: '只顯示不活躍會員 (<30%)',
                  disabled: false,
                },
              ]
            },
            {
              type: 'datePicker',
              key: 'beginAt',
              defaultValue: this.props.beginAt,
              props: {
                picker: 'month',
                disabledDate: (current) => current && current > moment().add('day', 1).startOf('day'),
              }
            },
          ]}
          columns={[
            {
              key: 'action',
              width: 50,
              render: (value: any, record) => {
                return (
                  <JJ_TableItemAction
                    menus={[
                      {
                        type: 'item',
                        disabled: false,
                        key: 'showDetails',
                        name: '顯示詳情',
                        onAction: () => {
                          this.setState(state => ({
                            foodCardDetailsModal: { ...state.foodCardDetailsModal, show: true, key: new Date().toString(), foodCardCode: record.foodCard.code }
                          }))
                        }
                      },
                    ]}
                  />
                )

              },
            },
            {
              title: 'ID',
              dataIndex: ['foodCard', 'id'],
              key: 'foodCardId',
              render: (value) => (
                <JJ_TableItemCopyableId
                  text={value}
                />
              )
            },
            {
              title: '代號',
              dataIndex: ['foodCard', 'code'],
              key: 'foodCardCode',
            },
            {
              title: '平均每日取餐數量',
              dataIndex: 'averageDayCount',
              key: 'averageDayCount',
              render: (value, recode) => {
                const rate = recode.averageDayCount
                return Number((rate).toFixed(2))
              },
            },
            {
              title: '每月取餐數量',
              dataIndex: 'monthCount',
              key: 'monthCount',
              render: (value, recode, index) => `${recode.averageDayCount * recode.monthMaxCount}/${recode.monthMaxCount}`,
            },
            {
              title: '每月取餐率',
              dataIndex: 'monthCountRate',
              key: 'monthCountRate',
              render: (value, recode) => {
                const rate = recode.averageDayCount
                return (
                  <span style={{
                    color: rate > 0.8
                      ? 'green'
                      : rate > 0.3
                        ? 'orange'
                        : 'red'
                  }}>
                    {Number((rate * 100).toFixed(2))}%
                  </span>
                )
              },
            },
          ]}
          title={this.props.title}
          onDataSource={async (body) => {
            const { sourceItemBody } = body
            const params: {
              scope?: GetStatisticKioskVendingMemberMonthStatsParameters['scope']
              beginAt?: GetStatisticKioskVendingMemberMonthStatsParameters['beginAt']
              active?: GetStatisticKioskVendingMemberMonthStatsParameters['active']
            } = {}
            if (sourceItemBody) {
              if (sourceItemBody.beginAt) {
                params.beginAt = moment(sourceItemBody.beginAt).startOf('day').toISOString()
              }

              params.active = sourceItemBody.active === undefined ? undefined : Boolean(sourceItemBody.active)

            }
            const res = await api.GetStatisticKioskVendingMemberMonthStats({
              ...body,
              ...params,
              kioskVendingId: this.props.kioskVendingId,
            })
            if (res.kind !== 'ok') throw new Error(res.kind)
            const resData = res.data
            return {
              data: resData.data.map(item => ({ ...item, id: item.foodCard.id })),
              totalCount: resData.totalCount,
            }
          }}
        />
      </div>
    )
  }

  render() {

    return (
      <div id="KioskVendingMemberMonthStatsModal">
        <Modal
          footer={null}
          maskClosable={false}
          title={null}
          visible={this.props.visible}
          onCancel={this.props.onCancel}
          width={'85%'}
        >
          <Spin spinning={this.state.isSpinLoading}>
            {!this.state.isSpinLoading && this._view()}
          </Spin>

        </Modal>
      </div>
    )
  }

}
export default KioskVendingMemberMonthStatsModal


