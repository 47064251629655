import React, { } from 'react';
import { Tooltip, Button } from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import 'moment/locale/zh-tw';
import Moment from 'react-moment';
import CopyToClipboard from 'react-copy-to-clipboard';

interface PageState {

}

interface Props {
  text: string
  from?: number
  length?: number
}

type PageProps = Props

interface Page {

}

export default class JJ_TableItemCopyableText extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {

  }



  render() {

    return (
      <CopyToClipboard text={this.props.text}>
        <Tooltip title={`點擊複製: ${this.props.text}`}>
          <Button type="dashed" style={{ width: '100%' }}>
            {this.props.text && this.props.text.substr(this.props.from || 0, this.props.length || 8)}
          </Button>
        </Tooltip>
      </CopyToClipboard>
    )
  }

}
