import React, { } from 'react';
import { Tag, Modal, Button } from 'antd';
import JJ_Table from '../../components/JJ_Table';
import { GetFoodCardGroupXlsxPreviewRes } from '../../api';
import JJ_TableItemCopyableText from '../../components/JJ_TableItemCopyableText';


interface PageState {

}

interface Props {
  title: string
  foodCardGroupXlsxPreviews: GetFoodCardGroupXlsxPreviewRes[]
  visible: boolean
  onCancel(): void
  onFinish(res: GetFoodCardGroupXlsxPreviewRes[]): void
}



interface Page {

  tableRef?: JJ_Table<GetFoodCardGroupXlsxPreviewRes & { id: string }> | null

}


type PageProps = Props



class FoodCardGroupXlsxPreViewModal extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);

    this.state = {

    }

  }
  componentDidMount() {
  }

  tableRef?: JJ_Table<GetFoodCardGroupXlsxPreviewRes & { id: string }> | null

  _tableView = () => {
    return (
      <JJ_Table<GetFoodCardGroupXlsxPreviewRes & { id: string }>

        tableProps={{
          pagination: false,
          scroll: {
            y: window.innerHeight * 0.6,
          }
        }}
        ref={ref => this.tableRef = ref}
        isSearchText={true}
        columns={[

          {
            width: 120,
            title: '家庭號',
            dataIndex: ['foodCardUserMemberFamily', 'foodCardCode'],
            key: 'foodCardUserMemberFamily.foodCardCode',
          },
          {
            width: 120,
            title: '電話號碼',
            dataIndex: 'foodCardUserMemberFamily.phone',
            key: 'foodCardUserMemberFamily.phone',
            render: (value, record) => {
              if (!record.foodCardUserMemberFamily) {
                return
              }
              const [areaCode, phoneCode] = String(record.foodCardUserMemberFamily.phone).split('-')
              if (phoneCode) {
                return phoneCode
              }
            }
          },
          {
            width: 120,
            title: '八達通號',
            dataIndex: 'externalId',
            key: 'externalId',
            render: (value) => (
              <JJ_TableItemCopyableText
                text={value}
              />
            )
          },

          {
            width: 60,
            title: '第三方卡類別',
            dataIndex: 'type',
            key: 'type',
            render: (value) => (
              <Tag color='pink'>{value}</Tag>
            )
          },

          {
            title: '是否啟用',
            width: 100,
            dataIndex: 'isEnable',
            key: 'isEnable',
            render: (value, record) => (
              record.isEnable
                ? <Tag color="blue">啟用</Tag>
                : <Tag color="red">停用</Tag>
            )
          },
          {
            width: 180,
            title: '錯誤描述',
            dataIndex: 'codeError',
            key: 'codeError',
            render: (value) => value ? <Tag color='error'>{value}</Tag> : `--`
          },
        ]}
        title={'食品卡清單'}
        onDataSource={async (body) => {

          const data = await Promise.resolve(this.props.foodCardGroupXlsxPreviews)
          return {
            data: data
              .map((item, index: number) => ({ ...item, id: String(index) }))
              .sort((a, b) => a.codeError ? -1 : 1)
              .filter(item => {
                const searchText = body.searchText || ''
                if (searchText) {
                  const codeError = item.codeError || ''
                  return String(codeError).indexOf(searchText) !== -1
                    || String(item.externalId).indexOf(searchText) !== -1
                    || String(item.foodCardUserMemberFamily?.phone).indexOf(searchText) !== -1
                } else {
                  return true
                }
              })
            ,
            totalCount: 0,
          }
        }}
      />
    )
  }


  render() {
    return (
      <div id="FoodCardGroupXlsxPreViewModal">
        <Modal
          maskClosable={false}
          title={this.props.title}
          visible={this.props.visible}
          footer={[
            <Button key="submit"
              type="primary"
              disabled={this.props.foodCardGroupXlsxPreviews.some(item => item.codeError) || this.props.foodCardGroupXlsxPreviews.length <= 0}
              onClick={() => this.props.onFinish(this.props.foodCardGroupXlsxPreviews)}>
              批量生成
            </Button>,
          ]}
          onCancel={this.props.onCancel}
          width={'85%'}
        >
          {this._tableView()}
        </Modal>
      </div>
    )
  }

}
export default FoodCardGroupXlsxPreViewModal


