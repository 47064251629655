
const userType = {
    INIT_DATA: '【用户】初始化数据',
    UP_DATE: '【用户】更新数据',
    GET_DATA: '【用户】获取数据',
    GET_PERMISSION_DATA: '【用户】获取用户权限数据',
}

const configType = {
    INIT_DATA: '【配置】初始化数据',
    UP_DATE: '【配置】更新数据',
}

export default {
    userType,
    configType,
}