import React, { } from 'react';
import api, { PostFoodCardDownloadImageParameters, GetFoodCardRes, GetFoodCardListParameters, GetFoodCardRecordRes, GetFoodCardRecordListParameters, GetKioskVendingRes, GetKioskVendingListParameters } from '../../api';
import { connect, ConnectedProps } from 'react-redux';
import { Spin, Alert, Tree, Modal, message, Button, Layout, Carousel, Tag } from 'antd';
import JJ_Table from '../../components/JJ_Table';
import JJ_TableItemAction from '../../components/JJ_TableItemAction';
import JJ_TableItemCopyableId from '../../components/JJ_TableItemCopyableId';
import JJ_TableItemCopyableText from '../../components/JJ_TableItemCopyableText';
import JJ_TableItemMoment from '../../components/JJ_TableItemMoment';
import config from '../../config';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import FoodCardRecordEditAndAdd from '../foodCardRecord/FoodCardRecordEditAndAdd';
import moment from 'moment';

const { confirm } = Modal;

type EditAndAddModalType = { type: 'edit', id: string }

interface PageState {
  spinning: boolean
  editAndAddModal: {
    show: boolean
    key: string
  } & EditAndAddModalType
}

interface Props {
  foodCard: GetFoodCardRes
}

interface Page {

  /**
   * 获取数据
   */
  _onLoadData(): Promise<{}>
  tableRef?: JJ_Table<GetFoodCardRecordRes> | null

}


type PageProps = Props & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {

}

const mapState = () => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class FoodCardDetailsRecordList extends React.Component<PageProps, PageState> implements Page {
  tableRef?: JJ_Table<GetFoodCardRecordRes> | null

  constructor(props: Readonly<PageProps>) {
    super(props);

    this.state = {
      spinning: true,
      editAndAddModal: {
        type: 'edit',
        id: '',
        show: false,
        key: new Date().toString()
      },
    }

  }
  componentDidMount() {
    this._onLoadData()
  }


  _getFoodCardList = async (params: GetFoodCardListParameters) => {
    const res = await api.GetFoodCardList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _onLoadData = async (): Promise<any> => {

    try {
      this.setState({
        spinning: true
      })

      this.setState({
        spinning: false,
      })
    } catch (error) {
      this.setState({
        spinning: false
      })
      console.log(error)
    }
  };


  render() {
    return (

      <div id="FoodCardDetailsRecordList"
      >
        <Spin
          spinning={this.state.spinning}
        >

          <Modal
            maskClosable={false}
            title={this.state.editAndAddModal.type === 'edit' ? '編輯食品卡取餐記錄' : '新建食品卡取餐記錄'}
            visible={this.state.editAndAddModal.show}
            footer={null}
            onCancel={() => this.setState(state => ({
              editAndAddModal: { ...state.editAndAddModal, show: false }
            }))}
            width={'85%'}
          >
            <FoodCardRecordEditAndAdd
              {...this.state.editAndAddModal}
              onFinish={async (props) => {
                this.setState(state => ({
                  editAndAddModal: { ...state.editAndAddModal, show: false }
                }))
                switch (props.type) {
                  case 'edit': {
                    this.tableRef && await this.tableRef.refreshData()
                    message.success(`編輯成功`)
                  }
                }

              }}
            />
          </Modal>


          {!this.state.spinning && (
            <JJ_Table<GetFoodCardRecordRes, {
              desc: 'ascend' | 'descend'
              status: 'NORMAL' | 'ERROR'
              rangePicker?: [moment.Moment, moment.Moment] | null

            }>
              ref={ref => this.tableRef = ref}
              isSearchText={false}
              sourceItems={[
                {
                  type: 'select',
                  defaultValue: 'ascend',
                  key: 'desc',
                  options: [
                    {
                      value: 'ascend',
                      name: '升序',
                      disabled: false,
                    },
                    {
                      value: 'descend',
                      name: '降序',
                      disabled: false,
                    },
                  ]
                },
                {
                  type: 'select',
                  defaultValue: undefined,
                  key: 'status',
                  placeholder: '請選擇任意狀態',
                  allowClear: true,
                  options: [
                    {
                      value: 'NORMAL',
                      name: <Tag color={'blue'}>正常</Tag>,
                      disabled: false,
                    },
                    {
                      value: 'ERROR',
                      name: <Tag color={'error'}>出貨失敗</Tag>,
                      disabled: false,
                    },
                  ]
                },
                {
                  type: 'rangePicker',
                  key: 'rangePicker',
                  props: {
                  }
                },
              ]}
              columns={[
                {
                  key: 'action',
                  width: 50,
                  render: (value: any, record) => {
                    return (
                      <JJ_TableItemAction
                        menus={[
                          {
                            type: 'item',
                            disabled: false,
                            key: 'edit',
                            name: '編輯',
                            onAction: () => {
                              this.setState(state => ({
                                editAndAddModal: { ...state.editAndAddModal, show: true, type: 'edit', id: record.id, key: new Date().toString() }
                              }))
                            }
                          },
                          {
                            type: 'item',
                            disabled: config.REACT_APP_DELETE_DISABLED,
                            key: 'delete',
                            name: '刪除',
                            onAction: () => {
                              confirm({
                                title: `是否删除${(record.foodCard as GetFoodCardRes).code}`,
                                icon: <ExclamationCircleOutlined />,
                                okText: 'Yes',
                                okType: 'danger',
                                cancelText: 'No',
                                onOk: async () => {
                                  try {
                                    await api.DeleteFoodCardRecord({ id: record.id })
                                    this.tableRef && this.tableRef.refreshData()
                                    message.success(`${(record.foodCard as GetFoodCardRes).code} 删除成功`)
                                  } catch (error) {
                                    message.error(`${(record.foodCard as GetFoodCardRes).code} 删除失败 [${error.message}]`);
                                  }
                                },
                                onCancel() {
                                }
                              })
                            }
                          },
                        ]}
                      />
                    )

                  },
                },
                {
                  title: 'ID',
                  dataIndex: 'id',
                  key: 'id',
                  width: 130,
                  render: (value) => (
                    <JJ_TableItemCopyableId
                      text={value}
                    />
                  )
                },
                {
                  title: '食品卡號',
                  dataIndex: ['foodCard', 'code'],
                  key: 'foodCardCode',
                  width: 180,
                },
                {
                  title: '訂單ID',
                  dataIndex: 'orderId',
                  key: 'orderId',
                  width: 130,
                  render: (value) => (
                    <JJ_TableItemCopyableText
                      text={value}
                    />
                  )
                },
                {
                  title: '機器ID',
                  dataIndex: ['kioskVending', 'kioskId'],
                  key: 'kioskVendingKioskId',
                  width: 150,
                  render: (value) => (
                    <JJ_TableItemCopyableText
                      text={value}
                    />
                  )
                },
                {
                  title: '機器名称',
                  dataIndex: ['kioskVending', 'name'],
                  key: 'kioskVendingKioskName',
                  render: (value) => (<Tag color={'orange'}>{value}</Tag>)
                },
                {
                  title: '出貨失敗資訊',
                  dataIndex: ['errorText'],
                  key: 'errorText',
                  render: (value) => (
                    value ? <Tag color='error'>{value}</Tag> : `--`
                  )
                },

                {
                  title: '創建時間',
                  dataIndex: 'createdAt',
                  key: 'createdAt',
                  render: (value) => (
                    <JJ_TableItemMoment
                      date={value}
                    />
                  )
                },
              ]}
              title={'食品卡取餐記錄清單'}
              onDataSource={async (body) => {
                const { sourceItemBody } = body
                const params: {
                  isAscend?: GetFoodCardRecordListParameters['isAscend']
                  status?: GetFoodCardRecordListParameters['status']
                  scope?: GetFoodCardRecordListParameters['scope']
                  beginAt?: GetFoodCardRecordListParameters['beginAt']
                  endAt?: GetFoodCardRecordListParameters['endAt']
                } = {}
                if (sourceItemBody) {
                  if (sourceItemBody.desc) {
                    params.isAscend = sourceItemBody.desc === 'ascend' ? true : false
                  }
                  params.status = sourceItemBody.status

                  const rangePicker = sourceItemBody.rangePicker
                  if (rangePicker && rangePicker.length === 2) {
                    const [beginAtMoment, endAtMoment,] = rangePicker
                    params.beginAt = moment(beginAtMoment).startOf('day').toISOString()
                    params.endAt = moment(endAtMoment).endOf('day').toISOString()
                  }

                }
                params.scope = ['foodCard', 'kioskVending', 'area']
                const res = await api.GetFoodCardRecordList({
                  ...body,
                  ...params,
                  foodCardId: this.props.foodCard.id,
                })
                if (res.kind !== 'ok') throw new Error(res.kind)
                return res.data
              }}
              tableProps={{
                scroll: {
                  y: 320,
                  x: 1200,
                },
              }}
            />
          )}

        </Spin>
      </div>
    )
  }

}
export default connector(FoodCardDetailsRecordList)


