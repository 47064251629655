import React, { } from 'react';
import JJ_From, { FormItemData } from "../../components/JJ_From";
import api, { GetFoodCardUserMemberFamilyParameters, PutFoodCardUserMemberFamilyParameters, PostFoodCardUserMemberFamilyParameters, GetKioskVendingRes, GetKioskVendingListParameters, PostFoodCardUserMemberFamilyChangeCodeParameters, GetFoodCardUserMemberFamilyAreaRes, GetFoodCardUserMemberFamilyAreaListParameters } from '../../api';
import { Select, Tag, DatePicker, Space } from 'antd';
import { connect, ConnectedProps } from 'react-redux';
import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';


interface PageState {
  kioskVendings: GetKioskVendingRes[]
  memberFamilyAreas: GetFoodCardUserMemberFamilyAreaRes[]
}

type Type = { type: 'add' } | { type: 'edit', id: string }

interface Props {
  onFinish?(props: PageProps, id: string): void
}



interface Page {
  /**
   * 保存数据
   */
  _onFinish(values: any): void

  /**
   * 获取数据
   */
  _onLoadData(): Promise<{}>

  id?: string

}


type PageProps = Props & Type & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {

}

const mapState = (state: RootState) => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class FoodCardUserMemberFamilyEditAndAdd extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);

    this.state = {
      kioskVendings: [],
      memberFamilyAreas: [],
    }

  }
  componentDidMount() {
  }

  _onFinish = async (values: any): Promise<void> => {

    console.log('_onFinish', this.props.type, values)

    switch (this.props.type) {
      case 'edit': {

        const beginAt = values.beginAt ? moment(values.beginAt).startOf('day') : undefined
        const endAt = values.endAt ? moment(values.endAt).endOf('day') : undefined

        const res = await this._putFoodCardUserMemberFamily({
          ...values,
          id: this.props.id,
          phone: `+852-${values.phone}`,
          appliedAt: values.appliedAt ? new Date(values.appliedAt).toISOString() : undefined,
          beginAt,
          endAt,
        })

        this.props.onFinish && this.props.onFinish(this.props, res.id)
      }
        break
      case 'add': {
        const beginAt = values.beginAt ? moment(values.beginAt).startOf('day') : undefined
        const endAt = values.endAt ? moment(values.endAt).endOf('day') : undefined
        const res = await this._postFoodCardUserMemberFamily({
          ...values,
          phone: `+852-${values.phone}`,
          appliedAt: values.appliedAt ? new Date(values.appliedAt).toISOString() : undefined,
          beginAt,
          endAt,
        })

        if (values.kioskVendingId) {
          await this._postFoodCardUserMemberFamilyChangeCode({ foodCardUserMemberFamilyId: res.id, kioskVendingId: values.kioskVendingId })
        }

        this.props.onFinish && this.props.onFinish(this.props, res.id)
      }
        break
    }
  }

  _postFoodCardUserMemberFamilyChangeCode = async (params: PostFoodCardUserMemberFamilyChangeCodeParameters) => {
    const res = await api.PostFoodCardUserMemberFamilyChangeCode(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getFoodCardUserMemberFamilyAreaList = async (params: GetFoodCardUserMemberFamilyAreaListParameters) => {
    const res = await api.GetFoodCardUserMemberFamilyAreaList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _onLoadData = async (): Promise<any> => {


    const familyAreaListRes = await this._getFoodCardUserMemberFamilyAreaList({ count: 1000 })

    this.setState({
      memberFamilyAreas: familyAreaListRes.data
    })


    switch (this.props.type) {
      case 'edit': {
        const res = await this._getFoodCardUserMemberFamily({ id: this.props.id })

        const appliedAt = res.appliedAt ? moment(res.appliedAt) : res.appliedAt

        const [areaCode, phone] = String(res.phone).split('-')

        const beginAt = res.beginAt ? moment(res.beginAt) : res.beginAt
        const endAt = res.endAt ? moment(res.endAt) : res.endAt

        return {
          ...res,
          appliedAt,
          phone,
          areaId: res.area,
          beginAt,
          endAt,
        }
      }
      case 'add': {

        const kioskVendingList = await this._getKioskVendingList({ count: 10000 })

        this.setState({
          kioskVendings: kioskVendingList.data
        })

        return
      }


    }
  };

  _getFoodCardUserMemberFamily = async (param: GetFoodCardUserMemberFamilyParameters) => {
    const res = await api.GetFoodCardUserMemberFamily(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _putFoodCardUserMemberFamily = async (param: PutFoodCardUserMemberFamilyParameters) => {
    const res = await api.PutFoodCardUserMemberFamily(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }
  _postFoodCardUserMemberFamily = async (param: PostFoodCardUserMemberFamilyParameters) => {
    const res = await api.PostFoodCardUserMemberFamily(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }


  _getKioskVendingList = async (params: GetKioskVendingListParameters) => {
    const res = await api.GetKioskVendingList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }



  _formListData = (): FormItemData[] => {
    switch (this.props.type) {
      case 'edit': {
        return [
          {
            id: 'jcConsole',
            label: 'JcConsole',
            rules: [
              {
                required: false,
                message: `請輸入 JcConsole`
              }
            ]
          },
          {
            id: 'phone',
            label: '手機號碼',
            rules: [
              {
                required: false,
                message: `請輸入 手機號碼`
              }
            ],
          },
          {
            id: 'address',
            label: '用戶地址',
            rules: [
              {
                required: false,
                message: `請輸入 用戶地址`
              }
            ],
          },
          {
            id: 'coordinator',
            label: '社工',
            rules: [
              {
                required: false,
                message: `請輸入 社工`
              }
            ],
          },
          {
            id: 'phase',
            label: '版本號',
            rules: [
              {
                required: false,
                message: `請選擇 版本號`
              }
            ],
            componet: (
              <Select
                showSearch={true}
                filterOption={(input: any, option) => {
                  if (option && option.children && typeof (option.children) === 'string') {
                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  return false
                }}
              >
                {['1.0', '2.0'].map(item => {
                  return (
                    <Select.Option
                      key={item}
                      value={item}
                    >
                      {item}
                    </Select.Option>
                  )
                })}
              </Select>
            ),
          },

          {
            id: 'areaId',
            label: '用戶家庭區域',
            rules: [
              {
                required: false,
                message: `請選擇 用戶家庭區域`
              }
            ],
            componet: (
              <Select
                showSearch={true}
                filterOption={(input: any, option) => {
                  if (option && option.children && typeof (option.children) === 'string') {
                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  return false
                }}
              >
                {this.state.memberFamilyAreas.map(item => {
                  return (
                    <Select.Option
                      key={item.id}
                      value={item.id}
                    >
                      {item.title}
                    </Select.Option>
                  )
                })}
              </Select>
            ),
          },

          {
            id: 'appliedAt',
            label: '登記日期',
            rules: [
              {
                required: false,
                message: `請輸入 登記日期`
              }
            ],
            componet: (
              <DatePicker picker='date' />
            )
          },

          {
            id: 'whatsapp',
            label: 'whatsapp',
            rules: [
              {
                required: false,
                message: `請輸入 whatsapp`
              }
            ],
          },


          {
            id: 'remark',
            label: '備註',
            rules: [
              {
                required: false,
                message: `請輸入 備註`
              }
            ],
            componet: (
              < TextArea
                style={{
                  height: 150,
                }}
              />
            )
          },
          {
            id: 'beginAt',
            label: '啟用時間',
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <DatePicker picker='date' />
            )
          },
          {
            id: 'endAt',
            label: '停用時間',
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <DatePicker picker='date' />
            )
          },
          {
            id: 'submit',
          },
        ]
      }
      case 'add': {
        return [

          {
            id: 'jcConsole',
            label: 'JcConsole',
            rules: [
              {
                required: false,
                message: `請輸入 JcConsole`
              }
            ]
          },
          {
            id: 'phone',
            label: '手機號碼',
            rules: [
              {
                required: true,
                message: `請輸入 手機號碼`
              }
            ],
          },
          {
            id: 'address',
            label: '用戶地址',
            rules: [
              {
                required: false,
                message: `請輸入 用戶地址`
              }
            ],
          },
          {
            id: 'coordinator',
            label: '社工',
            rules: [
              {
                required: false,
                message: `請輸入 社工`
              }
            ],
          },

          {
            id: 'phase',
            label: '版本號',
            rules: [
              {
                required: false,
                message: `請輸入 版本號`
              }
            ],
            componet: (
              <Select
                showSearch={true}
                filterOption={(input: any, option) => {
                  if (option && option.children && typeof (option.children) === 'string') {
                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  return false
                }}
              >
                {['1.0', '2.0'].map(item => {
                  return (
                    <Select.Option
                      key={item}
                      value={item}
                    >
                      {item}
                    </Select.Option>
                  )
                })}
              </Select>
            ),
          },

          {
            id: 'areaId',
            label: '用戶家庭區域',
            rules: [
              {
                required: false,
                message: `請選擇 用戶家庭區域`
              }
            ],
            componet: (
              <Select
                showSearch={true}
                filterOption={(input: any, option) => {
                  if (option && option.children && typeof (option.children) === 'string') {
                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  return false
                }}
              >
                {this.state.memberFamilyAreas.map(item => {
                  return (
                    <Select.Option
                      key={item.id}
                      value={item.id}
                    >
                      {item.title}
                    </Select.Option>
                  )
                })}
              </Select>
            ),
          },

          {
            id: 'appliedAt',
            label: '登記日期',
            rules: [
              {
                required: false,
                message: `請輸入 登記日期`
              }
            ],
            componet: (
              <DatePicker picker='date' />
            )
          },
          {
            id: 'whatsapp',
            label: 'whatsapp',
            rules: [
              {
                required: false,
                message: `請輸入 whatsapp`
              }
            ],
          },

          {
            id: 'remark',
            label: '備註',
            rules: [
              {
                required: false,
                message: `請輸入 備註`
              }
            ],
            componet: (
              < TextArea
                style={{
                  height: 150,
                }}
              />
            ),
          },


          {
            id: 'beginAt',
            label: '啟用時間',
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <DatePicker picker='date' />
            )
          },
          {
            id: 'endAt',
            label: '停用時間',
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <DatePicker picker='date' />
            )
          },

          {
            id: 'divider',
          },

          {
            id: 'kioskVendingId',
            label: '售賣機',
            rules: [
              {
                required: false,
                message: `請選擇 售賣機`
              }
            ],
            componet: (


              <Select
                allowClear={true}
                placeholder={'請選擇售賣機'}
                showSearch={true}
                filterOption={(input: any, option) => {
                  if (option && option.data) {
                    const data: GetKioskVendingRes = option.data
                    return (
                      data.code.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                      String(data.name || '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                    )
                  }
                  return false
                }}
              >
                {this.state.kioskVendings.map(kioskVending => {
                  return (
                    <Select.Option
                      key={kioskVending.id}
                      value={kioskVending.id}
                      data={kioskVending}
                    >
                      <Space>
                        <Tag color='blue'> {kioskVending.code}</Tag>
                        <Tag color='success'> {kioskVending.name}</Tag>
                      </Space>
                    </Select.Option>
                  )
                })}
              </Select>


            ),
          },

          {
            id: 'submit',
          },
        ]
      }

    }
  }

  render() {
    return (
      <div id="FoodCardUserMemberFamilyEditAndAdd">
        <JJ_From
          formListData={this._formListData()}
          onLoadData={this._onLoadData}
          onFinish={this._onFinish}
        />
      </div>
    )
  }

}
export default connector(FoodCardUserMemberFamilyEditAndAdd)
