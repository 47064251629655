import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, message, Spin, Button, Tag, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import api, { GetMessageRecordListParameters, DeleteMessageRecordParameters, GetMessageRecordRes, GetUserRes } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import JJ_TableItemAction from '../../components/JJ_TableItemAction';
import JJ_TableItemMoment from '../../components/JJ_TableItemMoment';
import MessageRecordEditAndAdd, { MessageRecordEditAndAddPropsType } from './MessageRecordEditAndAdd';
import { PlusOutlined } from '@ant-design/icons';
import { connect, ConnectedProps } from 'react-redux';
import JJ_TableItemCopyableId from '../../components/JJ_TableItemCopyableId';
import config from '../../config';
import moment from 'moment';
import { ActionUserRes } from '../../actions/ActionUser';
import { getI18nText } from '../../public';
import { ColumnsType } from 'antd/lib/table';


const { confirm } = Modal;

type EditAndAddModalType = MessageRecordEditAndAddPropsType

interface PageState {
  isSpinLoading: boolean
  editAndAddModal?: {
    show: boolean
    key: string
  } & EditAndAddModalType
}
interface Props {
  messageId?: string
}


interface Page {
  tableRef?: JJ_Table<GetMessageRecordRes> | null

}


type PageProps = Props & ConnectedProps<typeof connector>

interface RootState {
  user: ActionUserRes

}

const mapState = (state: RootState) => ({
  user: state.user,
})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class MessageRecordList extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: true,
    }
  }
  tableRef?: JJ_Table<GetMessageRecordRes> | null
  componentDidMount() {
    this._initData()
  }
  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true
      })
      await Promise.resolve()
      this.setState({
        isSpinLoading: false
      })
    } catch (error) {
      this.setState({
        isSpinLoading: false
      })
    }
  }


  _deleteMessageRecord = async (params: DeleteMessageRecordParameters) => {
    const res = await api.DeleteMessageRecord(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }




  _getColumns = (): ColumnsType<GetMessageRecordRes> => {
    return [
      {
        key: 'action',
        width: 50,
        fixed: 'left',
        render: (value: any, record) => {
          return (
            <JJ_TableItemAction
              menus={[
                {
                  type: 'item',
                  disabled: config.REACT_APP_DELETE_DISABLED,
                  key: 'delete',
                  name: '删除',
                  onAction: () => {
                    confirm({
                      title: `是否刪除${record.foodCardUserMemberFamily.foodCardCode}`,
                      icon: <ExclamationCircleOutlined />,
                      okText: 'Yes',
                      okType: 'danger',
                      cancelText: 'No',
                      onOk: async () => {
                        try {
                          await this._deleteMessageRecord({ id: record.id })
                          this.tableRef && this.tableRef.refreshData()
                          message.success(`${record.foodCardUserMemberFamily.foodCardCode} 删除成功`)
                        } catch (error) {
                          message.error(`${record.foodCardUserMemberFamily.foodCardCode} 删除失败 [${error.message}]`);
                        }
                      },
                      onCancel() {
                      },
                    });
                  }
                },
              ]}
            />
          )

        },
      },
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 150,
        render: (value) => (
          <JJ_TableItemCopyableId
            text={value}
          />
        )
      },

      {
        title: '家庭號',
        dataIndex: 'foodCardCode',
        key: 'foodCardCode',
        width: 150,
        render: (value, record) => record.foodCardUserMemberFamily.foodCardCode || '-'
      },

      {
        title: '閱讀時間',
        dataIndex: 'readAt',
        key: 'readAt',
        width: 120,
        render: (value) => (
          <JJ_TableItemMoment
            date={value}
          />
        )
      },

      {
        title: '發送時間',
        dataIndex: 'sendAt',
        key: 'sendAt',
        width: 120,
        render: (value) => (
          <JJ_TableItemMoment
            date={value}
          />
        )
      },

      {
        title: '創建時間',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 120,
        fixed: 'right',
        render: (value) => (
          <JJ_TableItemMoment
            date={value}
          />
        )
      },
    ]
  }

  render() {

    const editAndAddModal = this.state.editAndAddModal
    const messageId = this.props.messageId

    return (
      <div id="MessageRecordList"
      >
        <Button
          type='primary'
          style={{ marginBottom: 5 }}
          onClick={() => {
            if (messageId) {
              this.setState({
                editAndAddModal: {
                  ...editAndAddModal,
                  show: true,
                  type: 'add',
                  messageId,
                  key: new Date().toString()
                }
              })
            }


          }}
          icon={<PlusOutlined />}
        >
          {`添加App系統消息記錄`}
        </Button>


        {editAndAddModal && messageId && (

          <Modal
            maskClosable={false}
            title={'推送到用戶'}
            visible={editAndAddModal.show}
            footer={null}
            onCancel={() => this.setState({
              editAndAddModal: { ...editAndAddModal, show: false }
            })}
            width={'85%'}
          >
            <MessageRecordEditAndAdd
              {...editAndAddModal}
              onFinish={async (props) => {
                this.setState({
                  editAndAddModal: { ...editAndAddModal, show: false }
                })
                switch (props.type) {
                  case 'add': {
                    message.success(`推送成功`)
                  }
                    break

                }

                this.tableRef && await this.tableRef.refreshData()


              }}
            />
          </Modal>
        )}





        <Spin spinning={this.state.isSpinLoading}>
          {!this.state.isSpinLoading && (
            <JJ_Table<GetMessageRecordRes, {
              desc: 'ascend' | 'descend'
              createRange?: [moment.Moment, moment.Moment] | null
              searchTimeType?: GetMessageRecordListParameters['searchTimeType']
            }>
              ref={ref => this.tableRef = ref}
              isSearchText={true}
              sourceItems={[
                {
                  type: 'select',
                  defaultValue: 'ascend',
                  key: 'desc',
                  options: [
                    {
                      value: 'ascend',
                      name: '升序',
                      disabled: false,
                    },
                    {
                      value: 'descend',
                      name: '降序',
                      disabled: false,
                    },
                  ]
                },

                {
                  type: 'select',
                  defaultValue: 'createdAt',
                  key: 'searchTimeType',
                  options: [
                    {
                      value: 'createdAt',
                      name: '創建時間',
                      disabled: false,
                    },
                    {
                      value: 'readAt',
                      name: '閱讀時間',
                      disabled: false,
                    },
                  ]
                },
                {
                  type: 'rangePicker',
                  key: 'createRange',
                  span: 8,
                  props: {
                    placeholder: ['開始時間', '結束時間'],
                  }
                },
              ]}

              columns={this._getColumns()}
              title={'App系統消息記錄列表'}
              tableProps={{
                scroll: {
                  x: this._getColumns().reduce((pValue, cValue) => pValue + (Number(cValue.width) || 0), 0)
                }
              }}
              onDataSource={async (body) => {
                const { sourceItemBody } = body
                const params: {
                  isAscend?: GetMessageRecordListParameters['isAscend']
                  beginAt?: GetMessageRecordListParameters['beginAt']
                  endAt?: GetMessageRecordListParameters['endAt']
                  searchTimeType?: GetMessageRecordListParameters['searchTimeType']

                } = {}
                if (sourceItemBody) {
                  if (sourceItemBody.desc) {
                    params.isAscend = sourceItemBody.desc === 'ascend' ? true : false
                  }

                  const createRange = sourceItemBody.createRange
                  if (createRange && createRange.length === 2) {
                    const [createRangeBeginAtMoment, createRangeEndAtMoment,] = createRange
                    params.beginAt = createRangeBeginAtMoment.toISOString()
                    params.endAt = createRangeEndAtMoment.toISOString()
                  }

                  params.searchTimeType = sourceItemBody.searchTimeType

                }

                const res = await api.GetMessageRecordList({
                  ...body,
                  ...params,
                  messageId: this.props.messageId,
                  scope: ['message'],
                })
                if (res.kind !== 'ok') throw new Error(res.kind)
                return res.data
              }}
            />
          )}
        </Spin>

      </div>
    )
  }

}
export default connector(MessageRecordList)

