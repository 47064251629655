import React, { } from 'react';
import JJ_From, { FormItemData } from "../../components/JJ_From";
import api, { GetKioskVendingRes, GetKioskVendingListParameters, GetFoodCardUserMemberFamilyAreaParameters, PutFoodCardUserMemberFamilyAreaParameters, PostFoodCardUserMemberFamilyAreaParameters, GetFoodCardUserMemberFamilyAreaListParameters, GetFoodCardUserMemberFamilyAreaRes } from '../../api';
import { connect, ConnectedProps } from 'react-redux';
import { Select } from 'antd';
import { getAreaCodes } from '../../public';


interface PageState {
}

export type FoodCardUserMemberFamilyAreaEditAndAddPropsType = { type: 'add' } | { type: 'edit', id: string }

interface Props {
  onFinish?(props: PageProps, id: string): void
}



interface Page {
  /**
   * 保存数据
   */
  _onFinish(values: any): void

  /**
   * 获取数据
   */
  _onLoadData(): Promise<{}>

  id?: string

}


type PageProps = Props & FoodCardUserMemberFamilyAreaEditAndAddPropsType & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {

}

const mapState = (state: RootState) => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class FoodCardUserMemberFamilyAreaEditAndAdd extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);

    this.state = {
    }

  }
  componentDidMount() {
  }

  _onFinish = async (values: any): Promise<void> => {

    console.log('_onFinish', this.props.type, values)

    switch (this.props.type) {
      case 'edit': {
        const res = await this._putFoodCardUserMemberFamilyArea({
          ...values,
          id: this.props.id,
        })

        this.props.onFinish && this.props.onFinish(this.props, res.id)
      }
        break
      case 'add': {

        const res = await this._postFoodCardUserMemberFamilyArea({
          ...values,
        })
        this.props.onFinish && this.props.onFinish(this.props, res.id)
      }
        break
    }
  }

  _onLoadData = async (): Promise<any> => {
    switch (this.props.type) {
      case 'edit': {
        const res = await this._getFoodCardUserMemberFamilyArea({ id: this.props.id })
        return {
          ...res,
        }
      }
      case 'add': {
        return {

        }
      }


    }
  };

  _getFoodCardUserMemberFamilyArea = async (param: GetFoodCardUserMemberFamilyAreaParameters) => {
    const res = await api.GetFoodCardUserMemberFamilyArea(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _getFoodCardUserMemberFamilyAreaList = async (param: GetFoodCardUserMemberFamilyAreaListParameters) => {
    const res = await api.GetFoodCardUserMemberFamilyAreaList(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _putFoodCardUserMemberFamilyArea = async (param: PutFoodCardUserMemberFamilyAreaParameters) => {
    const res = await api.PutFoodCardUserMemberFamilyArea(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }
  _postFoodCardUserMemberFamilyArea = async (param: PostFoodCardUserMemberFamilyAreaParameters) => {
    const res = await api.PostFoodCardUserMemberFamilyArea(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }



  _getKioskVendingList = async (params: GetKioskVendingListParameters) => {
    const res = await api.GetKioskVendingList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }



  _formListData = (): FormItemData[] => {
    switch (this.props.type) {
      case 'edit': {
        return [
          {
            id: 'title',
            label: '區域名稱',
            rules: [
              {
                required: false,
                message: `請輸入 區域名稱`
              }
            ],
          },

          {
            id: 'code',
            label: '區域代碼',
            rules: [
              {
                required: false,
                message: `請輸入 區域代碼`
              }
            ],
            componet: (
              <Select>
                {getAreaCodes.map(item => {
                  return (
                    <Select.Option key={item} value={item}>{item}</Select.Option>
                  )
                })}
              </Select>
            ),
          },
          {
            id: 'remark',
            label: '備註',
            rules: [
              {
                required: false,
                message: `請輸入 備註`
              }
            ],
          },

          {
            id: 'submit',
          },
        ]
      }
      case 'add': {


        return [


          {
            id: 'title',
            label: '區域名稱',
            rules: [
              {
                required: true,
                message: `請輸入 區域名稱`
              }
            ],
          },

          {
            id: 'code',
            label: '區域代碼',
            rules: [
              {
                required: true,
                message: `請輸入 區域代碼`
              }
            ],
            componet: (
              <Select>
                {getAreaCodes.map(item => {
                  return (
                    <Select.Option key={item} value={item}>{item}</Select.Option>
                  )
                })}
              </Select>
            ),
          },

          {
            id: 'remark',
            label: '備註',
            rules: [
              {
                required: false,
                message: `請輸入 備註`
              }
            ],
          },
          {
            id: 'submit',
          },
        ]
      }


    }
  }

  render() {
    return (
      <div id="FoodCardUserMemberFamilyAreaEditAndAdd">
        <JJ_From
          formListData={this._formListData()}
          onLoadData={this._onLoadData}
          onFinish={this._onFinish}
        />
      </div>
    )
  }

}
export default connector(FoodCardUserMemberFamilyAreaEditAndAdd)
