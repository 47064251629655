import React, { } from 'react';
import { Spin, Button, Tag } from 'antd';
import api, { GetSurveyResponseListParameters, GetSurveyResponseRes } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import JJ_TableItemMoment from '../../components/JJ_TableItemMoment';
import { SurveySetUserModalPropsType } from '../survey/SurveySetUserModal';
import { PlusOutlined } from '@ant-design/icons';
import { connect, ConnectedProps } from 'react-redux';
import JJ_TableItemCopyableId from '../../components/JJ_TableItemCopyableId';
import { ActionUserRes } from '../../actions/ActionUser';
import SurveySetUserModal from '../survey/SurveySetUserModal';



interface PageState {
  isSpinLoading: boolean
  surveySetUserModalProps?: {
    show: boolean
    key: string
  } & SurveySetUserModalPropsType
}
interface Props {
  surveyId?: string
}


interface Page {


}


type PageProps = Props & ConnectedProps<typeof connector>

interface RootState {
  user: ActionUserRes

}

const mapState = (state: RootState) => ({
  user: state.user,
})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class SurveyResponseList extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: true,
    }
  }
  tableRef?: JJ_Table<GetSurveyResponseRes> | null
  componentDidMount() {
    this._initData()
  }
  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true
      })
      await Promise.resolve()
      this.setState({
        isSpinLoading: false
      })
    } catch (error) {
      this.setState({
        isSpinLoading: false
      })
    }
  }



  render() {
    return (
      <div id="SurveyResponseList"
      >
        <Button
          type='primary'
          style={{ marginBottom: 5 }}
          onClick={() => {
            const surveyId = this.props.surveyId
            if (surveyId) {
              this.setState(state => ({
                surveySetUserModalProps: {
                  show: true,
                  key: new Date().toString(),
                  surveyId,
                }
              }))
            }
          }}
          icon={<PlusOutlined />}
        >
          {`設置關聯用戶家庭`}
        </Button>
        {this.props.surveyId && this.state.surveySetUserModalProps &&
          (
            <SurveySetUserModal
              onSave={async e => {

                const surveySetUserModalProps = this.state.surveySetUserModalProps
                if (surveySetUserModalProps) {
                  this.setState({
                    surveySetUserModalProps: { ...surveySetUserModalProps, show: false }
                  })
                }

                this.tableRef && await this.tableRef.refreshData()
              }}
              surveyId={this.props.surveyId}
              title={'設置關聯用戶家庭'}
              visible={this.state.surveySetUserModalProps.show}
              onCancel={() => {
                const surveySetUserModalProps = this.state.surveySetUserModalProps
                if (surveySetUserModalProps) {
                  this.setState({
                    surveySetUserModalProps: { ...surveySetUserModalProps, show: false }
                  })
                }
              }}
            />
          )
        }


        <Spin spinning={this.state.isSpinLoading}>
          {!this.state.isSpinLoading && (
            <JJ_Table<GetSurveyResponseRes, {
              status: GetSurveyResponseListParameters['status']
            }>
              ref={ref => this.tableRef = ref}
              isSearchText={true}
              sourceItems={[
                {
                  type: 'select',
                  defaultValue: undefined,
                  key: 'status',
                  placeholder: '請選擇任意答題類型',
                  allowClear: true,
                  options: [
                    {
                      value: 'PENDING',
                      name: <Tag color='error'>單選</Tag>,
                      disabled: false,
                    },
                    {
                      value: 'COMPLETED',
                      name: <Tag color='success'>多選</Tag>,
                      disabled: false,
                    },
                  ]
                },
              ]}
              columns={[
                {
                  title: 'ID',
                  dataIndex: 'id',
                  key: 'id',
                  width: 50,
                  render: (value) => (
                    <JJ_TableItemCopyableId
                      text={value}
                    />
                  )
                },

                {
                  title: '用戶家庭號',
                  dataIndex: 'foodCardUserMemberFamily.foodCardCode',
                  key: 'foodCardUserMemberFamily.foodCardCode',
                  render: (value, record) => record.foodCardUserMemberFamily.foodCardCode
                },

                {
                  title: 'jcConsole',
                  dataIndex: 'foodCardUserMemberFamily.foodCardCode',
                  key: 'foodCardUserMemberFamily.foodCardCode',
                  render: (value, record) => record.foodCardUserMemberFamily.jcConsole
                },

                {
                  title: '狀態',
                  dataIndex: 'status',
                  key: 'status',
                  render: (value, record) => {
                    return (
                      record.status === 'PENDING'
                        ? <Tag color={'error'}>未提交</Tag>
                        : <Tag color={'success'}>已經提交</Tag>
                    )
                  }
                },
                {
                  title: '創建時間',
                  dataIndex: 'createdAt',
                  key: 'createdAt',
                  render: (value) => (
                    <JJ_TableItemMoment
                      date={value}
                    />
                  )
                },
              ]}
              title={'用戶問卷調查回應列表'}

              onDataSource={async (body) => {
                const { sourceItemBody } = body
                const params: {
                  status?: GetSurveyResponseListParameters['status']
                } = {}
                if (sourceItemBody) {
                  if (sourceItemBody.status) {
                    params.status = sourceItemBody.status

                  }
                }

                const res = await api.GetSurveyResponseList({
                  ...body,
                  ...params,
                  surveyId: this.props.surveyId,
                  scope: ['survey'],
                })
                if (res.kind !== 'ok') throw new Error(res.kind)
                return res.data
              }}


            />
          )}
        </Spin>

      </div >
    )
  }

}
export default connector(SurveyResponseList)

